/*global chrome*/
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useStateStore } from "../../store";
import FeedbackPopup from "./FeedbackPopup";
import InitBlock from "./InitBlock";
import NotFound from "./NotFound";
import Pagination from "./Pagination";
import ReferralFilters from "./ReferralFilters";
import ReferralList from "./ReferralList";
import "./ReferralMain.css";
import ReferralModal from "./ReferralModal";
import SendEmailSuccess from "./SendEmailSuccess";
import { mixpanel } from "../../index";
import ReferralAutomationBlock from "./ReferralAutomationBlock";
import AutomationStartModal from "./AutomationStartModal";
import InfoModal from "./InfoModal";

function ReferralMain() {
  const CLIENT_ID =
    "715818145189-7geg3j3td9u21nc9qe8eujb2l4dcbt88.apps.googleusercontent.com";
  const [selected, setSelected] = useState(1);
  const token = useStateStore((state) => state.token);
  const [showLoader, setShowLoader] = useState(false);
  const [companies, setCompanies] = useState([]);
  const userData = useStateStore((state) => state.userData);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const promptSelected = useStateStore((state) => state.promptSelected);
  const emailAutomationReferral = useStateStore(
    (state) => state.emailAutomationReferral
  );
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setReferralInitData = useStateStore(
    (state) => state.setReferralInitData
  );
  const setReferralResumeData = useStateStore(
    (state) => state.setReferralResumeData
  );
  const showEmailPermissionModal = useStateStore(
    (state) => state.showEmailPermissionModal
  );
  const showReferralFeedback = useStateStore(
    (state) => state.showReferralFeedback
  );
  const setShowReferralFeedback = useStateStore(
    (state) => state.setShowReferralFeedback
  );
  const setShowEmailPermissionModal = useStateStore(
    (state) => state.setShowEmailPermissionModal
  );
  const setShowEmailSentSuccess = useStateStore(
    (state) => state.setShowEmailSentSuccess
  );
  const showEmailSentSuccess = useStateStore(
    (state) => state.showEmailSentSuccess
  );
  const showMainReferralModal = useStateStore(
    (state) => state.showMainReferralModal
  );
  const setShowMainReferralModal = useStateStore(
    (state) => state.setShowMainReferralModal
  );
  // const [show, setShow] = useState(false);
  const [meta, setMeta] = useState({
    currentPage: 1,
    firstPage: 1,
    lastPage: 1000,
    perPage: 10,
    total: 10000,
  });
  const [initialDataLoader, setInitialDataLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [country, setCountry] = useState("");
  const [companyQuery, setCompanyQuery] = useState("");
  const [employees, setEmployees] = useState("");
  const [revenue, setRevenue] = useState("");
  const [showFilters, setShowFilters] = useState(true);
  const setButtonClick = useStateStore((state) => state.setButtonClick);
  const emailList = useStateStore((state) => state.emailList);
  const setEmailData = useStateStore((state) => state.setEmailData);
  const setEmailList = useStateStore((state) => state.setEmailList);
  const customTemplates = useStateStore((state) => state.customTemplates);
  const setCustomTemplates = useStateStore((state) => state.setCustomTemplates);

  const closeFn = () => {
    setShowMainReferralModal(false);
  };

  const closePermissionModal = () => {
    setShowEmailPermissionModal(false);
  };

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1);

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const findButtonSubmit = async () => {
    if (companyQuery === "") {
      setShowFilters(true);
      resetFilters();
    } else {
      setShowLoader(true);
      setShowFilters(false);
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/findCompaniesWithName`,
        {
          currentPage: 1,
          companyQuery,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setShowLoader(false);
      setMeta(
        response?.data?.meta || {
          currentPage: 1,
          firstPage: 1,
          lastPage: 1,
          perPage: 10,
          total: 1,
        }
      );
      setCompanies(response?.data?.companies || []);
      smoothScrollToTop();
      console.log("response", response);
    }
  };

  const setInitialCompanies = async () => {
    setCompanyQuery("");
    setShowFilters(true);
    setCountry("");
    setEmployees("");
    setRevenue("");
    setShowLoader(true);
    const response = await axios.get(
      `https://backend.lazyapply.com/referral/getMostPopular`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowLoader(false);
    setMeta({
      currentPage: 1,
      firstPage: 1,
      lastPage: 1,
      perPage: 1,
      total: 1,
    });
    setCompanies(response?.data?.initial_companies || []);
    smoothScrollToTop();
  };

  const setMainFilters = async (
    country_main,
    revenue_main,
    employees_main,
    company_query_main
  ) => {
    console.log("country", country_main);
    console.log("revenue", revenue_main);
    console.log("employees", employees_main);
    setShowLoader(true);
    if (selected) {
      getCompanies();
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getCompaniesWithFilters`,
        {
          country: country_main,
          revenue: revenue_main,
          employees: employees_main,
          companyQuery: company_query_main,
          currentPage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMeta(
        response?.data?.meta || {
          currentPage: 1,
          firstPage: 1,
          lastPage: 1,
          perPage: 10,
          total: 1,
        }
      );
      setCompanies(response?.data?.companies || []);
    }
    setShowLoader(false);
    smoothScrollToTop();
    console.log("response");
  };

  const getInitData = async () => {
    setInitialDataLoader(true);
    const initDataResponse = await axios.post(
      "https://backend.lazyapply.com/referral/getReferralData",
      {
        v2Id: userData?.planDetails?.v2Id || "",
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setInitialDataLoader(false);
    console.log("initData", initDataResponse, initDataResponse.data);
    setReferralInitData(initDataResponse.data);
  };

  const setCurrentPageAndQuery = async (page) => {
    setShowLoader(true);
    setMeta((meta) => ({
      ...meta,
      currentPage: page,
    }));
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/getCompaniesWithFilters`,
      {
        currentPage: page,
        country,
        revenue,
        employees,
        companyQuery,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowLoader(false);
    setCompanies(response?.data?.companies || []);
    smoothScrollToTop();
    console.log("response", response);
  };

  const getCompanies = () => {
    setInitialCompanies();
    // setMainFilters("", "", "", "");
  };

  const closeEmailSuccessModal = () => {
    setShowEmailSentSuccess(false);
  };

  const closeReferralFeedback = () => {
    setShowReferralFeedback(false);
  };

  const resetFilters = () => {
    setCompanyQuery("");
    setShowFilters(true);
    setCountry("");
    setEmployees("");
    setRevenue("");
    setSelected(true);
    getCompanies();
    // setMainFilters("", "", "", "");
  };

  const fetchUserResumeData = async () => {
    const v2Id = userData.planDetails.v2Id;
    try {
      const response = await axios.get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setReferralResumeData(response.data);
    } catch (err) {
      setReferralResumeData([]);
    }
    // const response = await axios.get(
    //   `https://backend.lazyapply.com/v2/resumesV3/completed/${userData.planDetails.v2Id}`,
    //   {
    //     headers: { Authorization: `Bearer ${token}` },
    //   }
    // );
    // setReferralResumeData(response.data);
  };

  useEffect(() => {
    setMainFilters(country, revenue, employees, "");
  }, [country, revenue, employees, selected]);

  useEffect(() => {
    fetchTemplates();
    fetchUserResumeData();
    getInitData();
    getCompanies();
    mixpanel.identify(userData.email);
    mixpanel.people.increment("secret_network_page dashboard");
    mixpanel.track("secret_network_page dashboard", {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain != "" &&
      usermain != undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              console.log("some error");
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  const Automation = async () => {
    setCompanyQuery(promptSelected.form.X);
    setShowLoader(true);
    setShowFilters(false);
    setMainAutomationStep("Getting Company");
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/findCompaniesWithName`,
      {
        currentPage: 1,
        companyQuery: promptSelected.form.X,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setShowLoader(false);
    setMeta(
      response?.data?.meta || {
        currentPage: 1,
        firstPage: 1,
        lastPage: 1,
        perPage: 10,
        total: 1,
      }
    );
    setCompanies(response?.data?.companies || []);
    smoothScrollToTop();
    return response?.data?.companies;
  };

  const Automation1 = async () => {
    setShowLoader(true);
    setSelected(false);
    if (promptSelected.form.revenue != "")
      setRevenue(promptSelected.form.revenue);
    if (promptSelected.form.employeeCount != "")
      setEmployees(promptSelected.form.employeeCount);
    if (promptSelected.form.country != "")
      setCountry(promptSelected.form.country);

    setMainAutomationStep("Getting Companies");
    const response = await axios.post(
      `https://backend.lazyapply.com/referral/getCompaniesWithFilters`,
      {
        country: promptSelected.form.country,
        revenue: promptSelected.form.revenue,
        employees: promptSelected.form.employeeCount,
        companyQuery: "",
        currentPage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    setMeta(
      response?.data?.meta || {
        currentPage: 1,
        firstPage: 1,
        lastPage: 1,
        perPage: 10,
        total: 1,
      }
    );
    setCompanies(response?.data?.companies || []);
    setShowLoader(false);
    smoothScrollToTop();
    return response?.data?.companies;
  };

  const delay = (ms) => {
    return new Promise((resolve, reject) => {
      let start = Date.now();

      const checkDelay = () => {
        checkIfStopped();

        if (Date.now() - start >= ms) {
          resolve();
        } else {
          setTimeout(checkDelay, 100);
        }
      };

      checkDelay();
    });
  };

  function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const [mainAutomationStep, setMainAutomationStep] = useState("");
  const setEmailAutomationReferral = useStateStore(
    (state) => state.setEmailAutomationReferral
  );
  const setEmailDataString = useStateStore((state) => state.setInitialString);

  let mainEditEmailInterval = null;
  let mainInterval = null;
  let mainEmailSettingsInterval = null;
  let mainEmailSettingsTimeout = null;
  let mainTimeout = null;
  let mainTimeoutWaitForPeopleList = null;

  async function calculateTotalTime() {
    return await new Promise((resolve) => {
      mainInterval = setInterval(() => {
        checkIfStopped();
        const emailDataString = useStateStore.getState().emailDataString;
        const words = emailDataString.split(" ");
        if (words.length > 3) {
          clearInterval(mainInterval);
          clearTimeout(mainTimeout);
          const intervalDelay = 100;
          const totalTime = words.length * intervalDelay;
          resolve(totalTime);
        }
      }, 100);

      mainTimeout = setTimeout(async () => {
        checkIfStopped();
        const email = checkIfEmailPresent();
        if (email == "") {
          clearTimeout(mainTimeout);
          clearInterval(mainInterval);
          resolve(-1);
        }
      }, 3000);
    });
  }

  const checkIfEmailPresent = () => {
    const employeeSelected = useStateStore.getState().employeeSelected;
    const emailList = useStateStore.getState().emailList;
    let email = "";
    if (employeeSelected.linkedinUrl in emailList) {
      email = emailList[employeeSelected.linkedinUrl];
    }
    console.log("time main email", email);
    return email;
  };

  const checkIfStopped = () => {
    if (useStateStore.getState().stopClick) {
      closeFn();

      if (mainEditEmailInterval) {
        clearInterval(mainEditEmailInterval);
      }

      if (mainEmailSettingsInterval) {
        clearInterval(mainEmailSettingsInterval);
      }

      if (mainEmailSettingsTimeout) {
        clearInterval(mainEmailSettingsTimeout);
      }

      if (mainInterval) {
        clearInterval(mainInterval);
      }

      if (mainTimeout) {
        clearTimeout(mainTimeout);
      }

      if (mainTimeoutWaitForPeopleList) {
        clearTimeout(mainTimeoutWaitForPeopleList);
      }

      setMainAutomationStep("Automation Completed");
      setEmailAutomationReferral(false);
      smoothScrollToTop();
      findButtonSubmit();
      setPromptIndexSelected(-1);
      setMainPrompts([
        {
          id: 1,
          checked: false,
          text: "I want referral from N companies that can offer more than Y CTC for Z role.",
        },
        {
          id: 2,
          checked: false,
          text: "I want referral from X company for Z role.",
        },
      ]);

      throw new Error("Automation stopped");
    }
  };

  const setEmployeeQuery = useStateStore((state) => state.setEmployeeQuery);
  const setSendEmail = useStateStore((state) => state.setSendEmail);
  const setEmployeesMain = useStateStore((state) => state.setEmployees);
  const setMainReferralPause = useStateStore(
    (state) => state.setMainReferralPause
  );

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);
              resolve(0);
            }
            if ("status" in response && response.status == "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              resolve(1);
            }
          }
        );
      });
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const checkIfConnected = () => {
    return useStateStore.getState().referralInitData?.emailConnected &&
      useStateStore.getState().referralInitData.emailConnected
      ? true
      : false;
  };

  const EmailPermission = async () => {
    await new Promise((resolve) => {
      if (checkIfConnected()) {
        resolve();
      } else {
        let extensionCheckInterval = setInterval(() => {
          // const connectEmailModal = document.querySelector(
          //   ".login-with-google-btn"
          // );
          if (!checkIfConnected()) {
            setMainReferralPause({
              status: true,
              text: "Give Permission To Connect Email",
            });
          } else {
            clearInterval(extensionCheckInterval);
            setMainReferralPause({
              status: false,
              text: "",
            });
            resolve();
          }
        }, 500);
      }
    });
  };

  const extensionPresent = useStateStore((state) => state.extensionPresent);
  const PauseFunction = async () => {
    await new Promise((resolve) => {
      if (extensionPresent) {
        resolve();
      } else {
        let extensionCheckInterval = setInterval(() => {
          if (useStateStore.getState().installExtensionDuringAutomation) {
            setMainReferralPause({
              status: false,
              text: "",
            });
            clearInterval(extensionCheckInterval);
            resolve();
          } else {
            chromeExtensionPresent();
            setMainReferralPause({
              status: true,
              text: "Install Lazyapply Extension To Continue Automation",
              text1: "If Already Installed Click Continue Automation",
            });
          }
        }, 500);
      }
    });
  };

  const checkCondition = () => {
    const referralInitData = useStateStore.getState().referralInitData;
    const referralResumeData = useStateStore.getState().referralResumeData;
    if (
      referralInitData?.referral?.emailTemplateOption &&
      referralInitData.referral.emailTemplateOption != "" &&
      referralInitData?.referral?.resumeId &&
      referralInitData?.referral?.resumeId != "" &&
      referralResumeData.resumes.some(
        (resumeObj) => resumeObj.resumeId === referralInitData.referral.resumeId
      )
    ) {
      return true;
    } else {
      return false;
    }
  };

  const PauseFunctionIfResumeNotSelectedOrCompleted = async () => {
    await new Promise((resolve) => {
      if (checkCondition()) {
        resolve();
      } else {
        let extensionCheckInterval = setInterval(() => {
          if (useStateStore.getState().profileSelectedDuringAutomation) {
            setMainReferralPause({
              status: false,
              text: "",
            });
            clearInterval(extensionCheckInterval);
            resolve();
          } else {
            setMainReferralPause({
              status: true,
              text: "Please Fill The Email Setting Options",
              text1: "If Already Completed, Click 'Save Your Choice'",
            });
            console.log("do nothing");
          }
        }, 500);
      }
    });
  };

  const PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail =
    async () => {
      await new Promise((resolve) => {
        checkIfStopped();
        mainEmailSettingsTimeout = setTimeout(() => {
          checkIfStopped();
          if (useStateStore.getState().editEmailSettings) {
            mainEmailSettingsInterval = setInterval(() => {
              checkIfStopped();
              if (!useStateStore.getState().editEmailSettings) {
                clearInterval(mainEmailSettingsInterval);
                resolve();
              } else {
                console.log("keep in pause state only");
              }
            }, 100);
          } else {
            resolve();
          }
        }, 5000);
      });
    };

  const setMainCompanySet = useStateStore((state) => state.setMainCompanySet);
  async function performTask() {
    try {
      setSendEmail(false);
      setMainCompanySet({});
      setMainAutomationStep("Starting Automation");
      await delay(2000);
      const companies = await Automation();
      checkIfStopped();
      const mainForLoop = 1;
      console.log("companies", companies);

      for (let index = 0; index < mainForLoop; index++) {
        await delay(2000);
        checkIfStopped();
        let selectedIndex = index;
        const company = companies[selectedIndex];
        let alldomains = document.querySelectorAll(
          ".main-referral-container .main-referral-company-domain"
        );
        let alldomainsmain = Array.from(alldomains).map((x) =>
          x.innerText.trim()
        );

        const domainIndex = alldomainsmain
          .map((domain) => domain.toLowerCase())
          .indexOf(company.domain.toLowerCase());

        if (domainIndex === -1) {
          console.error("Domain not found.");
          continue;
        }

        setMainAutomationStep(`Opening Company ${alldomainsmain[domainIndex]}`);
        await delay(1000);

        const mainDiv = alldomains[domainIndex];
        if (!mainDiv || !mainDiv.parentNode || !mainDiv.parentNode.parentNode) {
          console.error("Main div or parent not found.");
          continue;
        }

        mainDiv.parentNode.parentNode.scrollIntoView({ behavior: "smooth" });

        const button = mainDiv.parentNode.parentNode.querySelector(
          "#main-ask-referral-button"
        );

        if (!button) {
          console.error("Button not found.");
          continue;
        }

        checkIfStopped();

        button.click();
        await delay(2000);

        await PauseFunction();
        setMainReferralPause({
          status: false,
          text: "",
        });

        setMainAutomationStep("Finding Employees");

        const input = document.querySelector("#input_jobtitles");
        if (input) {
          setEmployeeQuery(promptSelected.form.employeeQuery);
          await delay(2000);
          input.parentNode.parentNode.querySelector("button").click();
        }

        const waitForPeopleList = async () => {
          const timeout = 10000;
          const interval = 500;
          const startTime = Date.now();

          return new Promise((resolve, reject) => {
            const checkPeopleList = () => {
              checkIfStopped();

              const peopleList = document.querySelectorAll(
                ".main-referral-container-peoplelist"
              );

              if (Array.from(peopleList).length > 0) {
                clearTimeout(mainTimeoutWaitForPeopleList);
                resolve(peopleList);
              } else if (Date.now() - startTime >= timeout) {
                clearTimeout(mainTimeoutWaitForPeopleList);
                resolve([]);
              } else {
                mainTimeoutWaitForPeopleList = setTimeout(
                  checkPeopleList,
                  interval
                );
              }
            };

            checkPeopleList();
          });
        };

        let totalCount = 0;
        for (let index = 0; index < 10; index++) {
          setEmailDataString("");
          checkIfStopped();

          if (index != 0) {
            const backbutton = document.querySelector("#email-block-go-back");
            if (backbutton) backbutton.click();
            await delay(500);
            const button = document.querySelector("#main-find-employee");
            if (button) button.click();
            await delay(500);
          }

          const peopleList = await waitForPeopleList();

          if (peopleList.length < index + 1 || totalCount == 2) {
            console.log("the end");
            setMainAutomationStep("Email Sent To All Employees");
            break;
          }

          setMainAutomationStep("Employees Found");
          await delay(1000);

          setMainAutomationStep(`Selecting Employee No - ${index + 1}`);
          await delay(1000);

          const emailResponse = await getEmail(
            useStateStore.getState().employees[index]
          );
          console.log("emailResponse", emailResponse);

          if (emailResponse.email == "") {
            setMainAutomationStep("Email Not Found");
            await delay(1000);
            setMainAutomationStep("Moving Ahead");
            console.log("move ahead");
            continue;
          } else {
            totalCount++;
          }

          const buttonList = Array.from(peopleList).map((list) => {
            return list.querySelector(".main-referral-top-third button");
          });

          if (buttonList.length > 0) {
            buttonList[index]?.click();
            await EmailPermission();
            setMainReferralPause({
              status: false,
              text: "",
            });
            setMainAutomationStep("Writing Email For Employee");
          } else {
            setMainAutomationStep("Writing Email For Employee");
          }

          // If details already completed.
          if (checkCondition()) {
            setMainAutomationStep("Update Email Settings If Required.");
            await PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail();
          }

          await PauseFunctionIfResumeNotSelectedOrCompleted();
          setMainAutomationStep("Writing Email For Employee");

          // await PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail();

          const time = await calculateTotalTime();
          console.log("time main", time);
          if (time == -1) {
            setMainAutomationStep("Email Not Found");
            continue;
          }
          checkIfStopped();
          await delay(time);

          setMainAutomationStep("Waiting For Email Editing");
          await delay(5000);

          setMainAutomationStep("");

          await new Promise((resolve) => {
            mainEditEmailInterval = setInterval(async () => {
              checkIfStopped();
              const editEmailMain = useStateStore.getState().editEmail;
              if (!editEmailMain) {
                const sendButton = document.querySelector(
                  "#referral-main-send-email"
                );
                if (sendButton) {
                  clearInterval(mainEditEmailInterval);
                  setMainAutomationStep("Sending Email");
                  sendButton.click();
                  await delay(1000);
                  closeEmailSuccessModal();
                  setMainAutomationStep("Email Sent");
                  await delay(2000);
                  resolve();
                }
              } else {
                console.log("no resolve");
              }
            }, 100);
          });
          closeEmailSuccessModal();
        }
      }
      setTimeout(() => {
        setShowInfoModal(true);
        setInfoModalType("success");
        setTimeout(() => {
          setShowInfoModal(false);
        }, 2500);
      }, 1500);
      closeFn();
      setMainAutomationStep("Automation Completed");
      setEmailAutomationReferral(false);
      smoothScrollToTop();
      setPromptIndexSelected(-1);
      findButtonSubmit();
      setMainPrompts([
        {
          id: 1,
          checked: false,
          text: "I want referral from N companies that can offer more than Y CTC for Z role.",
        },
        {
          id: 2,
          checked: false,
          text: "I want referral from X company for Z role.",
        },
      ]);
    } catch (error) {
      if (error.message === "Automation stopped") {
        console.log("Automation process was stopped.");
      } else {
        console.error("An error occurred:", error);
        checkIfStopped();
      }
    }
  }

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        "https://backend.lazyapply.com/referral/fetchCustomTemplates",
        {
          params: { v2Id: userData.planDetails.v2Id },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setCustomTemplates(response.data.templates);
    } catch (error) {
      console.error("Error fetching templates", error);
    }
  };

  const getEmail = async (employeeSelected) => {
    if (employeeSelected.linkedinUrl in emailList) {
      setEmailData({
        email: emailList[employeeSelected.linkedinUrl],
      });
      return {
        email: emailList[employeeSelected.linkedinUrl],
      };
    } else {
      const response = await axios.post(
        `https://backend.lazyapply.com/referral/getEmailMain`,
        {
          employee: employeeSelected,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("getEmailData", response.data.emailData);
      setEmailData(response.data.emailData);
      setEmailList({
        linkedin: employeeSelected.linkedinUrl,
        email: response.data.emailData.email,
      });
      return response.data.emailData;
    }
  };

  async function performTask1() {
    try {
      setSendEmail(false);
      setMainAutomationStep("Starting Automation");
      await delay(2000);
      setMainAutomationStep("Applying Company Filters");
      const companies = await Automation1();
      checkIfStopped();

      let givenCompaniesToApply = promptSelected.form.N;

      for (let index = 0; index < givenCompaniesToApply; index++) {
        if (index != 0) setMainAutomationStep(`Going To Next Company`);
        setEmployeesMain([]);
        setSendEmail(false);
        setButtonClick(false);
        closeFn();
        await delay(2000);
        checkIfStopped();
        let selectedIndex = index;
        const company = companies[selectedIndex];

        let alldomains = document.querySelectorAll(
          ".main-referral-container .main-domain"
        );
        let alldomainsmain = Array.from(alldomains).map((x) =>
          x.innerText.trim()
        );

        const domainIndex = alldomainsmain
          .map((domain) => domain.toLowerCase())
          .indexOf(company.domain.toLowerCase());

        console.log("domainindex", domainIndex);
        if (domainIndex === -1) {
          console.error("Domain not found.");
          continue;
        }

        setMainAutomationStep(`Opening Company ${alldomainsmain[domainIndex]}`);
        await delay(1000);

        const mainDiv = alldomains[domainIndex];
        if (!mainDiv || !mainDiv.parentNode || !mainDiv.parentNode.parentNode) {
          console.error("Main div or parent not found.");
          continue;
        }

        console.log("maindiv", mainDiv);

        mainDiv.parentNode.parentNode.scrollIntoView({ behavior: "smooth" });

        const button = mainDiv.parentNode.parentNode.querySelector(
          "#main-ask-referral-button"
        );

        if (!button) {
          console.error("Button not found.");
          continue;
        }

        checkIfStopped();

        button.click();
        await delay(2000);

        await PauseFunction();
        setMainReferralPause({
          status: false,
          text: "",
        });

        console.log("button", button);

        setMainAutomationStep("Finding Employees");
        const input = document.querySelector("#input_jobtitles");
        console.log("input", input);
        if (input) {
          setEmployeeQuery(promptSelected.form.employeeQuery);
          await delay(500);
          // input.parentNode.parentNode.querySelector("button").click();
        }

        const waitForPeopleList = async () => {
          const timeout = 10000;
          const interval = 500;
          const startTime = Date.now();

          return new Promise((resolve, reject) => {
            const checkPeopleList = () => {
              checkIfStopped();

              const peopleList = document.querySelectorAll(
                ".main-referral-container-peoplelist"
              );

              if (Array.from(peopleList).length > 0) {
                clearTimeout(mainTimeoutWaitForPeopleList);
                resolve(peopleList);
              } else if (Date.now() - startTime >= timeout) {
                clearTimeout(mainTimeoutWaitForPeopleList);
                resolve([]);
              } else {
                mainTimeoutWaitForPeopleList = setTimeout(
                  checkPeopleList,
                  interval
                );
              }
            };

            checkPeopleList();
          });
        };

        let totalCount = 0;
        for (let index = 0; index < 10; index++) {
          setEmailDataString("");
          checkIfStopped();

          // if (index != 0) {
          const backbutton = document.querySelector("#email-block-go-back");
          if (backbutton) backbutton.click();
          await delay(1000);

          const button = document.querySelector("#main-find-employee");
          if (button) {
            setMainAutomationStep("Opening Linkedin");
            await delay(2000);
            button.click();
          }
          await delay(1000);
          // }

          const peopleList = await waitForPeopleList();

          if (peopleList.length < index + 1 || totalCount == 2) {
            console.log("the end");
            setMainAutomationStep("Email Sent To All Employees");
            break;
          }

          setMainAutomationStep("Employees Found");
          await delay(1000);

          setMainAutomationStep(`Selecting Employee No - ${index + 1}`);
          await delay(1000);

          const emailResponse = await getEmail(
            useStateStore.getState().employees[index]
          );
          console.log("emailResponse", emailResponse);

          if (emailResponse.email == "") {
            setMainAutomationStep("Email Not Found");
            await delay(1000);
            setMainAutomationStep("Moving Ahead");
            console.log("move ahead");
            continue;
          } else {
            totalCount++;
          }

          const buttonList = Array.from(peopleList).map((list) => {
            return list.querySelector(".main-referral-top-third button");
          });

          if (buttonList.length > 0) {
            buttonList[index]?.click();
            await EmailPermission();
            setMainReferralPause({
              status: false,
              text: "",
            });
            setMainAutomationStep("Writing Email For Employee");
          } else {
            setMainAutomationStep("Writing Email For Employee");
          }

          // If details already completed.
          if (checkCondition()) {
            setMainAutomationStep("Update Email Settings If Required.");
            await PauseBeforeWritingEmailToChooseTemplateOrAiGeneratedEmail();
          }

          await PauseFunctionIfResumeNotSelectedOrCompleted();
          setMainAutomationStep("Writing Email For Employee");

          const time = await calculateTotalTime();
          console.log("time main", time);
          if (time == -1) {
            setMainAutomationStep("Email Not Found");
            continue;
          }
          checkIfStopped();
          await delay(time);

          setMainAutomationStep("Waiting For Email Editing");
          await delay(5000);

          setMainAutomationStep("");
          await new Promise((resolve) => {
            mainEditEmailInterval = setInterval(async () => {
              checkIfStopped();
              const editEmailMain = useStateStore.getState().editEmail;
              if (!editEmailMain) {
                const sendButton = document.querySelector(
                  "#referral-main-send-email"
                );
                if (sendButton) {
                  clearInterval(mainEditEmailInterval);
                  setMainAutomationStep("Sending Email");
                  sendButton.click();
                  await delay(1000);
                  closeEmailSuccessModal();
                  setMainAutomationStep("Email Sent");
                  await delay(2000);
                  resolve();
                }
              } else {
                console.log("no resolve");
              }
            }, 100);
          });
          closeEmailSuccessModal();
        }
      }
      setTimeout(() => {
        setShowInfoModal(true);
        setInfoModalType("success");
        setTimeout(() => {
          setShowInfoModal(false);
        }, 2500);
      }, 1000);
      closeFn();
      setMainAutomationStep("Automation Completed");
      setEmailAutomationReferral(false);
      smoothScrollToTop();
      findButtonSubmit();
      setPromptIndexSelected(-1);
      setMainPrompts([
        {
          id: 1,
          checked: false,
          text: "I want referral from N companies that can offer more than Y CTC for Z role.",
        },
        {
          id: 2,
          checked: false,
          text: "I want referral from X company for Z role.",
        },
      ]);
    } catch (error) {
      if (error.message === "Automation stopped") {
        console.log("Automation process was stopped.");
      } else {
        console.error("An error occurred:", error);
        checkIfStopped();
      }
    }
  }

  useEffect(() => {
    if (emailAutomationReferral && promptSelected.prompt.id != -1) {
      setTimeout(() => {
        if (promptSelected.prompt.id == 1) {
          performTask1();
        } else {
          performTask();
        }
      }, 500);
    }
  }, [emailAutomationReferral, promptSelected]);

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoModalType, setInfoModalType] = useState("success");
  const [promptIndexSelect, setPromptIndexSelected] = useState(-1);
  let [mainPrompts, setMainPrompts] = useState([
    {
      id: 1,
      checked: false,
      text: "I want referral from N companies that can offer more than Y CTC for Z role.",
    },
    {
      id: 2,
      checked: false,
      text: "I want referral from X company for Z role.",
    },
  ]);

  const mainPromptClick = (x, index) => {
    let basicPrompts = [
      {
        id: 1,
        checked: false,
        text: "I want referral from N companies that can offer more than Y CTC for Z role.",
      },
      {
        id: 2,
        checked: false,
        text: "I want referral from X company for Z role.",
      },
    ];
    if (!x.checked) {
      setShowStartModal(true);
      setPromptIndexSelected(index);
    } else {
      setPromptIndexSelected(-1);
    }
    let prompts = basicPrompts.map((prompt, i) => {
      if (i == index) {
        prompt.checked = !x.checked;
      }
      return prompt;
    });
    setMainPrompts(prompts);
  };

  const [showStartModal, setShowStartModal] = useState(false);

  return (
    <div className="main-referral-block">
      <GoogleOAuthProvider clientId={CLIENT_ID}>
        <InitBlock
          s={showEmailPermissionModal}
          closeFn={closePermissionModal}
        />
      </GoogleOAuthProvider>
      <ReferralFilters
        selected={selected}
        setSelected={setSelected}
        country={country}
        setCountry={setCountry}
        companyQuery={companyQuery}
        setCompanyQuery={setCompanyQuery}
        employees={employees}
        setEmployees={setEmployees}
        revenue={revenue}
        setRevenue={setRevenue}
        findButtonSubmit={findButtonSubmit}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        resetFilters={resetFilters}
      />
      {userData.email &&
      [
        "gupta.19@alumni.iitj.ac.in",
        "vivekiitjcse@gmail.com",
        "vivek@lazyapply.com",
        "rahulghatode99@gmail.com",
        "prakhar@lazyapply.com",
        "dev.akhilsingh@gmail.com",
      ].includes(userData.email) ? (
        <div className="promt-block">
          <p className="prompt-block-heading">
            Select A Prompt To Start Email Automation
            <img src={"/assests/prompt.png"} />
          </p>
          <div className="main-referral-prompt-block">
            {mainPrompts.map((x, index) => {
              return (
                <>
                  {/* {index == 1 && ( */}
                  <div
                    onClick={() => mainPromptClick(x, index)}
                    className={
                      "main-referral-prompt-inner-block " +
                      (index == promptIndexSelect
                        ? " main-referral-prompt-inner-block-hover"
                        : " ")
                    }
                  >
                    <input
                      type="checkbox"
                      checked={x.checked}
                      onChange={(e) => mainPromptClick(x, index)}
                    />
                    <p className="ml-2">{x.text}</p>
                  </div>
                  {/* )} */}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
      <p
        style={{
          textAlign: "end",
          fontSize: "12px",
          color: "grey",
          marginRight: "20px",
        }}
      >
        page {meta?.currentPage} - {meta?.lastPage}
      </p>
      {selected && (
        <p
          style={{
            textAlign: "start",
            fontSize: "14px",
            color: "grey",
            marginLeft: "25px",
          }}
        >
          Most Popular Companies
        </p>
      )}
      <AutomationStartModal
        setPromptIndexSelected={setPromptIndexSelected}
        setMainPrompts={setMainPrompts}
        prompt={mainPrompts.filter((prompt) => prompt.checked == true)[0]}
        showStartModal={showStartModal}
        customFn={() => setShowStartModal(false)}
      />
      {!showLoader && companies.length === 0 && <NotFound />}
      <ReferralList
        setShow={setShowMainReferralModal}
        showLoader={showLoader || initialDataLoader}
        companies={companies}
      />
      <SendEmailSuccess
        s={showEmailSentSuccess}
        closeFn={closeEmailSuccessModal}
      />
      <FeedbackPopup s={showReferralFeedback} closeFn={closeReferralFeedback} />
      <Pagination pagination={meta} setCurrentPage={setCurrentPageAndQuery} />
      <ReferralModal s={showMainReferralModal} closeFn={closeFn} />
      <ReferralAutomationBlock
        mainAutomationStep={mainAutomationStep}
        show={emailAutomationReferral}
        setShowInfoModal={setShowInfoModal}
        setInfoModalType={setInfoModalType}
      />
      <InfoModal
        type={infoModalType}
        showInfoModal={showInfoModal}
        customFnInfoModal={() => {
          setShowInfoModal(false);
        }}
      />
    </div>
  );
}

export default ReferralMain;
