import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

function SendEmailSuccess({ s, closeFn }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    closeFn();
  };

  useEffect(() => {
    setShow(s);
  }, [s]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      id="modal_email_sent_success"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="main-referral-send-email-modal-block">
        <img src={"/assests/success_tick.webp"} className="navbarImg"></img>
        <div >
          Email sent successfully.
          <p>
            You can verify that yourself in your GMAIL app in sent section {" "}
            <span
              onClick={() => {
                window.open(
                  "https://mail.google.com/mail/u/0/?tab=rm&ogbl#sent",
                  "_blank"
                );
              }}
            >
              OR by clicking here
            </span>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SendEmailSuccess;
