import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

function InfoModal({ type = "success", showInfoModal, customFnInfoModal }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    customFnInfoModal();
  };

  const types = {
    success: {
      heading: "Email Automation Completed!",
      desc: "Your email automation is completed successfully.",
    },
    stop: {
      heading: "Email Automation Stopped!",
      desc: "Your email automation is stopped successfully.",
    },
  };

  useEffect(() => {
    setShow(showInfoModal);
  }, [showInfoModal]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>{types[type].heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="referral-main-info-modal">
        <div>{types[type].desc}</div>
      </Modal.Body>
    </Modal>
  );
}

export default InfoModal;
