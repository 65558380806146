import React, { useState, useEffect } from "react";
import "./Toggle.css";
import { mixpanel } from "../index";
import { useStateStore } from "../store";

function Toggle({ setToggleValue }) {
  const userData = useStateStore((state) => state.userData);
  const [checked, setChecked] = useState("individuals");
  const setCheckedFn = (value) => {
    setChecked(value);
    mixpanel.track("individuals_enterprises_toggle pricing_page dashboard", {
      email: userData?.email,
      toggle_value: value,
      href:window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  };

  useEffect(() => {
    setToggleValue(checked);
  }, [checked]);

  return (
    <>
      <div className="container container-toggle">
        <div className="columns">
          <div className="column is-12">
            <div className="up-in-toggle">
              <input
                type="radio"
                id="switch_left"
                name="switch_2"
                checked={checked === "individuals"}
                onChange={(e) => setCheckedFn("individuals")}
              />
              <label for="switch_left">Individuals</label>
              <input
                type="radio"
                id="switch_right"
                name="switch_2"
                checked={checked === "enterprises"}
                onChange={(e) => setCheckedFn("enterprises")}
              />
              <label for="switch_right">Enterprises</label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Toggle;
