import React, { useEffect, useState } from "react";
import "./homedash.css";
import { ProgressBar, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useStateStore } from "../../store/index";
import axios from "axios";
import CustomAlert from "../modal";
import { mixpanel } from "../../index";
import ApplyCoupanCode from "./applycoupan.js";
import { GrantAccess } from "./grantAccess";
import SkeletonLoader from "./skeletonLoader";
import { Modal } from "react-bootstrap";
import ExtensionInstallPopup from "./ExtensionInstallPopup";
import MainWatchDemoModal from "./MainWatchDemoModal";
// import beta from "../../beta.jpeg";
// import linkedinLogo from "../../linkedin.png"
// import indeedLogo from "../../indeed.png"
// import ziprecruiterLogo from "../../ziprecruiter.png"

function Homedash() {
  const searchParams = new URLSearchParams(window.location.search);
  const from_job_page = searchParams.get("from_job_page");
  const history = useHistory();
  const [navbarWidth, setnavbarWidth] = useState(0);
  const mainLoadingState = useStateStore((state) => state.mainLoadingState);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const showDemo = useStateStore((state) => state.showDemo);
  const userData = useStateStore((state) => state.userData);
  const configDummy = useStateStore((state) => state.configDummy);
  const token = useStateStore((state) => state.token);
  const [country, setCountry] = useState("");
  const setVoted = useStateStore((state) => state.setVoted);
  const voted = useStateStore((state) => state.voted);
  const InputPlatformName = useStateStore((state) => state.InputPlatformName);
  const setInputPlatformName = useStateStore(
    (state) => state.setInputPlatformName
  );
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  // const loading = useRef(null);
  // const [showOtherVoteInput, setShowOtherVoteInput] = useState(false);
  // const setcheckdate = useStateStore((state) => state.setcheckdate);
  const setShowDemo = useStateStore((state) => state.setShowDemo);
  const mainJobPageFn = () => {
    if (from_job_page && from_job_page != "") {
      setShowDemo(true);
      window.localStorage.setItem("fromjob", true);
    }
    const fromjob = window.localStorage.getItem("fromjob");
    if (fromjob != undefined && fromjob) {
      setShowDemo(true);
    }
  };

  useEffect(() => {
    // if (loading.current) {
    //   loading.current.style.display = "none";
    // },
    mainJobPageFn();
    setnavbarWidth(window.innerWidth);
    mixpanel.identify(userData.email);
    mixpanel.people.increment("home_page dashboard");
    mixpanel.track("home_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (country === "") findIP();
  }, []);

  function dailyLimitBottom(today, daily) {
    let percent = daily == 0 ? 0 : (today / daily) * 100;
    if (percent > 100) {
      percent = 100;
    }
    return (
      <>
        <ProgressBar variant="success" now={percent} />

        <div className="dailyLimitBottom totalJobsProgress">
          <p>
            {Math.round(percent * 10) / 10}% used, today - {today} Jobs Applied
          </p>
          <p>Total sessions today - {configDummy.totalsessionstoday}</p>
        </div>
      </>
    );
  }

  // function dailyLimitBottom1(total, monthly, indeed, linkedin) {
  //   let percent = monthly == 0 ? 0 : (total / monthly) * 100;
  //   if (percent > 100) {
  //     percent = 100;
  //   }
  //   return (
  //     <>
  //       <ProgressBar variant="success" now={percent} />
  //       <p>
  //         {Math.round(percent * 10) / 10}% used, Indeed - {indeed}, Linkedin -{" "}
  //         {linkedin}
  //       </p>
  //     </>
  //   );
  // }

  // function updatePlan() {
  //   axios
  //     .post(
  //       "https://backend.lazyapply.com/finishplan",
  //       { planDetails: userData.planDetails },
  //       {
  //         headers: { Authorization: `Bearer ${token}` },
  //       }
  //     )
  //     .then((response) => {
  //       setcheckdate(1);
  //       console.log("plan finished");
  //       //console.log(response);
  //     })
  //     .catch((error) => {
  //       setcheckdate(1);
  //       if (error.message == "Request failed with status code 403") {
  //         console.log("reauthenticate");
  //         setmessage("Please relogin again!");
  //         settitle("Not authorized");
  //         setmodalshow(true);
  //         setTimeout(() => {
  //           globalLogout();
  //         }, 1500);
  //       }
  //       console.log("some error occured");
  //       // console.log(error);
  //     });
  // }

  // function getEndDate(endDate) {
  //   let endFinalDate = "";
  //   if (endDate && "_seconds" in endDate) {
  //     const dateInMillis = endDate._seconds * 1000;
  //     endFinalDate = new Date(dateInMillis).toDateString();
  //     // console.log(endFinalDate);
  //   }

  //   return new Date(endFinalDate).toLocaleDateString("en-US");
  // }

  const resumefetch = useStateStore((state) => state.resumefetch);
  const setresumefetch = useStateStore((state) => state.setresumefetch);
  const setResumesComplete = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const setResumeFetchLoadingState = useStateStore(
    (state) => state.setResumeFetchLoadingState
  );

  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);
  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };
  const [mainTitle, setMainTitle] = useState("");
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [directClick, setDirectClick] = useState(false);

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let mainReference = {
    linkedin: "Linkedin",
    indeed: "Indeed",
    askForReferral: "Ask For Referral",
    ziprecruiter: "Ziprecruiter",
    dice: "Dice",
    careerBuilder: "Career Builder",
    simplyHiredLogo: "Simply Hired",
    glassdoor: "Glassdoor",
  };

  useEffect(() => {
    if (resumefetch === 0 && userData?.planDetails?.v2Id) {
      setResumeFetchLoadingState(0);
      const v2Id = userData?.planDetails?.v2Id;
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          setresumefetch(1);
          console.log("here11", response);
          setUserResumeV3(response.data.resumes);
          setResumeFetchLoadingState(1);
        })
        .catch((err) => {
          console.log("some error occured");
        });
      axios
        .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setresumefetch(1);
          console.log("here111");
          setResumeFetchLoadingState(1);
          console.log("resumescompleted", response);
          if ("resumes" in response.data) {
            console.log("resumescompleted1");
            setResumesComplete(response.data.resumes);
          }
        })
        .catch((err) => {
          setresumefetch(1);
          setResumeFetchLoadingState(1);
          console.log("some error occured");
          if (err.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            console.log("error");
          }
        });
    }
  }, [userData, token]);

  function goToPricing() {
    mixpanel.identify(userData.email);
    mixpanel.people.increment("upgrade_plan_button home_page dashboard");
    mixpanel.track("upgrade_plan_button home_page dashboard", {
      email: userData.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    // window.open("https://lazyapply.com/pricing", "_blank");
    history.push("/pricing");
  }

  function findIP() {
    axios.get("https://ipinfo.io/json?token=7987a2c0dfe3af").then((res) => {
      const data = res.data;
      setCountry(data.country);
    });
  }

  function planFun() {
    if (configDummy.plan == 0 || configDummy.planName == "free") {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Purchase Plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else if (
      configDummy.planName == "basic" ||
      configDummy.planName == "premium"
    ) {
      return (
        <>
          <span onClick={() => goToPricing()}>
            Upgrade plan{" "}
            <i
              className="fas fa-credit-card ml-2"
              style={{ color: "white" }}
            ></i>
          </span>
        </>
      );
    } else {
      return <>{configDummy.planName.toUpperCase()} Plan</>;
    }
  }
  const [showProp, setShowProp] = useState(false);
  function planFun1() {
    setShowProp(true);
  }

  function closePlanFun1() {
    setShowProp(false);
  }

  function showPlan() {
    if (configDummy.planName == "free") {
      return "Free Trial";
    } else {
      return `${configDummy.planName}`;
    }
  }

  function setA(platform) {
    mixpanel.track(`start_applying_button home_page dashboard`, {
      email: userData.email,
      platform_name: platform,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }

  const handleVote = (platformName) => {
    console.log(userData);
    axios
      .post(
        "https://backend.lazyapply.com/sendvoting",
        { platformName: platformName },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log(response.data);
        if (response.data === "success") {
          setVoted(platformName);
          // setmessage('Successfully voted!');
          // settitle('Success');
          // setmodalshow(true);
          // setTimeout(() => {
          // 	setmodalshow(false);
          // }, 1500);
        } else {
          setmessage("Some error occured!");
          settitle("Error");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 1500);
        }
      })
      .catch((error) => {
        if (error.message == "Request failed with status code 403") {
          console.log("reauthenticate");
          setmessage("Please relogin again!");
          settitle("Not authorized");
          setmodalshow(true);
          setTimeout(() => {
            globalLogout();
          }, 1500);
        }
        console.log("some error occured");
      });
  };

  const changeVote = () => {
    setVoted("");
    setInputPlatformName("");
  };

  const submitInput = () => {
    if (InputPlatformName != "") {
      handleVote(InputPlatformName);
    }
  };

  const waitFn = async (time) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, time);
    });
  };

  const accessPlatform = async (platformName) => {
    const response = await GrantAccess(platformName);
    setMainTitle(mainReference[platformName]);
    mixpanel.track(`start_applying_button home_page dashboard`, {
      platform: platformName,
      email: userData.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    if (!response.access) {
      if (response.resultObj.extensionNotPresent) {
        setShowExtensionInstallPopup(true);
      } else if (response.resultObj.updateExtension) {
        setShowUpdateExtension(true);
        setShowExtensionInstallPopup(true);
      } else {
        setmessage(response.resultObj.message);
        settitle(response.resultObj.title);
        setmodalshow(true);
        await waitFn(2500);
        setmodalshow(false);
        if (response?.resultObj?.gotolinknew) {
          window.open(response.resultObj.gotolinknew, "_blank");
        }
        if (response?.resultObj?.gotolink) {
          history.push(response.resultObj.gotolink);
        }
      }
    } else {
      setTimeout(() => {
        history.push(`/dashboard/${platformName}`);
      }, 500);
    }
  };

  if (!mainLoadingState) {
    return (
      <>
        <SkeletonLoader />
      </>
    );
  }

  return (
    <>
      <div>
        <MainWatchDemoModal
          platform={mainTitle}
          showDemoPopup={showDemoPopup}
          customFn={() => {
            setShowDemoPopup(false);
          }}
          directClick={directClick}
          home={true}
        ></MainWatchDemoModal>
        <ExtensionInstallPopup
          title={mainTitle}
          home={true}
          onClickWatchDemo={onClickWatchDemo}
          s={showExtensionInstallPopup}
          closeFn={closeExtensionInstallPopup}
          showUpdateExtension={showUpdateExtension}
        />
        <ApplyCoupanCode showProp={showProp} hideShow={closePlanFun1} />
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        <div className="dashboardHeading">
          <div className="dashboardHeadingLeft">
            <span className="dashboardText">Dashboard</span>
            <span className="overviewText">overview</span>
          </div>
          <div className="dashboardHeadingRight">
            {configDummy.plan == 0 ? (
              <button onClick={planFun1} className="button1">
                Apply Coupon Code
              </button>
            ) : (
              <></>
            )}
            <button>{planFun()}</button>
          </div>
        </div>
        {navbarWidth >= 768 && (
          <div className="dashboardRightTop">
            <div className="dailyLimit dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Daily Limit</div>
                <div className="limitLeftTopText">Daily Limit Left</div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText">
                  {configDummy.plan == 0 ? "No plan" : configDummy.dailyLimit}
                </div>
                <div className="limitLeftText">
                  {configDummy.plan == 0 ? (
                    0
                  ) : (
                    <>
                      {configDummy.dailyLimit - configDummy.todaysessioncount <
                      0
                        ? configDummy.todaysessioncount
                        : configDummy.dailyLimit -
                          configDummy.todaysessioncount}
                      {/* /<span>{configDummy.dailyLimit}</span> */}
                      {` Jobs Left`}
                    </>
                  )}
                </div>
              </div>
              <div className="dailyLimitBottom">
                {configDummy.plan == 0
                  ? dailyLimitBottom(0, 0)
                  : dailyLimitBottom(
                      configDummy.todaysessioncount,
                      configDummy.dailyLimit
                    )}
              </div>
            </div>
            <div className="payment dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Your Plan Details</div>
                <div className="limitLeftTopText">Plan Ending</div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText planText">
                  {" "}
                  {configDummy.plan == 0 ? "No Plan" : showPlan()}
                </div>
                <div className="limitLeftText planEnding">
                  {configDummy.plan == 0 ? "--" : "Lifetime Access"}
                </div>
              </div>
              <div className="dailyLimitBottom">
                <p>
                  {configDummy.plan == 0
                    ? "No Plan, purchase a plan.."
                    : `You are subscribed to Lazyapply ${configDummy.planName.toUpperCase()} Plan`}
                </p>
              </div>
            </div>
            <div className="totalJobs dashboardRightTopContent">
              <div className="dailyLimitTop">
                <div className="dailyLimitTopText">Total Jobs Applied </div>
                <div className="limitLeftTopText">
                  {configDummy.planName == "free" ? "Total" : "Yearly"} Limit
                  Left
                </div>
              </div>
              <div className="dailyLimitMiddle">
                <div className="dailyLimitText">
                  {" "}
                  {configDummy.plan == 0 ? "No plan" : configDummy.totalJobs}
                </div>
                <div className="limitLeftText">
                  {configDummy.plan == 0 ? 0 : "Unlimited"}
                </div>
              </div>
              <div className="dailyLimitBottom totalJobsProgress">
                <p>
                  {configDummy.plan == 0
                    ? "No Plan, purchase a plan.."
                    : "Apply to unlimited jobs today :)"}
                </p>
              </div>
              {configDummy.plan != 0 && (
                <div className="dailyLimitBottom totalJobsProgress">
                  <p>Total sessions - {configDummy.totalsessions}</p>
                </div>
              )}
            </div>
          </div>
        )}
        {navbarWidth <= 768 && (
          <>
            <div className="dashboardRightTop">
              <div className="totalJobs dashboardRightTopContent">
                <div className="dailyLimitTop">
                  <div className="dailyLimitTopText">Your Details! </div>
                </div>
                <div className="dailyLimitMiddle">
                  <div className="dailyLimitText">Total Jobs Applied</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0 ? "No plan" : configDummy.totalJobs}
                  </div>
                  <div className="dailyLimitText">Today Applied</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0
                      ? `0`
                      : `${configDummy.todaysessioncount} / ${configDummy.dailyLimit}`}
                  </div>
                  <div className="dailyLimitText">Your Plan</div>
                  <div className="limitLeftText">
                    {configDummy.plan == 0 ? "No Plan" : showPlan()}
                  </div>
                </div>
                <div className="dailyLimitBottom totalJobsProgress">
                  <p>
                    {configDummy.plan == 0
                      ? "No Plan, purchase a plan.."
                      : "Apply to unlimited jobs today :)"}
                  </p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="dashboardRightMiddle">
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/linkedin.png"} className="linkedinLogo"></img>
          </div>
          <div className="jobdiv">
            <p>linkedin</p>
            <ul>
              <li>
                740 million members with over 55 million registered companies.
              </li>
              <li>
                The largest social network of professional networking and career
                development.
              </li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded before start applying.
              </li>
              <li>
                Make sure you are logged in to Linkedin before you start
                applying.
              </li>
            </ul>
          </div>

          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Linkedin");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            <button
              // to="/dashboard/linkedin"
              className="startNow"
              onClick={() => {
                setA("linkedin");
                accessPlatform("linkedin");
              }}
            >
              Start Applying
            </button>
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          {/* <img className="betaimg" src={"/assests/beta.jpeg"} /> */}
          <div className="jobdiv jobdivimage">
            <img src={"/assests/indeed.png"} className="indeedLogo"></img>
          </div>
          <div className="jobdiv">
            <p>indeed</p>
            <ul>
              <li>
                Over 16 million postings and 8.2 jobs are posted every second.
              </li>
              <li>Indeed is the most popular job posting site in the world.</li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded, before start applying.
              </li>
              <li>
                Make sure you are logged in to Indeed before you start applying
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Indeed");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            <button
              // to="/dashboard/indeed"
              className="startNow"
              onClick={() => {
                setA("indeed");
                accessPlatform("indeed");
              }}
            >
              Start Applying
            </button>
          </div>
        </div>
        {!(
          userData?.planDetails &&
          userData?.planDetails?.planType &&
          userData?.planDetails?.membertype &&
          userData?.planDetails?.planType == "enterprise"
        ) && (
          <div className="dashboardRightMiddleCard">
            <div className="jobdiv jobdivimage">
              <img src={"/assests/main.png"} className="linkedinLogo"></img>
            </div>
            <div className="jobdiv">
              <p>Referral</p>
              <ul>
                <li>Access information on over 53.1 million companies.</li>
                <li>
                  Request referrals directly from employees of these companies.
                </li>
                <li>Use LazyApply AI to write and send emails effortlessly.</li>
                <li>Ask for referrals from any company worldwide.</li>
              </ul>
            </div>
            <div className="jobdiv jobdivbutton">
              {showDemo && (
                <button
                  className="watch_demo_button_final"
                  onClick={() => {
                    setDirectClick(true);
                    setMainTitle("Ask For Referral");
                    setShowDemoPopup(true);
                  }}
                >
                  <img src={"/assests/youtube_icon.webp"}></img>
                  Watch Demo
                </button>
              )}
              <button
                className="startNow"
                onClick={() => {
                  mixpanel.track(
                    `ask_for_referral_button home_page dashboard`,
                    {
                      email: userData.email,
                      href: window.location.href,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    }
                  );
                  history.push("/dashboard/referral");
                }}
              >
                Ask For Referral
              </button>
            </div>
          </div>
        )}
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/ziprecruiter.png"} className="zipLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Ziprecruiter</p>
            <ul>
              <li>110M+ job seekers have used ZipRecruiter.</li>
              <li>#1 rated hiring site in the U.S.</li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded, before start applying.
              </li>
              <li>
                Make sure you are logged in to Ziprecruiter before you start
                applying.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Ziprecruiter");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            {configDummy.planName === "basic" ? (
              <div
                className="startNow startNowNew"
                onClick={() => history.push("/pricing")}
              >
                Upgrade the plan
              </div>
            ) : (
              <button
                // to="/dashboard/ziprecruiter"
                className="startNow"
                onClick={() => {
                  setA("ziprecruiter");
                  accessPlatform("ziprecruiter");
                }}
              >
                Start Applying
              </button>
            )}
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/dice.png"} className="diceLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Dice</p>
            <ul>
              <li>Search 70000+ job openings from tech's hottest employers.</li>
              <li>Over 6.1 million tech professionals.</li>
              <li>
                Tech Professional Visits: Around 1.7 million visits per month.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Dice");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            {configDummy.planName === "basic" ? (
              <div
                className="startNow startNowNew"
                onClick={() => history.push("/pricing")}
              >
                Upgrade the plan
              </div>
            ) : (
              <button
                // to="/dashboard/dice"
                className="startNow"
                onClick={() => {
                  setA("dice");
                  accessPlatform("dice");
                }}
              >
                Start Applying
              </button>
            )}
          </div>
        </div>

        {/* <div className="dashboardRightMiddleCard">
       
          <div className="jobdiv jobdivimage">
            <img src={"/assests/indeed.png"} className="indeedLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Remote Jobs</p>
            <ul>
              <li>
                Over 16 million postings and 8.2 jobs are posted every second.
              </li>
              <li>
                Our platform aggregates remote jobs from multiple job sites.
              </li>
              <li>
                Make sure you have a complete profile along with a resume
                uploaded, before start applying.
              </li>
              <li>
                Make sure you are logged in to the job site before you start
                applying.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            <button
            
              className="startNow"
              onClick={() => {
                setA("remoteJobs");
                accessPlatform("remoteJobs");
              }}
            >
              Start Applying
            </button>
          </div>
        </div> */}

        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img src={"/assests/cbfinal.png"} className="cbLogo"></img>
          </div>
          <div className="jobdiv">
            <p>Career Builder</p>
            <ul>
              <li>
                CareerBuilder leads the industry in recruiting solutions,
                employment screening
              </li>
              <li>
                Offers specialized recruitment solutions, uses advanced search
                technology to help companies identify the most suitable talent
              </li>
              <li>
                Connected with more than 80 million unique, diverse U.S. job
                seekers.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Career Builder");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            {configDummy.planName === "basic" ? (
              <div
                className="startNow startNowNew"
                onClick={() => history.push("/pricing")}
              >
                Upgrade the plan
              </div>
            ) : (
              <button
                className="startNow"
                onClick={() => {
                  setA("careerBuilder");
                  accessPlatform("careerBuilder");
                }}
              >
                Start Applying
              </button>
            )}
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img
              src={"/assests/simplyHiredLogo.png"}
              className="simplyHiredLogo"
            ></img>
          </div>
          <div className="jobdiv">
            <p>SimplyHired</p>
            <ul>
              <li>
                Simply Hired lists job openings from 700,000 unique employers.
              </li>
              <li>
                The platform operates job search engines in 24 countries and 12
                languages.
              </li>
              <li>
                For the past 3 years, Simply Hired has been named a "Top job
                search website" by Forbes and PC Magazine.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Simply Hired");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            {configDummy.planName === "basic" ? (
              <div
                className="startNow startNowNew"
                onClick={() => history.push("/pricing")}
              >
                Upgrade the plan
              </div>
            ) : (
              <button
                className="startNow"
                onClick={() => {
                  setA("simplyHired");
                  accessPlatform("simplyHired");
                }}
              >
                Start Applying
              </button>
            )}
          </div>
        </div>
        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage">
            <img
              src={"/assests/Glassdoor-Logo.png"}
              className="zipLogo glassdoorLogo"
            ></img>
          </div>
          <div className="jobdiv">
            <p>Glassdoor</p>
            <ul>
              <li>
                Glassdoor serves a global community of millions, championing
                workplace transparency since 2007.
              </li>
              <li>
                Over 55 million unique monthly visitors rely on Glassdoor for
                valuable insights.
              </li>
              <li>
                Glassdoor partners with 2.5 million employer clients, connecting
                them with talented professionals worldwide.
              </li>
            </ul>
          </div>
          <div className="jobdiv jobdivbutton">
            {showDemo && (
              <button
                className="watch_demo_button_final"
                onClick={() => {
                  setDirectClick(true);
                  setMainTitle("Glassdoor");
                  setShowDemoPopup(true);
                }}
              >
                <img src={"/assests/youtube_icon.webp"}></img>
                Watch Demo
              </button>
            )}
            {configDummy.planName === "basic" ? (
              <div
                className="startNow startNowNew"
                onClick={() => history.push("/pricing")}
              >
                Upgrade the plan
              </div>
            ) : (
              <button
                className="startNow"
                onClick={() => {
                  setA("glassdoor");
                  accessPlatform("glassdoor");
                }}
              >
                Start Applying
              </button>
            )}
          </div>
        </div>
        {/* ) : (
          <></>
        )} */}

        <div className="dashboardRightMiddleCard">
          <div className="jobdiv jobdivimage votetitle">
            Which platform you want next?
          </div>
          <div className="jobdiv">
            <button
              className={
                "vote" + (voted && voted === "well_found" ? " voted" : "")
              }
              disabled={voted && voted != ""}
              onClick={() => handleVote("well_found")}
            >
              Well Found
            </button>
            <button
              className={"vote" + (voted && voted === "upwork" ? " voted" : "")}
              disabled={voted && voted != ""}
              onClick={() => handleVote("upwork")}
            >
              Upwork
            </button>
            <button
              className={
                "vote" + (voted && voted === "flexjobs" ? " voted" : "")
              }
              disabled={voted && voted != ""}
              onClick={() => handleVote("flexjobs")}
            >
              Flexjobs
            </button>

            <Form.Control
              type="text"
              disabled={
                voted &&
                voted !== "" &&
                voted !== "zip_recruiter" &&
                voted !== "career_builder" &&
                voted !== "glass_door"
              }
              placeholder="Enter platform name"
              id="othervote"
              aria-describedby="passwordHelpBlock"
              value={InputPlatformName}
              className="othervote"
              onChange={(e) => setInputPlatformName(e.target.value)}
            />
            {!(
              voted &&
              voted !== "" &&
              voted !== "zip_recruiter" &&
              voted !== "career_builder" &&
              voted !== "glass_door"
            ) ? (
              <button
                className="vote"
                style={{ margin: "10px 0" }}
                onClick={() => submitInput()}
              >
                Submit Name
              </button>
            ) : (
              <></>
            )}
          </div>
          <div className="jobdiv jobdivbutton">
            {voted && voted != "" ? (
              <p onClick={() => changeVote()}>Change Vote</p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Homedash;
