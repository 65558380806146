import { mixpanel } from "../../../index";

export function mixpanelGoBack(userData, platform) {
  mixpanel.identify(userData?.email);
  mixpanel.people.increment(`go_back_button ${platform}_page dashboard`);
  mixpanel.track("go_back_button dashboard", {
    href: window.location.href,
    email: userData?.email,
    platformName: platform,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelStartAutomation(
  userData,
  form,
  advanceSearch,
  excludeString,
  mayIncludeString,
  stringToArray,
  platform
) {
  mixpanel.identify(userData?.email);
  mixpanel.people.increment(
    `click_to_fetch_filter_button ${platform}_page dashboard`
  );
  mixpanel.track(`click_to_fetch_filter_button ${platform}_page dashboard`, {
    href: window.location.href,
    email: userData?.email,
    skill: form.skill,
    location: form.location,
    numberOfJobs: form.numberOfJobs,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelFinalSubmit(
  userData,
  form,
  advanceSearch,
  excludeString,
  stringToArray,
  mayIncludeString,
  platform,
  form1
) {
  mixpanel.identify(userData?.email);
  mixpanel.people.increment(`start_applying_button ${platform}_page dashboard`);
  mixpanel.track(`start_applying_button ${platform}_page dashboard`, {
    href: window.location.href,
    email: userData?.email,
    skill: form.skill,
    location: form.location,
    numberOfJobs: form.numberOfJobs,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    formData: form1,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelResetFull(userData, platform) {
  mixpanel.identify(userData.email);
  mixpanel.people.increment(`reset_button ${platform}_page dashboard`);
  mixpanel.track("reset_button dashboard", {
    href: window.location.href,
    email: userData?.email,
    platformName: platform,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelDashboard(userData, platform) {
  mixpanel.identify(userData.email);
  mixpanel.people.increment(`${platform}_page dashboard`);
  mixpanel.track(`${platform}_page dashboard`, {
    href: window.location.href,
    email: userData?.email,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelDemoSubmit(userData, platform) {
  mixpanel.track("start_auto_apply_button dashboard", {
    href: window.location.href,
    email: userData?.email,
    platformName: platform,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelCreateProfile(userData, platform) {
  mixpanel.track("create_your_own_profile_select dashboard", {
    href: window.location.href,
    email: userData?.email,
    platformName: platform,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelSelectedResumeLinkedin(userData) {
  mixpanel.track("resume_selected_linkedin linkedin_page dashboard", {
    email: userData?.email,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelUploadResumeLinkedin(userData) {
  mixpanel.track(
    "upload_now_button select_resume_linkedin linkedin_page dashboard",
    {
      email: userData?.email,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    }
  );
}

export function mixpanelResumeLinkLinkedin(userData) {
  mixpanel.track("select_linkedin_resume_link linkedin_page dashboard", {
    email: userData?.email,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}

export function mixpanelPlanPurchase(userData, platform) {
  mixpanel.track("purchase_plan_button dashboard", {
    href: window.location.href,
    email: userData?.email,
    platformName: platform,
    plan_details: userData?.planDetails || {},
    v2Id: userData?.planDetails?.v2Id || "",
  });
}
