import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesDice() {
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    datePosted: "",
    remoteJobs: "",
    employmentType: [],
    employerType: [],
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresDice() {
  let filterRefsDice = 10;

  let initialFormStateDice = {
    skill: "",
    location: "",
    numberOfJobs: 0,
    datePosted: "",
    remoteJobs: "",
    employerType: [],
    employmentType: [],
    resumeJobScore: 40,
  };

  return {
    filterRefsDice,
    initialFormStateDice,
  };
}

export function startAutomationDice(
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString
) {
  let startAutomationMessage = "diceFetchFilters";

  let startAutomationUrl = `https://www.dice.com/jobs?q=${encodeURIComponent(
    form.skill
  )}&pageSize=100&filters.easyApply=true&page=1`;

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    diceFetchFilters: 0,
    diceLimit: Math.min(form.numberOfJobs, 200),
    diceJobLinks: [],
    diceLocation: form.location,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: startAutomationUrl,
    selected: true,
    dice: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitDice(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData
) {
  function makeFilter(value) {
    let string = "";

    for (let index = 0; index < value.length; index++) {
      const element = value[index].value;
      if (index === 0) {
        string += element;
      } else {
        string += "|" + element;
      }
    }
    console.log(string, "string dice");
    return string;
  }

  console.log("form here", form);

  let filter3Value = "";
  let filter4Value = "";

  if (form.datePosted.value) {
    filter3Value = form.datePosted.value;
  }

  if (form.remoteJobs.value) {
    filter4Value = form.remoteJobs.value;
  }

  let url = urlMain;
  let string3 = "&filters.postedDate=" + filter3Value;
  let string4 = "&filters.isRemote=" + filter4Value;
  if (makeFilter(form.employerType) !== "") {
    url =
      url +
      "&filters.employerType=" +
      encodeURIComponent(makeFilter(form.employerType));
  }
  if (makeFilter(form.employmentType) !== "") {
    url =
      url +
      "&filters.employmentType=" +
      encodeURIComponent(makeFilter(form.employmentType));
  }

  if (filter3Value !== "") url = url + string3;
  if (filter4Value !== "") url = url + string4;

  console.log(url, "url here");

  let finalSubmitSaveValues = {
    diceSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    diceBaseUrl: url,
    diceLimit: Math.min(form.numberOfJobs, 200),
    diceJobLinks: [],
    diceResumeId: resumeId,
    session: {
      platformName: "dice",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
  };

  let finalSubmitMessage = "diceStartApplying";

  let finalSubmitData = {
    url: url,
    selected: true,
    dice: true,
    message: "setBaseUrl",
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionDice(configDummy, count, sessionLinks, userData) {
  let platformJobsApplied = configDummy.jobsByPlatform["dice"] + count;

  let diceJobApplicationLinksFinal = [...sessionLinks.current];
  if ("diceJobApplicationLinksFinal" in userData) {
    diceJobApplicationLinksFinal = [
      ...userData.diceJobApplicationLinksFinal,
      ...diceJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    diceJobApplicationLinksFinal: diceJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hDice(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome,
  mainFilters
) {
  console.log(request, "request dice");

  if ("dice" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of Dice. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);

          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              window.open("https://www.dice.com/dashboard/logout", "_blank");

              // window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = request.filterValues;

          filterValues.current = mainFilters.data.dice;

          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                diceFetchFilters: 1,
              },
              dice: true,
              message: "diceFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_DICE,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "dice");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "dice");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
      if (request.message === "updateLocationUrl") {
        console.log("url final here", request.url);
        setUrlMain(request.url);
      }
    }
  }
}

export function changeFiltersValuesDice(option, key, setField) {
  console.log(option, "option");
  if (key === "employerOptions") {
    setField("employerType", option);
  } else if (key === "employmentTypeOptions") {
    setField("employmentType", option);
  } else if (key === "dateposted") {
    setField("datePosted", option);
  } else if (key === "remoteJobs") {
    setField("remoteJobs", option);
  }
}

export function remoteFinalSubmitDice(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData
) {
  console.log("form here", form);

  const filter4Value = "true";

  let url = `https://www.dice.com/jobs?q=${encodeURIComponent(
    form.skill
  )}&pageSize=100&filters.easyApply=true&page=1`;

  let string4 = "&filters.isRemote=" + filter4Value;

  if (filter4Value !== "") url = url + string4;

  console.log(url, "url here");

  let finalSubmitSaveValues = {
    diceSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    diceBaseUrl: url,
    diceLimit: Math.min(form.numberOfJobs, 200),
    diceJobLinks: [],
    diceResumeId: resumeId,
    session: {
      platformName: "dice",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    diceFetchFilters: 1,
    diceLocation: form.location,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let finalSubmitMessage = "diceStartApplying";

  let finalSubmitData = {
    url: url,
    selected: true,
    dice: true,
    message: "setBaseUrl",
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
