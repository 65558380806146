/*global chrome*/
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useMultipleRefs } from "../customHooks";
import { Form, Col, Row } from "react-bootstrap";
import "../jobplatform.css";
import { useHistory } from "react-router-dom";
import Progress from "../progress";
import axios from "axios";
import { useStateStore } from "../../../store/index";
import CustomAlert from "../../modal";
import { mixpanel } from "../../../index";
import Feedback from "../../feedback";
import Select from "react-select";
import { v1 as uuidv1 } from "uuid";
import { MESSAGETYPE, messages, MESSAGES } from "../slackMessages";
import MoreFiltersHelp from "../../morefiltershelp";
import AdvanceSearchModal from "../AdvanceSearchModal";
import { GrantAccess } from "../grantAccess";
import SaveSearchModal from "../saveSearchModal";
import GetSavedSearches from "../getSearches";

import ExtensionInstallPopup from "../ExtensionInstallPopup";
import MainWatchDemoModal from "../MainWatchDemoModal";
import mainFilters from "../../filters.json";
import {
  mixpanelGoBack,
  mixpanelStartAutomation,
  mixpanelFinalSubmit,
  mixpanelResetFull,
  mixpanelDashboard,
  mixpanelDemoSubmit,
  mixpanelCreateProfile,
  mixpanelSelectedResumeLinkedin,
  mixpanelUploadResumeLinkedin,
  mixpanelResumeLinkLinkedin,
  mixpanelPlanPurchase,
} from "./mixpanel";

import {
  useStateVariablesPlatform,
  featuresPlatform,
  startAutomationPlatform,
  finalSubmitPlatform,
  hPlatform,
  saveSessionPlatform,
  changeFiltersValuesPlatform,
  filterLabels,
  multipleSelectFilterLabels,
  filterResetPlatform,
  remoteFinalSubmitPlatform,
  remotePlatforms,
} from "./platformFunctions";

// import { filterResetPlatform } from "./indeed";

import ResumeJobScore from "../resumeJobScore/resumeJobScore";

import { styles } from "./styles";
import { cloneUniformsGroups } from "three/src/renderers/shaders/UniformsUtils.js";

function RemotePlatform() {
  // platform file code

  // const platform = "indeed";

  const [platform, setPlatform] = useState("indeed");

  console.log(platform, "platform");

  const { form, setForm } = useStateVariablesPlatform(platform);

  const {
    filterRefs: filterRefsPlatform,
    initialFormState,
    country_options,
    countryUrlMap,
    countryLoginUrlMap,
  } = featuresPlatform(platform);

  const {
    styleform,
    styleform1,
    filtersStyle,
    parastyle,
    buttonMain1,
    buttonMain,
  } = styles();

  let mainReferencePlatform = {
    linkedin: "Linkedin",
    indeed: "Indeed",
    askForReferral: "Ask For Referral",
    ziprecruiter: "Ziprecruiter",
    dice: "Dice",
    careerBuilder: "Career Builder",
    simplyHired: "Simply Hired",
    glassdoor: "Glassdoor",
  };

  let countryoptionPlatform = "";

  if (
    platform === "careerBuilder" ||
    platform === "glassdoor" ||
    platform === "simplyHired"
  ) {
    countryoptionPlatform = "us";
  }

  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );

  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  const { availableChromeVersion } = useStateStore.getState();
  const [showPlanPurchase, setShowPlanPurchase] = useState(false);
  const [showDemoPopup, setShowDemoPopup] = useState(false);
  const [showExtensionInstallPopup, setShowExtensionInstallPopup] =
    useState(false);
  const [showUpdateExtension, setShowUpdateExtension] = useState(false);

  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [savedSuccessfully, setSavedSuccessfully] = useState(false);
  const [selectedSearchData, setSelectedSearchData] = useState({
    searchId: "",
    searchName: "",
  });
  const [searchInputValue, setSearchInputValue] = useState({
    value: "",
    label: "Select a saved search setting",
  });
  const [showSavedSearchButton, setShowSavedSearchButton] = useState(true);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [showSavedSearchModal, setShowSavedSearchModal] = useState(false);
  const [excludeString, setExcludeString] = useState("");
  const [mayIncludeString, setMayIncludeString] = useState("");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const setheightMain = useStateStore((state) => state.setheightMain);
  const heightMain = useStateStore((state) => state.heightMain);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const [resumeId, setResumeId] = useState("");
  const resumeData = useRef({});
  const [resumeChanged, setResumeChanged] = useState(false);
  const [resumeDisable, setResumeDisable] = useState(false);
  const [openHelper, setOpenHelper] = useState(false);
  const [country, setCountry] = useState("www");
  const [form1, setForm1] = useState({});
  const [errors, setErrors] = useState({});
  const [countryoption, setCountryOption] = useState(countryoptionPlatform);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const history = useHistory();
  const formMain = useRef(null);
  const sessionLinks = useRef([]);
  const sessionLinksBefore = useRef([]);
  const saveSessionState = useRef(0);
  const selectResumeRef = useRef(null);
  const userData = useStateStore((state) => state.userData);
  const userLinksUpdate = useStateStore((state) => state.userLinksUpdate);
  const [startApplying, setstartApplying] = useState(false);
  const [formDisable, setformDisable] = useState(false);
  const fetchbutton = useRef(null);
  const token = useStateStore((state) => state.token);
  const configDummy = useStateStore((state) => state.configDummy);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const [urlMain, setUrlMain] = useState("");
  const setIndeedRunning = useStateStore((state) => state.setIndeedRunning);
  const count = useRef(0);
  const [finalJobCount, setFinalJobCount] = useState(0);
  const progressPercent = useRef(15);
  const [c, setc] = useState(0);
  const [pp, setpp] = useState(15);
  const button1 = useRef(null);
  const spinner = useRef(null);
  const button2 = useRef(null);
  const chooseParaText = useRef(null);
  const filtersDiv = useRef(null);
  const formMain1 = useRef(null);
  const para = useRef(null);
  const initprocessing = useRef(null);
  const processing = useRef(null);
  const initprocessingFinal = useRef(null);
  const formRef = useRef(null);
  const questionRefs = useRef([]);
  const [showIndeedModal, setShowIndeedModal] = useState(null);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const sessionId = useRef(null);
  let filterValues = useRef({});
  let filterRefs = useMultipleRefs(filterRefsPlatform);
  let urlParts = {};

  const [resumeUrlId, setResumeUrlId] = useState("");
  const [resumeLinkedin, setResumeLinkedin] = useState(false);
  const [resumeArr, setResumeArr] = useState(null);
  const [resumeInfo, setResumeInfo] = useState(null);
  const selectResumeRef1 = useRef(null);
  const resumeRef1 = useRef(null);
  const resumeRef2 = useRef(null);
  const resumeRef3 = useRef(null);

  const selectedPlatform = useRef(null);

  useEffect(() => {
    let vall1 = window.localStorage.getItem("resumeInfo");
    let vall2 = window.localStorage.getItem("resumeArr");
    if (vall1 && !resumeInfo) {
      setResumeInfo(JSON.parse(vall1));
    }
    if (vall2 && !resumeArr) {
      setResumeArr(JSON.parse(vall2));
    }
  }, []);

  function smoothScrollToTop() {
    let start = null;
    const initialScrollTop = window.scrollY;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 1000, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop = initialScrollTop * (1 - easedProgress);

      window.scrollTo(0, newScrollTop);

      if (newScrollTop > 0) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  function smoothScrollToBottom() {
    let start = null;
    const initialScrollTop = window.scrollY;
    const targetScrollTop = document.body.scrollHeight - window.innerHeight;

    function easeOutCubic(t) {
      return 1 - Math.pow(1 - t, 3);
    }

    function step(timestamp) {
      if (start === null) start = timestamp;
      const elapsed = timestamp - start;
      const progress = Math.min(elapsed / 500, 1); // take 2 seconds to scroll

      const easedProgress = easeOutCubic(progress);
      const newScrollTop =
        initialScrollTop + (targetScrollTop - initialScrollTop) * easedProgress;

      window.scrollTo(0, newScrollTop);

      if (newScrollTop < targetScrollTop) {
        window.requestAnimationFrame(step);
      }
    }

    window.requestAnimationFrame(step);
  }

  const handleCloseSearch = () => {
    setShowAdvanceSearch(false);
  };

  const setField = (field, value) => {
    console.log(field + value);
    setForm({
      ...form,
      [field]: value,
    });

    // Check and see if errors exist, and remove them from the error object:

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const findFormErrors = () => {
    const { skill, numberOfJobs } = form;
    const newErrors = {};
    // name errors
    if (!skill || skill === "") newErrors.skill = "Please enter a skill!";
    if (numberOfJobs <= 0)
      newErrors.numberOfJobs = "Please enter the no. of jobs to apply";

    if (numberOfJobs < 20 && configDummy.planName !== "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount <= 20) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 20";
      }
    } else if (numberOfJobs < 35 && configDummy.planName === "free") {
      if (configDummy.dailyLimit - configDummy.todaysessioncount < 35) {
        console.log("go for it");
      } else {
        newErrors.numberOfJobs = "Please enter a value more than 35";
      }
    }

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    console.log(newErrors);
    // Conditional logic:
    if (userData?.planDetails?.v2Id) {
      //v2
      if (resumeId === "" && Object.keys(userResumeV3).length > 0) {
        setResumeChanged(true);
      } else if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startAutomation();
        }
      }
    } else {
      if (Object.keys(newErrors).length > 0) {
        // We got errors!
        setErrors(newErrors);
      } else {
        // No errors! Put any logic here for the form submission!
        if (await fetchFilterUserHandler()) {
          startAutomation();
        }
      }
    }
  };

  function goBack() {
    mixpanelGoBack(userData, platform);

    console.log("go back");
    history.goBack();
  }

  async function findIP() {
    const request = await fetch("https://ipinfo.io/json?token=7987a2c0dfe3af");
    const json = await request.json();
    console.log(json, "IP DETAILS");
    if (json.country.toLowerCase() === "us") {
      setCountry("www");
    } else {
      setCountry(json.country.toLowerCase());
    }
  }

  const stringToArray = (string) => {
    return string
      .split(",")
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
  };

  function startAutomation() {
    const {
      startAutomationMessage,
      startAutomationUrl,
      startAutomationSaveValues,
      startAutomationData,
    } = startAutomationPlatform(
      platform,
      form,
      country,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      countryUrlMap,
      resumeUrlId,
      resumeInfo,
      resumeArr,
      uuidv1
    );

    console.log(form.skill);
    console.log(form.location);
    console.log(form.numberOfJobs);

    // spinner.current.style.display = "inline-block";

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      // fetchbutton.current.disabled = true;

      setUrlMain(startAutomationUrl);

      mixpanelStartAutomation(
        userData,
        form,
        advanceSearch,
        excludeString,
        mayIncludeString,
        stringToArray,
        platform
      );

      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: startAutomationSaveValues,

          [platform]: true,
          message: startAutomationMessage,
          data: startAutomationData,
        });
    } else {
      // spinner.current.style.display = "none";

      // alert("rgrt");
      if (configDummy.dailyLimit - configDummy.todaysessioncount === 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function indeedReset() {
    console.log("Reset");
    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(yourExtensionId, {
        dashboard: true,
        [platform]: true,
        message: `reset${platform}`,
      });
  }

  function getUnappliedJobsDueToResumeScore() {
    console.log("Unapplied jobs due to resume score");

    if (typeof chrome !== "undefined" && chrome && chrome.runtime)
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          [platform]: true,
          message: "totalUnappliedJobsDueToResumeScore",
        },
        function (response) {
          if (chrome.runtime.lastError) {
            console.error(chrome.runtime.lastError);
          } else {
            console.log("Received response:", response);
          }
        }
      );
  }

  // filters reset

  console.log(filterValues, "filterValues");
  console.log(form, "form");

  const filterReset = function () {
    filterResetPlatform(
      platform,
      urlParts,
      filterRefs,
      filterValues,
      setForm,
      form
    );
  };

  function handleSubmit1(e) {
    console.log("submit,, -1  ");
    e.preventDefault();
    console.log(form1);

    // let count = 0;
    // Object.keys(form1).forEach((key) => {
    //   if (form1[key] != "") {
    //     count++;
    //   }
    // });
    if (count > 1) {
      //show error
      // para.current.style.display = "block";
    } else {
      //para.current.style.display = "none";
      finalSubmit();
    }
  }

  const closeExtensionInstallPopup = () => {
    setShowExtensionInstallPopup(false);
  };

  const onClickWatchDemo = () => {
    setShowExtensionInstallPopup(false);
    setShowDemoPopup(true);
  };

  let chromeExtensionPresent = async () => {
    let extensionPresentMain = 0;
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      console.log("check1");
      extensionPresentMain = await new Promise((resolve, reject) => {
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            lazyapply: "lazyapply",
            message: "check",
            injected: scriptInjectedGlobal,
          },
          function (response) {
            // Do whatever you want, background script is ready now
            var lastError = chrome.runtime.lastError;
            if (lastError) {
              console.log("error", lastError.message);

              // 'Could not establish connection. Receiving end does not exist.'
              resolve(0);
            }
            console.log("check0", response);
            if ("status" in response && response.status === "installed") {
              setScriptInjectedGlobal(true);
              setExtensionPresent(true);
              console.log("check2");

              resolve(1);
            }
          }
        );
      });
      console.log("extensionpresent", extensionPresentMain);
      return extensionPresentMain;
    } else {
      extensionPresentMain = 0;
      return extensionPresentMain;
    }
  };

  const fetchFilterUserHandler = async () => {
    if (userData.planDetails.planStarted) {
      const response = await GrantAccess("linkedin");
      if (!response.access) {
        if (response.resultObj.extensionNotPresent) {
          setShowExtensionInstallPopup(true);
        } else if (response.resultObj.updateExtension) {
          setShowUpdateExtension(true);
          setShowExtensionInstallPopup(true);
        } else {
          setmessage(response.resultObj.message);
          settitle(response.resultObj.title);
          setmodalshow(true);
          if (response?.resultObj?.gotolinknew) {
            window.open(response.resultObj.gotolinknew, "_blank");
          }
          if (response?.resultObj?.gotolink) {
            history.push(response.resultObj.gotolink);
          }
        }
        return 0;
      } else {
        return 1;
      }
    } else {
      if (resumeId === "") setResumeId("demo_id_lazyapply");
      const extensionPresent = await chromeExtensionPresent();
      console.log("heree111", extensionPresent);
      if (!extensionPresent) {
        setShowExtensionInstallPopup(true);
        return 0;
      } else if (
        chromeVersion !== availableChromeVersion &&
        chromeVersion !== -1 &&
        availableChromeVersion !== -1
      ) {
        setShowExtensionInstallPopup(true);
        setShowUpdateExtension(true);
        return 0;
      } else {
        return 1;
      }
    }
  };

  // function finalSubmitUrl(platform) {
  //   return urlMain;
  // }

  function finalSubmit() {
    // startAutomation();

    console.log(sessionId, "sessionId");

    if (
      configDummy.dailyLimit - configDummy.todaysessioncount >=
        form.numberOfJobs ||
      !userData.planDetails.planStarted
    ) {
      console.log("final submit");

      // console.log(urlMain, "urlMain");

      // console.log(sessionId, "sessionId");

      sessionId.current = uuidv1();

      // console.log(sessionId, "sessionId");

      const {
        url: finalSubmitUrl,
        finalSubmitSaveValues,
        finalSubmitMessage,
        finalSubmitData,
      } = remoteFinalSubmitPlatform(
        platform,
        sessionId,
        advanceSearch,
        stringToArray,
        excludeString,
        mayIncludeString,
        resumeId,
        formRef,
        resumeData,
        form1,
        urlMain,
        resumeUrlId,
        form,
        urlParts,
        country,
        countryUrlMap,
        resumeInfo,
        resumeArr
      );

      setstartApplying(true);
      setIndeedRunning(1);
      setResumeDisable(true);
      saveSessionState.current = 0;
      formRef.current = { ...form, ...form1 };
      button2.current.style.backgroundColor = "grey";
      button2.current.style.color = "black";
      button2.current.innerText = "Process Started!";
      console.log("final submit");
      console.log(
        "Filters selected according to which  now everything will run"
      );
      console.log("Run the main process again");
      // let url = finalSubmitUrl(platform);
      let url = finalSubmitUrl;
      console.log(url, "url");
      initprocessing.current.style.display = "flex";
      smoothScrollToBottom();
      // sessionId.current = uuidv1();
      console.log("current sessionId", sessionId.current);

      mixpanelFinalSubmit(
        userData,
        form,
        advanceSearch,
        excludeString,
        stringToArray,
        mayIncludeString,
        platform,
        form1
      );

      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(yourExtensionId, {
          dashboard: true,
          saveValues: finalSubmitSaveValues,
          [platform]: true,
          message: finalSubmitMessage,
          data: finalSubmitData,
        });

      selectedPlatform.current.style.display = "none";
    } else {
      // spinner.current.style.display = "none";

      // alert("rgrt");
      if (configDummy.dailyLimit - configDummy.todaysessioncount === 0) {
        setmessage("You have used your daily limit! Come back tomorrow :)");
        settitle("Daily limit exceeded!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
        setTimeout(() => {
          history.push("/dashboard");
        }, 3000);
      } else {
        setmessage(
          `You have applied to ${
            configDummy.todaysessioncount
          } jobs today. Please enter a value less than ${
            configDummy.dailyLimit - configDummy.todaysessioncount
          }`
        );
        settitle("Daily limit update!");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 2500);
      }
    }
  }

  function resetFull() {
    mixpanelResetFull(userData, platform);

    if (saveSessionState.current === 0) {
      saveSession(count.current, platform);
    }
    resumeData.current = {};
    window.wasRun = false;
    setShowPlanPurchase(false);
    setResumeId("");
    setResumeDisable(false);
    if (selectResumeRef.current)
      selectResumeRef.current.style.display = "block";

    setPlatform("indeed");

    if (selectedPlatform.current) {
      selectedPlatform.current.style.display = "block"; // or 'inline-block' if needed
    }

    // if (resumeRef1.current) resumeRef1.current.style.display = "none";
    // if (resumeRef2.current) resumeRef2.current.style.display = "none";
    // if (resumeRef3.current) resumeRef3.current.style.display = "none";

    sessionLinks.current = [];
    sessionLinksBefore.current = [];
    setIndeedRunning(0);
    setForm(initialFormState);
    setAdvanceSearch(false);
    setMayIncludeString("");
    setExcludeString("");
    setSelectedSearchData({
      searchId: "",
      searchName: "",
    });
    setSearchInputValue({
      value: "",
      label: "Select a saved search setting",
    });
    filterReset();
    formRef.current = {};

    // fetchbutton.current.disabled = false;

    document.getElementById("inputskill").value = "";
    document.getElementById("inputlocation").value = "";
    document.getElementById("inputnumber").value = "";
    document.getElementById("sliderResumeJobScore").value = 40;
    // formMain1.current.reset();
    indeedReset();
    setpp(15);
    setc(0);
    count.current = 0;
    progressPercent.current = 15;
    console.log("resetFull");
    // para.current.style.display = "none";
    // chooseParaText.current.style.display = "none";
    // formMain1.current.style.display = "none";
    // button1.current.style.display = "block";
    setShowSavedSearchButton(true);
    setformDisable(false);
    button2.current.style.backgroundColor = "#F8F9FA";
    button2.current.style.color = "#007bff";
    button2.current.innerText = "Start Applying";
    initprocessing.current.style.display = "none";
    initprocessingFinal.current.style.display = "none";

    // selectedPlatform.current.style.display = "none";

    setstartApplying(false);
  }

  const messageFn = useCallback(
    (event) => {
      console.log(platform, "platform value");

      console.log("indeed", event.data);
      if (
        typeof event.data === "object" &&
        !Array.isArray(event.data) &&
        event.data !== null
      ) {
        console.log("indeed platform", event.data);

        h(event.data, platform);
        event.source.postMessage("finished", event.origin);
      }
    },
    [platform]
  );

  const checkIfPresent = () => {
    if (selectedSearchData.searchName !== "") {
      return true;
    } else {
      return false;
    }
  };

  const hideSavedSearchModal = () => {
    setShowSavedSearchModal(false);
  };

  const chromeVersion = useStateStore((state) => state.chromeVersion);

  function h(request, platform) {
    console.log(request, "request", platform);

    hPlatform(
      platform,
      request,
      sessionLinks,
      count,
      selectResumeRef1,
      form,
      formMain,
      setUrlMain,
      countryLoginUrlMap,
      countryoption,
      setResumeArr,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      button1,
      setShowSavedSearchButton,
      filtersDiv,
      setheightMain,
      heightMain,
      chrome,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      resumeRef1,
      resumeRef2,
      resumeRef3,
      mainFilters
    );
  }

  function saveSession(count, platformName) {
    console.log(formRef.current);
    console.log(saveSessionState.current, "savesessionstate0");
    if (
      (count > 0 && saveSessionState.current === 0) ||
      (count === 0 &&
        saveSessionState.current === 0 &&
        sessionLinksBefore.current.length > 0)
    ) {
      getUnappliedJobsDueToResumeScore();
      console.log(saveSessionState.current, "savesessionstate1");
      let todaysessioncount = configDummy.todaysessioncount + count;
      let totalJobs = configDummy.totalJobs + count;

      const { platformJobsApplied, platformJobApplicationLinksFinal } =
        saveSessionPlatform(
          platform,
          configDummy,
          count,
          sessionLinks,
          userData
        );

      let totalsessions = configDummy.totalsessions + 1;
      let totalsessionstoday = configDummy.totalsessionstoday + 1;
      // totalCountUpdate({
      //   indeedJobsApplied: indeedJobsApplied,
      //   todaysessioncount: todaysessioncount,
      //   totalJobs: totalJobs,
      //   totalsessions: totalsessions,
      //   totalsessionstoday: totalsessionstoday,
      // });

      let totalsession = configDummy.totalsession;
      // setStreakAnalytics({
      //   ...streakAnalytics,
      //   indeed: streakAnalytics.indeed + count,
      // });
      configDummyUpdate({
        ...configDummy,
        jobsByPlatform: {
          ...configDummy.jobsByPlatform,
          [platform]: platformJobsApplied,
        },
        todaysessioncount: todaysessioncount,
        totalJobs: totalJobs,
        totalsessions: totalsessions,
        totalsessionstoday: totalsessionstoday,
        totalsession: [
          ...totalsession,
          {
            resumeData: resumeData.current,
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            platformName: platform,
            form: formRef.current,
            count: count,
            email: userData.email,
          },
        ],
      });
      userLinksUpdate(platformJobApplicationLinksFinal);
      // setSaveSession(1);
      saveSessionState.current = 1;
      const v2Id = userData?.planDetails?.v2Id;
      const url = v2Id
        ? `https://backend.lazyapply.com/v2/saveSession/${v2Id}`
        : "http://backend.lazyapply.com/savesessionv2";

      axios
        .post(
          url,
          {
            sessionLinks: sessionLinks.current,
            sessionLinksBefore: sessionLinksBefore.current,
            resumeData: resumeData.current,
            session: {
              count: count,
              platformName: platformName,
              form: formRef.current,
            },
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          indeedReset();
          console.log("configDummy final", configDummy);
          console.log(response);
          setmessage("Process completed!");
          settitle("Success");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
          }, 2500);
        })
        .catch((error) => {
          indeedReset();

          console.log("configDummy final", configDummy);
          console.log(error);
          if (error.message === "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    } else {
      getUnappliedJobsDueToResumeScore();
      saveSessionState.current = 1;
      // setSaveSession(1);
      indeedReset();
    }
  }

  const [resumefetch, setresumefetch] = useState(false);
  const fetchAllResumes = (v2Id) => {
    axios
      .get(
        `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setresumefetch(true);
        setUserResumeV3(response.data.resumes);
      })
      .catch((err) => {
        setresumefetch(true);
        console.log("some error occured");
      });
  };

  useEffect(() => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) fetchAllResumes(v2Id);
  }, [userData]);

  useEffect(() => {
    if (advanceSearch) {
      setShowAdvanceSearch(true);
    }
  }, [advanceSearch]);

  useEffect(() => {
    if (form.skill !== "" && form.numberOfJobs > 0 && resumeId !== "") {
      setSaveButtonDisabled(false);
    } else {
      setSaveButtonDisabled(true);
    }
  }, [form, resumeId]);

  useEffect(() => {
    console.log(
      "selected search data --> do processing here",
      selectedSearchData
    );
    if (selectedSearchData.form) {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: selectedSearchData.form.numberOfJobs,
          skill: selectedSearchData.form.skill,
          location: selectedSearchData.form.location,
        };
        return newFormData;
      });
    } else {
      setForm((formData) => {
        let newFormData = {
          ...formData,
          numberOfJobs: 0,
          skill: "",
          location: "",
        };
        return newFormData;
      });
    }
    if (selectedSearchData.advanceSearch) {
      setAdvanceSearch(selectedSearchData.advanceSearch.advanceSearch);
      setExcludeString(selectedSearchData.advanceSearch.excludeString);
      setMayIncludeString(selectedSearchData.advanceSearch.mayIncludeString);
    } else {
      setAdvanceSearch(false);
      setExcludeString("");
      setMayIncludeString("");
    }
    if (selectedSearchData.extraConfig) {
      if (selectedSearchData.extraConfig.resumeId) {
        const found = userResumeV3.filter((key) => {
          return key.resumeId === selectedSearchData.extraConfig.resumeId;
        });
        if (found.length > 0) {
          userResumeV3.map((key) => {
            const item = key;
            if (item.resumeId === selectedSearchData.extraConfig.resumeId) {
              resumeData.current = {
                resumeId: item.resumeId,
                resumeName: item.resumename,
              };
            }
          });
          setResumeId(selectedSearchData.extraConfig.resumeId);
        } else {
          setResumeId("");
        }
      }
      if (selectedSearchData.extraConfig.countryoption) {
        setCountryOption(selectedSearchData.extraConfig.countryoption);
      }
    } else {
      setResumeId("");
    }
  }, [selectedSearchData]);

  useEffect(() => {
    if (countryoption !== "") {
      setCountry(countryoption);
    } else {
      setCountryOption(country);
      console.log("countryoption is empty");
    }
  }, [countryoption, country]);

  useEffect(() => {
    console.log("form1....", form1);
    Object.keys(form1).length > 1 ? setOpenHelper(true) : setOpenHelper(false);
  }, [form1]);

  useEffect(() => {
    console.log("added");
    window.addEventListener("message", messageFn);
    return () => {
      console.log("removed");
      window.removeEventListener("message", messageFn);
    };
  }, [messageFn]);

  useEffect(() => {
    console.log("Sending initial request to extension");
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    if (
      tokenmain !== "" &&
      usermain !== undefined &&
      Object.keys(usermain).length > 0
    ) {
      if (typeof chrome !== "undefined" && chrome && chrome.runtime)
        chrome.runtime.sendMessage(
          yourExtensionId,
          {
            dashboard: true,
            saveValues: {
              token: tokenmain,
              user: usermain,
            },
            injected: scriptInjectedGlobal,
            global: true,
            message: "loginToken",
          },
          (response) => {
            console.log(
              "response from extension",
              response,
              chrome.runtime.lastError
            );
            var lastError = chrome.runtime?.lastError;
            if (lastError) {
              setScriptInjectedGlobal(false);
              setExtensionPresent(false);
              history.push("/dashboard");
              return;
            } else {
              console.log("response of initial request", response);
              setScriptInjectedGlobal(true);
              setGlobalTabId(response.globalTabId);
            }
          }
        );
    }
  }, []);

  useEffect(() => {
    if (platform === "indeed") {
      findIP();
    }
    // const checking = localStorage.getItem("stepcheckindeed");
    // if (checking && checking == 'true' ) {
    //   setChecks(true);
    //   console.info('checking1',checking)
    // }

    mixpanelDashboard(userData, platform);

    console.log("todays total count", configDummy);

    // spinner.current.style.display = "none";

    // para.current.style.display = "none";

    initprocessing.current.style.display = "none";

    // chooseParaText.current.style.display = "none";

    // formMain1.current.style.display = "none";

    indeedReset();
  }, []);

  useEffect(() => {
    console.log("ppcurrent", progressPercent.current);
    if (progressPercent.current === 100) {
      formMain.current.style.display = "none";
      // formMain1.current.style.display = "none";
      button2.current.style.display = "none";
      // chooseParaText.current.style.display = "none";
    } else {
      formMain.current.style.display = "block";
      button2.current.style.display = "block";
    }
  }, [progressPercent.current]);
  // const [checks, setChecks] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    questionRefs.current.forEach((question) => {
      const clickHandler = (event) => {
        const active = document.querySelector(".question.active");
        if (active && active !== question) {
          active.classList.toggle("active");
          active.nextElementSibling.style.maxHeight = 0;
        }
        question.classList.toggle("active");
        const answer = question.nextElementSibling;
        if (question.classList.contains("active")) {
          answer.style.maxHeight = answer.scrollHeight + "px";
        } else {
          answer.style.maxHeight = 0;
        }
      };

      if (question) {
        question.addEventListener("click", clickHandler);
      }

      return () => {
        if (question) question.removeEventListener("click", clickHandler);
      };
    });
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (count.current === 0) {
      smoothScrollToBottom();
    }
  }, [progressPercent.current]);

  const demoSubmit = () => {
    mixpanelDemoSubmit(userData, platform);

    setTimeout(() => {
      history.push("/pricing");
    }, 200);
  };

  const showFilters = () => {
    filterValues.current = mainFilters.data[platform];
    console.log("filter values", filterValues.current);
    // chooseParaText.current.style.display = "block";
    // spinner.current.style.display = "none";
    // formMain1.current.style.display = "block";
    // button1.current.style.display = "none";
    setShowSavedSearchButton(false);
    if (filtersDiv.current)
      setheightMain(heightMain + filtersDiv.current.clientHeight + 300);
    smoothScrollToBottom();
  };

  const checkIfDemoAndNoPlan = () => {
    return !userData.planDetails.planStarted && showDemo;
  };

  const showDemo = useStateStore((state) => state.showDemo);
  useEffect(() => {
    const fn = async () => {
      const result = await GrantAccess(platform);

      console.log("grantAccess", result);
      if (!result.access) {
        history.push("/dashboard");
      } else {
        if (checkIfDemoAndNoPlan()) {
          showFilters();
        }
      }
    };
    fn();
  }, [userData]);

  return (
    <div style={{ position: "relative" }}>
      {/* {!checks && (
        <>
          <button
            className="buttonGoBack btn buttonSetup"
            onClick={() => {
              localStorage.setItem("stepcheckindeed", true);
              setChecks(true);
            }}
          >
            Skip Setup Steps
          </button>
          <button className="buttonGoBack btn" onClick={goBack}>
            <i className="fas fa-arrow-left"></i> Go Back
          </button>
          <div className="stepswithcutmain">
            <p className="stepswithcutmainheading">
              One time setup steps for Indeed
            </p>
            <StepsWithCut
              platform="indeed"
              setComplete={(value) => {
                if (value) {
                  localStorage.setItem("stepcheckindeed", true);
                }
                setChecks(value);
              }}
            />
          </div>
        </>
      )} */}
      <div>
        <MainWatchDemoModal
          platform={mainReferencePlatform[platform]}
          showDemoPopup={showDemoPopup}
          customFn={() => {
            setShowDemoPopup(false);
          }}
        ></MainWatchDemoModal>
        <ExtensionInstallPopup
          title={mainReferencePlatform[platform]}
          onClickWatchDemo={onClickWatchDemo}
          s={showExtensionInstallPopup}
          closeFn={closeExtensionInstallPopup}
          showUpdateExtension={showUpdateExtension}
        />
        <MoreFiltersHelp
          openHelper={openHelper}
          setOpenHelper={setOpenHelper}
        />
        <CustomAlert
          message={message}
          s={modalshow}
          title={title}
        ></CustomAlert>
        <p className="startApplying">
          Start Applying on {platform} remote jobs.{" "}
        </p>
        {/* <button
          className="buttonGoBack btn buttonSetup"
          onClick={() => setChecks(false)}
        >
          <i className="fas fa-wrench"></i> One Time Setup Steps
        </button> */}
        <button className="buttonGoBack btn" onClick={goBack}>
          <i className="fas fa-arrow-left"></i> Go Back
        </button>

        {userData?.planDetails?.v2Id && (
          <div className="v2resumeSelect" ref={selectResumeRef}>
            <p className="makesure" style={{ marginBottom: "40px" }}>
              Make sure to login in the {platform} account of the person with
              the selected resume and his/her {platform} profile should be
              completed before applying
            </p>

            <SaveSearchModal
              platformName={platform}
              showProp={showSavedSearchModal}
              hideShow={hideSavedSearchModal}
              form={{ ...form }}
              advanceSearch={{
                advanceSearch: advanceSearch,
                excludeString,
                mayIncludeString,
              }}
              extraConfig={{
                resumeId: resumeId,
                countryoption: countryoption,
              }}
              setSelectedSearchData={setSelectedSearchData}
              setSavedSuccessfully={setSavedSuccessfully}
              selectedSearchData={selectedSearchData}
            />

            {/* <GetSavedSearches
              platformName={platform}
              savedSuccessfully={savedSuccessfully}
              setSelectedSearchData={setSelectedSearchData}
              searchInputValue={searchInputValue}
              setSearchInputValue={setSearchInputValue}
            /> */}

            <Form.Group ref={selectedPlatform} style={{ marginTop: "30px" }}>
              <Form.Label>
                Choose a Platform to apply remote jobs for
              </Form.Label>

              <Form.Control
                as="select"
                value={platform}
                id="select-remote-platform"
                onChange={(e) => {
                  console.log("platform", e.target.value);
                  setPlatform(e.target.value);
                }}
              >
                {Object.keys(remotePlatforms).map((v) => {
                  return (
                    <option value={remotePlatforms[v].value}>
                      {remotePlatforms[v].label}
                    </option>
                  );
                })}
              </Form.Control>

              {/* <Select
          id="select-remote-platform"
          onChange={(option) => {
            console.log("onchange", option);
          }}
          name="select-remote-platform"
          placeholder="Select Platform"
          options={remotePlatforms}
          className="basic-multi-select"
          classNamePrefix="select"
        /> */}
            </Form.Group>

            <Form.Group>
              <Form.Label>Choose a Profile to apply jobs for</Form.Label>
              {Object.keys(userResumeV3).length == 0 ||
              !userResumeV3.some((resume) => resume.validation === 1) ? (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    value={"demo_id_lazyapply"}
                    onChange={(e) => {
                      if (e.target.value === "create_own_profile") {
                        mixpanelCreateProfile(userData, platform);

                        history.push("/dashboard/editresumesv2");
                      }
                    }}
                  >
                    <option value="demo_id_lazyapply">
                      Demo Profile Selected
                    </option>
                    <option value="create_own_profile">
                      Create Your Own Profile
                    </option>
                  </Form.Control>
                </>
              ) : (
                <>
                  <Form.Control
                    as="select"
                    id="resumeId"
                    disabled={resumeDisable}
                    isInvalid={resumeChanged}
                    value={resumeId}
                    onChange={(e) => {
                      if (e.target.value != "") {
                        setResumeChanged(false);
                      }

                      userResumeV3.map((key) => {
                        const item = key;
                        if (item.resumeId === e.target.value) {
                          resumeData.current = {
                            resumeId: item.resumeId,
                            resumeName: item.resumename,
                          };
                        }
                      });
                      setResumeId(e.target.value);
                    }}
                  >
                    <option value="">Select a resume</option>
                    {userResumeV3.map((key) => {
                      const item = key;
                      if (key.validation == 1)
                        return (
                          <option value={item.resumeId}>
                            {item.resumename}
                          </option>
                        );
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a resume/cv
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>
          </div>
        )}

        {platform === "linkedin" ? (
          <div
            class={resumeArr ? "lknResumeFullBorder" : "lknResumeFull"}
            ref={resumeRef3}
          >
            {resumeInfo && (
              <Form.Group>
                <Form.Label
                  style={{ padding: "20px 20px 0 20px", width: "100%" }}
                >
                  Selected Resume for Upload on Linkedin
                </Form.Label>
              </Form.Group>
            )}

            {resumeArr && !resumeInfo && (
              <>
                <div class="row">
                  <div class="col-12">
                    <Form.Group>
                      <Form.Label
                        style={{ padding: "25px 20px 0px 30px", width: "100%" }}
                      >
                        Select Resume for Linkedin
                        {resumeArr.length > 0 && (
                          <a
                            className="btn btn-primary"
                            style={{ float: "right", marginRight: "40px" }}
                            href="https://www.linkedin.com/jobs/application-settings/"
                            target="_blank"
                          >
                            Upload new
                          </a>
                        )}
                      </Form.Label>
                    </Form.Group>
                  </div>

                  {resumeArr.length > 0 &&
                    resumeArr.map((arr, index) => (
                      <div class="col-md-5 d-flex lknResumeCol">
                        <div className="form-check form-check-inline custom-radio d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id={`flexRadioDefault${index}`}
                            onClick={() => {
                              mixpanelSelectedResumeLinkedin(userData);

                              setResumeInfo({
                                index: index,
                                data: resumeArr[index],
                              });
                              window.localStorage.setItem(
                                "resumeInfo",
                                JSON.stringify({
                                  index: index,
                                  data: resumeArr[index],
                                })
                              );
                            }}
                          />
                        </div>

                        <div
                          class="col-4 myclass d-flex align-items-center"
                          onClick={() => {
                            setResumeInfo({
                              index: index,
                              data: resumeArr[index],
                            });
                            window.localStorage.setItem(
                              "resumeInfo",
                              JSON.stringify({
                                index: index,
                                data: resumeArr[index],
                              })
                            );
                          }}
                        >
                          <div class="redBox">PDF</div>
                          <div class="textDiv">
                            <div class="upper">{arr[0]}</div>
                            <div class="lower">{arr[1]}</div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {resumeArr.length === 0 && (
                    <div className="col-12" style={{ paddingLeft: "50px" }}>
                      <p>You currently have no resume present on linkedin. </p>
                      <br />
                      <a
                        onClick={() => {
                          mixpanelUploadResumeLinkedin(userData);
                        }}
                        className="btn btn-primary"
                        href="https://www.linkedin.com/jobs/application-settings/"
                        target="_blank"
                      >
                        Upload now
                      </a>
                    </div>
                  )}
                </div>
              </>
            )}

            {resumeInfo && (
              <div class="col-md-5 d-flex lknResumeCol">
                <div className="form-check form-check-inline custom-radio d-flex align-items-center">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={`flexRadioDefaultSelected`}
                    checked="true"
                  />
                </div>

                <div
                  class="col-4 myclass d-flex align-items-center"
                  ref={resumeRef2}
                >
                  <div class="redBox">PDF</div>
                  <div class="textDiv">
                    <div class="upper">{resumeInfo.data[0]}</div>
                    <div class="lower">{resumeInfo.data[1]}</div>
                  </div>
                </div>
              </div>
            )}

            {!checkIfDemoAndNoPlan() &&
              !resumeLinkedin && ( // ajay
                <div class="col-md-12" ref={resumeRef1}>
                  <a
                    href="#"
                    className="text-primary"
                    style={{
                      marginLeft: "20px",
                      marginTop: "20px",
                      marginBottom: "20px",
                      display: "inline-block",
                    }}
                    onClick={(e) => {
                      e.preventDefault();

                      mixpanelResumeLinkLinkedin(userData);

                      window.localStorage.removeItem("resumeInfo");
                      setResumeInfo(null);
                      chrome.runtime.sendMessage(yourExtensionId, {
                        dashboard: true,
                        linkedin: true,
                        message: "openSettingsUrl",
                        url: "https://www.linkedin.com/jobs/application-settings/",
                      });
                    }}
                  >
                    {resumeInfo
                      ? "Change Again"
                      : "Select Resume to use while Applying on Linkedin"}{" "}
                    &nbsp;
                    <i class="fas fa-arrow-right"></i>{" "}
                  </a>
                </div>
              )}
          </div>
        ) : (
          <></>
        )}

        <Form onSubmit={handleSubmit1} ref={formMain}>
          <Row style={styleform}>
            {["indeed", "careerBuilder", "glassdoor"].includes(platform) && (
              <Col md={12}>
                <Form.Group>
                  <Form.Label htmlFor="country">
                    Choose country (Optional)
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={countryoption}
                    id="country"
                    onChange={(e) => setCountryOption(e.target.value)}
                  >
                    {Object.keys(country_options).map((v) => {
                      return (
                        <option value={country_options[v].code}>
                          {country_options[v].name}
                        </option>
                      );
                    })}
                  </Form.Control>
                  <Form.Text id="passwordHelpBlock" muted>
                    Only countries mentioned in the dropdown are available for{" "}
                    {platform}.{" "}
                    {platform === "indeed" ? (
                      <a
                        href="https://www.indeed.com/worldwide"
                        target="_blank"
                        style={{ color: "#007bff" }}
                      >
                        More info here.
                      </a>
                    ) : (
                      <></>
                    )}
                  </Form.Text>
                </Form.Group>
              </Col>
            )}

            <Col md={4}>
              <Form.Label htmlFor="inputskill">
                Enter skill / job title*
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="text"
                id="inputskill"
                value={form.skill}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("skill", e.target.value)}
                isInvalid={!!errors.skill}
              />
              <Form.Control.Feedback type="invalid">
                {errors.skill}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - Enter only one single skill or job title like
                react or data analyst or marketing manager etc
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputlocation">
                Enter the job location / city (Optional)
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="text"
                value={form.location}
                id="inputlocation"
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("location", e.target.value)}
              />
              <Form.Text id="passwordHelpBlock" muted>
                For example - you can enter your job location like - bangalore
                or delhi or any other place etc.
              </Form.Text>
            </Col>
            <Col md={4}>
              <Form.Label htmlFor="inputnumber">
                Number of jobs to apply*
              </Form.Label>
              <Form.Control
                disabled={formDisable}
                type="number"
                id="inputnumber"
                value={form.numberOfJobs}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("numberOfJobs", e.target.value)}
                isInvalid={!!errors.numberOfJobs}
              />
              <Form.Control.Feedback type="invalid">
                {errors.numberOfJobs}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - 50 or 100 etc.
              </Form.Text>
            </Col>

            <Col md={12}>
              {" "}
              <ResumeJobScore form={form} setField={setField} />{" "}
              {/* <Form.Label htmlFor="resumeJobScore">
                Enter resume score
              </Form.Label>

              <Form.Control
                disabled={formDisable}
                type="text"
                id="resumeJobScore"
                value={form.resumeJobScore}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => setField("resumeJobScore", e.target.value)}
                isInvalid={!!errors.skill}
              />
              <Form.Control.Feedback type="invalid">
                {errors.skill}
              </Form.Control.Feedback>
              <Form.Text id="passwordHelpBlock" muted>
                For example - Enter only one single skill or job title like
                react or data analyst or marketing manager etc
              </Form.Text> */}
            </Col>

            <div className="advanceSearchContainer">
              <div className="advanceSearchContainerLeft">
                Job Title Filters - Advance Search
              </div>
              <div className="advanceSearchContainerRight">
                <div className="exactsearch">
                  <div className="exactsearchchild">
                    <p
                      style={{
                        color: "#007bff",
                        marginBottom: "0px",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                        cursor: "pointer",
                      }}
                    >
                      {advanceSearch ? (
                        <span onClick={() => setShowAdvanceSearch(true)}>
                          Configure
                        </span>
                      ) : (
                        ""
                      )}
                    </p>
                    <label
                      className={
                        "switch3" + (advanceSearch ? " switch3-checked" : "")
                      }
                    >
                      <input
                        type="checkbox"
                        checked={advanceSearch}
                        onChange={(e) => {
                          setAdvanceSearch(!advanceSearch);
                        }}
                      />
                      <div></div>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <AdvanceSearchModal
              show={showAdvanceSearch}
              excludeString={excludeString}
              setExcludeString={setExcludeString}
              mayIncludeString={mayIncludeString}
              setMayIncludeString={setMayIncludeString}
              handleClose={handleCloseSearch}
            />
            {/* <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={"/assests/alert.svg"}
                style={{
                  marginLeft: "30px",
                  marginTop: "10px",
                  height: "40px",
                }}
              />
              <p
                className="makesure"
                style={{ marginLeft: "10px", marginTop: "20px" }}
              >
                We are currently fixing the issue you might face on Indeed.
                <br />
                The extension update will be released with the fix between 6 to
                12 hours.
              </p>
            </div> */}

            {/* Testing resume match score  */}

            {/* testing finished resume match score  */}

            {/* <Col md={8} ref={button1}>
              <button
                type="submit"
                className="btn linkedinSubmitButton"
                ref={fetchbutton}
              >
                Click to fetch filters first
                <i className="fas fa-arrow-right"></i>{" "}
                <span
                  className="spinner-border spinner-border-sm"
                  style={{ marginLeft: "5px" }}
                  role="status"
                  aria-hidden="true"
                  ref={spinner}
                ></span>
              </button>
            </Col> */}

            <Row>
              <Col md={12}>
                {checkIfDemoAndNoPlan() ? (
                  <button
                    type="button"
                    ref={button2}
                    onClick={() => demoSubmit()}
                    className="btn linkedinSubmitButton"
                  >
                    Start Auto Applying{" "}
                    {form.numberOfJobs !== 0
                      ? `To ${form.numberOfJobs} Jobs`
                      : ""}
                    <i className="fas fa-arrow-right"></i>
                  </button>
                ) : (
                  <button
                    disabled={startApplying}
                    ref={button2}
                    type="submit"
                    className="btn linkedinSubmitButton"
                  >
                    Start Applying <i className="fas fa-arrow-right"></i>
                  </button>
                )}
              </Col>
            </Row>

            {/* <Col md={8}>
              <button
                disabled={startApplying}
                ref={button2}
                type="submit"
                className="btn linkedinSubmitButton"
              >
                Start Applying <i className="fas fa-arrow-right"></i>
              </button>
            </Col> */}

            {showSavedSearchButton && (
              <>
                {/* <Col
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                {configDummy?.totalJobs && configDummy.totalJobs >= 200 && (
                  <button
                    type="button"
                    style={saveButtonDisabled ? buttonMain : buttonMain1}
                    disabled={saveButtonDisabled}
                    onClick={() => {
                      setShowSavedSearchModal(true);
                    }}
                  >
                    {checkIfPresent()
                      ? "Update Search Configuration"
                      : "Save Search Configuration"}
                  </button>
                )}
              </Col> */}
              </>
            )}
          </Row>
        </Form>

        {/* <p ref={chooseParaText} style={{ marginLeft: "20px", color: "gray" }}>
          Choose any one filter only (optional)
        </p> */}

        {/* <Form onSubmit={handleSubmit1} ref={formMain1}>
          <Row style={filtersStyle} ref={filtersDiv}>
            {Object.keys(filterValues.current).map((key, index) => {
              return (
                <>
                  <Col md={6}>
                    {index === 1 && (
                      <button
                        className="btn filterReset"
                        type="button"
                        onClick={filterReset}
                      >
                        Reset
                      </button>
                    )}

                    <Form.Label>
                      {" "}
                      {key in filterLabels ? filterLabels[key] : key}{" "}
                    </Form.Label>
                    <Select
                      id={key}
                      isMulti={
                        multipleSelectFilterLabels.includes(key) ? true : false
                      }
                      onChange={(option) => {
                        console.log("onchange", option);
                        console.log("filtervalues", filterValues.current);
                        console.log(key, "key");

                        changeFiltersValuesPlatform(
                          platform,
                          option,
                          urlParts,
                          filterValues,
                          key,
                          setField
                        );

                        console.log(filterRefs, "filterRefs");
                        console.log(form, "form");
                      }}
                      key={index}
                      name={key}
                      ref={filterRefs[index]}
                      placeholder={
                        key in filterLabels ? filterLabels[key] : key
                      }
                      options={(() => {
                        console.log("filterValues:", filterValues);
                        return platform === "indeed" ||
                          platform === "ziprecruiter" ||
                          platform === "dice" ||
                          platform === "linkedin"
                          ? filterValues.current[key]
                          : filterValues.current[key].options;
                      })()}
                      className="basic-multi-select"
                      classNamePrefix="select"
                    />
                  </Col>
                </>
              );
            })}
          </Row>
          <Row>
            <Col md={12}>
              {checkIfDemoAndNoPlan() ? (
                <button
                  type="button"
                  ref={button2}
                  onClick={() => demoSubmit()}
                  className="btn linkedinSubmitButton"
                >
                  Start Auto Applying{" "}
                  {form.numberOfJobs !== 0
                    ? `To ${form.numberOfJobs} Jobs`
                    : ""}
                  <i className="fas fa-arrow-right"></i>
                </button>
              ) : (
                <button
                  disabled={startApplying}
                  ref={button2}
                  type="submit"
                  className="btn linkedinSubmitButton"
                >
                  Start Applying <i className="fas fa-arrow-right"></i>
                </button>
              )}
            </Col>
          </Row>

          <p ref={para} style={parastyle}>
            Choose only one filter value!
          </p>
        </Form> */}

        <Row style={styleform1}>
          {progressPercent.current === 100 ? (
            <Col md={5} style={{ marginBottom: "20px" }}>
              <Feedback
                platformName={platform}
                totalJobsApplied={count.current}
              />
            </Col>
          ) : (
            <Col md={5}></Col>
          )}
          <Col md={7} ref={initprocessingFinal} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            {showPlanPurchase ? (
              <>
                <div className="planPurchaseRequiredMainDiv">
                  <p className="planPurchaseRequired">
                    You don't have a plan, please purchase a plan to continue
                  </p>
                  <button
                    onClick={() => {
                      mixpanelPlanPurchase(userData, platform);

                      setTimeout(() => {
                        history.push("/pricing");
                      }, 500);
                    }}
                    className="button1"
                  >
                    Purchase Plan
                  </button>
                </div>
              </>
            ) : (
              <div className="linkedinInitTop">
                <div>Percentage complete ({100} %)</div>
                <div>Total jobs applied ({finalJobCount})</div>
              </div>
            )}
            {showPlanPurchase ? (
              <></>
            ) : (
              <div className="linkedinInitTop">
                <div>
                  <Progress done={100} />
                </div>
                <div className="jobsApplied">{finalJobCount}</div>
              </div>
            )}
          </Col>
          <Col md={7} ref={initprocessing} className="linkedinInit">
            <button
              type="button"
              className="linkedinReset btn"
              onClick={resetFull}
            >
              Reset
            </button>
            <div className="linkedinInitTop1">
              <div ref={processing} className="processing">
                Automation in Process...
              </div>
            </div>
            <div className="linkedinInitTop1">
              <div>
                Please do not switch tabs till this session's completion.
              </div>
            </div>
          </Col>
        </Row>
        {/* <Row>
          {progressPercent.current == 100 && (
            <>
              <div class="col-10 wrapper mx-auto">
                <h3 class="feedbackform_header issuesHeader">Common Issues</h3>

                {(() => {
                  let allIssues = [
                    ...faqObj.indeed.issues,
                    ...faqObj.common.issues,
                  ];
                  return allIssues.map((x, index) => (
                    <div className="container" key={index}>
                      <div
                        className="question"
                        ref={(el) => (questionRefs.current[index] = el)}
                      >
                        {x.question}
                      </div>
                      <div class="answercont">
                        <div class="answer">
                          {x.answer}
                          {x.answerPoints && (
                            <div className="answerPointsDiv">
                              {x.answerPoints.map((point, index) => (
                                <p>{index + "). " + point}</p>
                              ))}
                            </div>
                          )}

                          {x.question(
                            <a
                              href="#"
                              className="text-primary"
                              style={{
                                marginLeft: "0px",
                                marginTop: "20px",
                                marginBottom: "10px",
                                display: "inline-block",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                setModalTitle(x.question);
                                setShowIndeedModal(true);
                              }}
                            >
                              {x.anchorText}
                            </a>
                          )}
                          {x.videoId && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                            >
                              <iframe
                                className="myVideoClass"
                                loop="true"
                                autoPlay="true"
                                controls="true"
                                id="vid"
                                src={`https://www.youtube.com/embed/${x.videoId}?autoplay=1&loop=1&vq=hd1080`}
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ));
                })()}
              </div>
              <div class="col-12" style={{ height: "100px" }}>
                {" "}
              </div>
              <IndeedArticle
                showProp={showIndeedModal}
                hideShow={closeModal}
                title={modalTitle}
              />
            </>
          )}
        </Row> */}
      </div>
    </div>
  );
}

export default RemotePlatform;
