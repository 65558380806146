import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesGlassdoor() {
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresGlassdoor() {
  let filterRefsGlassdoor = 12;

  let initialFormStateGlassdoor = {
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  };

  let country_optionsGlassdoor = [
    { name: "United States", code: "us" },
    { name: "United Kingdom", code: "uk" },
    { name: "Canada", code: "ca" },
    { name: "Australia", code: "au" },
    { name: "India", code: "in" },
    { name: "Singapore", code: "sg" },
    { name: "New Zealand", code: "nz" },
    { name: "Ireland", code: "ie" },

    // { name: "New Zealand", code: "nz" },
    // { name: "Ireland", code: "ie" },
  ];

  let countryUrlMapGlassdoor = {
    us: "https://www.glassdoor.com/Job/",
    uk: "https://www.glassdoor.co.uk/Job/",
    ca: "https://www.glassdoor.ca/Job/",
    au: "https://www.glassdoor.com.au/Job/",
    in: "https://www.glassdoor.co.in/Job/",
    sg: "https://www.glassdoor.sg/Job/",
    nz: "https://www.glassdoor.co.nz/Job/",
    ie: "https://www.glassdoor.ie/Job/",
  };

  return {
    filterRefsGlassdoor,
    country_optionsGlassdoor,
    countryUrlMapGlassdoor,
    initialFormStateGlassdoor,
  };
}

export function startAutomationGlassdoor(
  countryUrlMap,
  country,
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  uuidv1,
  resumeUrlId
) {
  let startAutomationMessage = "glassdoorFetchFilters";

  const skills = form.skill.split(" ");
  let string1 = "";
  for (let index = 0; index < skills.length; index++) {
    const element = skills[index];
    if (index === 0) {
      string1 += element;
    } else {
      string1 += "-" + element;
    }
  }

  string1 += `-jobs-SRCH_KO0,${string1.length}.htm`;

  if (!(country in countryUrlMap)) {
    country = "us";
  }

  let startAutomationUrl =
    countryUrlMap[country] + `${string1}?applicationType=1`;

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    glassdoorFetchFilters: 0,
    glassdoorLimit: Math.min(form.numberOfJobs, 200),
    glassdoorJobLinks: [],
    glassdoorResumeUrlId: resumeUrlId,
    glassdoorLocation: form.location,
    glassdoorCountryCode: country,
    uniquesessionid: uuidv1(),
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: `${startAutomationUrl}`,
    selected: true,
    glassdoor: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitGlassdoor(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData,
  resumeUrlId,
  urlParts
) {
  let url = urlMain;

  console.log(urlParts, "urlParts");

  let finalSubmitSaveValues = {
    glassdoorSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    glassdoorBaseUrl: url,
    glassdoorLimit: Math.min(form.numberOfJobs, 200),
    glassdoorJobLinks: [],
    glassdoorResumeId: resumeId,
    session: {
      platformName: "glassdoor",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    glassdoorResumeUrlId: resumeUrlId,
    glassdoorSetFilters: urlParts,
  };

  let finalSubmitMessage = "glassdoorStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionGlassdoor(
  configDummy,
  count,
  sessionLinks,
  userData
) {
  let platformJobsApplied = configDummy.jobsByPlatform["glassdoor"] + count;

  let glassdoorJobApplicationLinksFinal = [...sessionLinks.current];
  if ("glassdoorJobApplicationLinksFinal" in userData) {
    glassdoorJobApplicationLinksFinal = [
      ...userData.glassdoorJobApplicationLinksFinal,
      ...glassdoorJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    glassdoorJobApplicationLinksFinal: glassdoorJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hGlassdoor(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome
) {
  if ("glassdoor" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        delete request.filterValues["Company rating"];
        filterValues.current = request.filterValues;
        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of Glassdoor. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);

          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              window.open("https://www.glassdoor.com/index.htm", "_blank");

              window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = request.filterValues;
          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                glassdoorFetchFilters: 1,
              },
              glassdoor: true,
              message: "glassdoorFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_GLASSDOOR,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "glassdoor");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "glassdoor");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
      if (request.message === "updateLocationUrl") {
        console.log("url final here", request.url);
        setUrlMain(request.url);
      }
    }
  }
}

export function changeFiltersValuesGlassdoor(option, urlParts, key) {
  if (option) {
    urlParts[key] = option.label;
    console.info("url-parts", urlParts);
  }
}

export function remoteFinalSubmitGlassdoor(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData,
  resumeUrlId,
  urlParts,
  country,
  countryUrlMap
) {
  const skills = form.skill.split(" ");
  let string1 = "";
  for (let index = 0; index < skills.length; index++) {
    const element = skills[index];
    if (index === 0) {
      string1 += element;
    } else {
      string1 += "-" + element;
    }
  }

  string1 += `-jobs-SRCH_KO0,${string1.length}.htm`;

  if (!(country in countryUrlMap)) {
    country = "us";
  }

  let string2 = "&remoteWorkType=1";

  let url = countryUrlMap[country] + `${string1}?applicationType=1` + string2;

  let finalSubmitSaveValues = {
    glassdoorSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    glassdoorBaseUrl: url,
    glassdoorLimit: Math.min(form.numberOfJobs, 200),
    glassdoorJobLinks: [],
    glassdoorResumeId: resumeId,
    session: {
      platformName: "glassdoor",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    glassdoorResumeUrlId: resumeUrlId,
    glassdoorSetFilters: urlParts,
    glassdoorLocation: form.location,
    glassdoorCountryCode: country,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
    glassdoorFetchFilters: 1,
  };

  let finalSubmitMessage = "glassdoorStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
