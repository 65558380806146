import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import { useStateStore } from "../../store/index";
function InviteMemberModal({ showProp, hideShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const setUserInvites = useStateStore((state) => state.setUserInvites);
  const userInvites = useStateStore((state) => state.userInvites);
  const token = useStateStore((state) => state.token);
  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      handleClose();
      setShowMessage("");
    }, 2500);
  };

  const inviteTeamMember = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    if (value != "") {
      axios
        .post(
          `https://backend.lazyapply.com/v2/updateInvite/${v2Id}`,
          {
            email: value,
            accepted: 0,
            invitedBy: userData.email,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          console.log(response, "response submitcode");
          showCustomMessage("Invited Successfully");
          setUserInvites({
            [value]: { email: value, accepted: 0, invitedBy: userData.email },
          });
          setDisable(false);
        })
        .catch((error) => {
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      setDisable(false);
      showCustomMessage("Resume name cannot be empty");
    }
  };

  function inviteMember(event) {
    event.preventDefault();
    if (Object.keys(userInvites).length > 0) {
      let present = false;
      for (let index = 0; index < Object.keys(userInvites).length; index++) {
        const key = Object.keys(userInvites)[index];
        if (key === value) {
          present = true;
          break;
        }
      }
      if (present) {
        showCustomMessage("Already invited this user!");
      } else if (value === userData.email) {
        showCustomMessage("You are already a part of workspace");
      } else {
        inviteTeamMember();
      }
    } else {
      inviteTeamMember();
    }
  }

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter email of team member to invite</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <Form onSubmit={inviteMember}>
            <Form.Control
              required
              type="email"
              id="invite user"
              placeholder="abc@gmail.com"
              onChange={(e) => setValue(e.target.value.toLowerCase())}
            />
            <Form.Control.Feedback type="invalid">
              Please provide a valid email address
            </Form.Control.Feedback>
            <button style={buttonSave} disabled={disable} type="submit">
              Invite Now
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default InviteMemberModal;
