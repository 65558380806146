import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { useStateStore } from "../store/index";
function PhoneModal({ showProp }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    console.log("no close");
  };
  const [value, setValue] = useState("");
  const [valid, setValid] = useState(false);
  const token = useStateStore((state) => state.token);
  const [isUpdating, setUpdating] = useState(false);
  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "10px",
  };

  function setPhoneNumber(v) {
    console.log(v, "Phone Number");
    if (v) {
      setValue(v);
    }

    if (v && isValidPhoneNumber(v) === true) {
      setValid(true);

      console.log("Save Number");
    } else {
      setValid(false);

      console.log("Show error");
    }
  }

  function saveMobileNumber() {
    const phoneNumber = value;
    const country = parsePhoneNumber(value).country;
    console.log("Api call here");
    setUpdating(true);
    axios
      .post(
        "https://backend.lazyapply.com/updatePhone",
        {
          phoneNumber,
          country,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        setUpdating(false);
        setShow(false);
        console.log(response);
      })
      .catch((error) => {
        setUpdating(false);
        console.log(error);
      });
  }

  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="md"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Enter Phone Number To Continue</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px" }}>
          <PhoneInput
            defaultCountry="IN"
            placeholder="Enter phone number"
            value={value}
            onChange={(v) => setPhoneNumber(v)}
          />
          {valid ? (
            <p
              style={{
                color: "green",
                marginLeft: "40px",
                marginTop: "10px",
              }}
            >
              Valid phone number
            </p>
          ) : (
            <p
              className=""
              style={{ color: "orange", marginLeft: "40px", marginTop: "10px" }}
            >
              Not a valid phone number
            </p>
          )}

          <button
            style={buttonSave}
            disabled={!valid || isUpdating}
            onClick={saveMobileNumber}
          >
            Submit To Continue{" "}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default PhoneModal;
