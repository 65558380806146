import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CustomAlert from "../modal";
import { useStateStore } from "../../store/index";
import CreateResumeModal from "./createResumeModalV2";
// import resumeimg from "../../resume.png";
import axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import ChangeResumeNameModal from "./changeResumeNameModalV2";
import ModalConfirm from "./modalConfirmNewV2";
import { mixpanel } from "../../index";

function EditResumes() {
  const token = useStateStore((state) => state.token);
  const history = useHistory();
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const resumeModifiedV3 = useStateStore((state) => state.resumeModifiedV3);
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setResumesCompleteV3 = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const [resumePresent, setResumePresent] = useState(false);
  const [completedResumes, setCompletedResumes] = useState({});
  const globalLogout = useStateStore((state) => state.globalLogout);
  function goBack() {
    mixpanel.track("go_back_button edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
    history.goBack();
  }

  useEffect(() => {
    mixpanel.track("edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, []);

  useEffect(() => {
    // get all resumes
    const v2Id = userData?.planDetails?.v2Id;
    loading.current.style.display = "block";
    axios
      .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log("resumescompleted", response);
        loading.current.style.display = "none";
        if (
          "resumes" in response.data &&
          Object.keys(response.data.resumes).length > 0
        ) {
          setResumesCompleteV3(response.data.resumes);
          setCompletedResumes(response.data.resumes);
        }
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured");
      });
  }, [userData, resumeModifiedV3]);

  const [resumemeta, setresumemeta] = useState(0);
  const setUserResumeV3 = useStateStore((state) => state.setUserResumeV3);
  useEffect(() => {
    if (resumemeta === 0 && userData?.planDetails?.v2Id) {
      loading.current.style.display = "block";
      setresumemeta(1);
      const v2Id = userData?.planDetails?.v2Id;
      axios
        .get(
          `https://backend.lazyapply.com/v2/resumesV3/checkResumesValidation/${v2Id}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((response) => {
          loading.current.style.display = "none";
          console.log("resumesall", response);
          if ("resumes" in response.data && response.data.resumes.length > 0) {
            setUserResumeV3(response.data.resumes);
            setResumePresent(true);
          } else {
            setResumePresent(false);
          }
        })
        .catch((err) => {
          loading.current.style.display = "none";
          console.log("some error occured");
          if (err.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            console.log("error");
          }
        });
    }
  }, [userData]);

  const createNew = () => {
    mixpanel.track("upload_resume_button edit_profile_page dashboard", {
      email: userData?.email,
      href: window.location.href,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });

    if (
      userData?.planDetails?.resumeLimit &&
      userData?.planDetails?.resumeLimit > userResumeV3.length
    )
      setShowCreateResumeModal(true);
    else {
      setmessage("Cannot create more resumes, resume limit reached");
      settitle("Resume limit reached!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 3000);
    }
  };

  function closeModal() {
    setShowCreateResumeModal(false);
  }
  const [showCreateResumeModal, setShowCreateResumeModal] = useState(false);
  const loading = useRef(null);

  function closeModalNameChange() {
    setShowCreateResumeModalNameChange(false);
  }
  const [showCreateResumeModalNameChange, setShowCreateResumeModalNameChange] =
    useState(false);
  const [selectResume, setSelectedResume] = useState({
    currentName: "",
    resumeId: "",
  });

  function closeModalConfirm() {
    setShowModalConfirm(false);
  }
  const [showModalConfirm, setShowModalConfirm] = useState(false);

  useEffect(() => {
    if (userResumeV3.length == 0) {
      setResumePresent(false);
    }
  }, [userResumeV3]);

  return (
    <div style={{ position: "relative" }}>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <CreateResumeModal
        showProp={showCreateResumeModal}
        hideShow={closeModal}
      />
      <ModalConfirm
        show={showModalConfirm}
        handleClose={closeModalConfirm}
        currentName={selectResume.currentName}
        resumeId={selectResume.resumeId}
      />
      <ChangeResumeNameModal
        showProp={showCreateResumeModalNameChange}
        hideShow={closeModalNameChange}
        currentName={selectResume.currentName}
        resumeId={selectResume.resumeId}
      />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <button className="additionalInfo btn" onClick={createNew}>
        Upload Resume/CV
      </button>
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>

      {resumePresent ? (
        <>
          <p className="editresumeheading">Edit your resumes/CV's</p>
          <div className="v2resumeAlreadyPresent">
            <div className="row">
              {userResumeV3.map((data, index) => {
                return (
                  <div className="col-lg-3 col-md-4 col-sm-6">
                    <div
                      onClick={() => {
                        mixpanel.track(
                          "edit_resume_button edit_profile_page dashboard",
                          {
                            email: userData?.email,
                            resumeId: data.resumeId,
                            resumeName: data.resumename,
                            href: window.location.href,
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                        history.push(
                          `/dashboard/editresumesv2/${data.resumeId}`
                        );
                      }}
                      className="resumeblock"
                      key={index}
                      style={{ cursor: "pointer" }}
                    >
                      {data.validation === 1 ? (
                        <span className="resumeblockstatus statuscompleted">
                          Completed
                        </span>
                      ) : (
                        <span className="resumeblockstatus statuspending">
                          Not Completed
                        </span>
                      )}
                      <div className="resumeEditOptions">
                        <Dropdown onClick={(e) => e.stopPropagation()}>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          />

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => {
                                mixpanel.track(
                                  "delete_resume_button edit_profile_page dashboard",
                                  {
                                    email: userData?.email,
                                    currentName: data.resumename,
                                    resumeId: data.resumeId,
                                    href: window.location.href,
                                    plan_details: userData?.planDetails || {},
                                    v2Id: userData?.planDetails?.v2Id || "",
                                  }
                                );
                                setSelectedResume({
                                  currentName: data.resumename,
                                  resumeId: data.resumeId,
                                });
                                setShowModalConfirm(true);
                              }}
                            >
                              Delete Resume
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                mixpanel.track(
                                  "edit_resume_name_button edit_profile_page dashboard",
                                  {
                                    email: userData?.email,
                                    currentName: data.resumename,
                                    resumeId: data.resumeId,
                                    href: window.location.href,
                                    plan_details: userData?.planDetails || {},
                                    v2Id: userData?.planDetails?.v2Id || "",
                                  }
                                );
                                setSelectedResume({
                                  currentName: data.resumename,
                                  resumeId: data.resumeId,
                                });
                                setShowCreateResumeModalNameChange(true);
                              }}
                            >
                              Edit Resume Name
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>

                      <div className="resumeblockimg">
                        <img src={"/assests/resume.png"} />
                      </div>
                      <div className="resumeblocktext">
                        {index}.{" " + data.resumename}
                      </div>

                      <div className="resumeblockedit">
                        <button
                          onClick={() => {
                            mixpanel.track(
                              "edit_resume_button edit_profile_page dashboard",
                              {
                                email: userData?.email,
                                resumeId: data.resumeId,
                                resumeName: data.resumename,
                                href: window.location.href,
                                plan_details: userData?.planDetails || {},
                                v2Id: userData?.planDetails?.v2Id || "",
                              }
                            );
                            history.push(
                              `/dashboard/editresumesv2/${data.resumeId}`
                            );
                          }}
                        >
                          Edit Resume
                        </button>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="v2resume">
            <button className="createfirstresume" onClick={createNew}>
              Upload your first Resume/Cv now
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default EditResumes;
