import {
  useStateVariablesIndeed,
  featuresIndeed,
  startAutomationIndeed,
  finalSubmitIndeed,
  hIndeed,
  saveSessionIndeed,
  changeFiltersValuesIndeed,
  remoteFinalSubmitIndeed,
} from "./indeed";

import {
  useStateVariablesCareerBuilder,
  featuresCareerBuilder,
  startAutomationCareerBuilder,
  finalSubmitCareerBuilder,
  hCareerBuilder,
  saveSessionCareerBuilder,
  changeFiltersValuesCareerBuilder,
  remoteFinalSubmitCareerBuilder,
} from "./careerBuilder";

import {
  useStateVariablesDice,
  featuresDice,
  startAutomationDice,
  finalSubmitDice,
  hDice,
  saveSessionDice,
  changeFiltersValuesDice,
  remoteFinalSubmitDice,
} from "./dice";

import {
  useStateVariablesGlassdoor,
  featuresGlassdoor,
  startAutomationGlassdoor,
  finalSubmitGlassdoor,
  hGlassdoor,
  saveSessionGlassdoor,
  changeFiltersValuesGlassdoor,
  remoteFinalSubmitGlassdoor,
} from "./glassdoor";

import {
  useStateVariablesLinkedin,
  featuresLinkedin,
  startAutomationLinkedin,
  finalSubmitLinkedin,
  hLinkedin,
  saveSessionLinkedin,
  changeFiltersValuesLinkedin,
  remoteFinalSubmitLinkedin,
} from "./linkedin";

import {
  useStateVariablesSimplyHired,
  featuresSimplyHired,
  startAutomationSimplyHired,
  finalSubmitSimplyHired,
  hSimplyHired,
  saveSessionSimplyHired,
  changeFiltersValuesSimplyHired,
  remoteFinalSubmitSimplyHired,
} from "./simplyHired";

import {
  useStateVariablesZiprecruiter,
  featuresZiprecruiter,
  startAutomationZiprecruiter,
  finalSubmitZiprecruiter,
  hZiprecruiter,
  saveSessionZiprecruiter,
  changeFiltersValuesZiprecruiter,
  remoteFinalSubmitZiprecruiter,
} from "./ziprecruiter";

export function useStateVariablesPlatform(platform) {
  const stateVariablesIndeed = useStateVariablesIndeed();
  const stateVariablesCareerBuilder = useStateVariablesCareerBuilder();
  const stateVariablesDice = useStateVariablesDice();
  const stateVariablesGlassdoor = useStateVariablesGlassdoor();
  const stateVariablesLinkedin = useStateVariablesLinkedin();
  const stateVariablesSimplyHired = useStateVariablesSimplyHired();
  const stateVariablesZiprecruiter = useStateVariablesZiprecruiter();

  if (platform === "indeed") {
    return stateVariablesIndeed;
  } else if (platform === "careerBuilder") {
    return stateVariablesCareerBuilder;
  } else if (platform === "dice") {
    return stateVariablesDice;
  } else if (platform === "glassdoor") {
    return stateVariablesGlassdoor;
  } else if (platform === "linkedin") {
    return stateVariablesLinkedin;
  } else if (platform === "simplyHired") {
    return stateVariablesSimplyHired;
  } else if (platform === "ziprecruiter") {
    return stateVariablesZiprecruiter;
  }

  return null;
}

export function featuresPlatform(platform) {
  let filterRefs = "";

  let initialFormState = {};

  let country_options = [];

  let countryUrlMap = {};

  let countryLoginUrlMap = {};

  if (platform === "indeed") {
    let { country_optionsIndeed, initialFormStateIndeed, filterRefsIndeed } =
      featuresIndeed();

    filterRefs = filterRefsIndeed;
    initialFormState = initialFormStateIndeed;
    country_options = country_optionsIndeed;
  } else if (platform === "careerBuilder") {
    let {
      filterRefsCareerBuilder,
      country_optionsCareerBuilder,
      initialFormStateCareerBuilder,
      countryUrlMapCareerBuilder,
      countryLoginUrlMapCareerBuilder,
    } = featuresCareerBuilder();

    filterRefs = filterRefsCareerBuilder;
    initialFormState = initialFormStateCareerBuilder;
    country_options = country_optionsCareerBuilder;
    countryUrlMap = countryUrlMapCareerBuilder;
    countryLoginUrlMap = countryLoginUrlMapCareerBuilder;
  } else if (platform === "dice") {
    let { filterRefsDice, initialFormStateDice } = featuresDice();

    filterRefs = filterRefsDice;
    initialFormState = initialFormStateDice;
  } else if (platform === "glassdoor") {
    let {
      filterRefsGlassdoor,
      country_optionsGlassdoor,
      countryUrlMapGlassdoor,
      initialFormStateGlassdoor,
    } = featuresGlassdoor();

    filterRefs = filterRefsGlassdoor;
    initialFormState = initialFormStateGlassdoor;
    country_options = country_optionsGlassdoor;
    countryUrlMap = countryUrlMapGlassdoor;
  } else if (platform === "linkedin") {
    let { filterRefsLinkedin, initialFormStateLinkedin } = featuresLinkedin();

    filterRefs = filterRefsLinkedin;
    initialFormState = initialFormStateLinkedin;
  } else if (platform === "simplyHired") {
    let {
      filterRefsSimplyHired,
      initialFormStateSimplyHired,
      country_optionsSimplyHired,
      countryUrlMapSimplyHired,
    } = featuresSimplyHired();

    filterRefs = filterRefsSimplyHired;
    initialFormState = initialFormStateSimplyHired;
    country_options = country_optionsSimplyHired;
    countryUrlMap = countryUrlMapSimplyHired;
  } else if (platform === "ziprecruiter") {
    let { filterRefsZiprecruiter, initialFormStateZiprecruiter } =
      featuresZiprecruiter();

    filterRefs = filterRefsZiprecruiter;
    initialFormState = initialFormStateZiprecruiter;
  }

  return {
    filterRefs,
    initialFormState,
    country_options,
    countryUrlMap,
    countryLoginUrlMap,
  };
}

export function startAutomationPlatform(
  platform,
  form,
  country,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  countryUrlMap,
  resumeUrlId,
  resumeInfo,
  resumeArr,
  uuidv1
) {
  if (platform === "indeed") {
    return startAutomationIndeed(
      form,
      country,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      uuidv1
    );
  } else if (platform === "careerBuilder") {
    return startAutomationCareerBuilder(
      countryUrlMap,
      country,
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      uuidv1
    );
  } else if (platform === "dice") {
    return startAutomationDice(
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString
    );
  } else if (platform === "glassdoor") {
    return startAutomationGlassdoor(
      countryUrlMap,
      country,
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      uuidv1,
      resumeUrlId
    );
  } else if (platform === "linkedin") {
    return startAutomationLinkedin(
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      uuidv1,
      resumeInfo,
      resumeArr
    );
  } else if (platform === "simplyHired") {
    return startAutomationSimplyHired(
      countryUrlMap,
      country,
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      uuidv1
    );
  } else if (platform === "ziprecruiter") {
    return startAutomationZiprecruiter(
      form,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString
    );
  }
}

export function finalSubmitPlatform(
  platform,
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  resumeId,
  formRef,
  resumeData,
  form1,
  urlMain,
  resumeUrlId,
  form,
  urlParts
) {
  if (platform === "indeed") {
    return finalSubmitIndeed(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      resumeId,
      formRef,
      resumeData,
      form1,
      urlMain,
      urlParts
    );
  } else if (platform === "careerBuilder") {
    return finalSubmitCareerBuilder(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      urlParts,
      form,
      resumeId,
      formRef,
      resumeData
    );
  } else if (platform === "dice") {
    return finalSubmitDice(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData
    );
  } else if (platform === "glassdoor") {
    return finalSubmitGlassdoor(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData,
      resumeUrlId,
      urlParts
    );
  } else if (platform === "linkedin") {
    return finalSubmitLinkedin(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      form,
      resumeId,
      formRef,
      urlMain,
      resumeData
    );
  } else if (platform === "simplyHired") {
    return finalSubmitSimplyHired(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData,
      urlParts
    );
  } else if (platform === "ziprecruiter") {
    return finalSubmitZiprecruiter(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData
    );
  }
}

export function saveSessionPlatform(
  platform,
  configDummy,
  count,
  sessionLinks,
  userData
) {
  if (platform === "indeed") {
    return saveSessionIndeed(configDummy, count, sessionLinks, userData);
  } else if (platform === "careerBuilder") {
    return saveSessionCareerBuilder(configDummy, count, sessionLinks, userData);
  } else if (platform === "dice") {
    return saveSessionDice(configDummy, count, sessionLinks, userData);
  } else if (platform === "glassdoor") {
    return saveSessionGlassdoor(configDummy, count, sessionLinks, userData);
  } else if (platform === "linkedin") {
    return saveSessionLinkedin(configDummy, count, sessionLinks, userData);
  } else if (platform === "simplyHired") {
    return saveSessionSimplyHired(configDummy, count, sessionLinks, userData);
  } else if (platform === "ziprecruiter") {
    return saveSessionZiprecruiter(configDummy, count, sessionLinks, userData);
  }
}

export function hPlatform(
  platform,
  request,
  sessionLinks,
  count,
  selectResumeRef1,
  form,
  formMain,
  setUrlMain,
  countryLoginUrlMap,
  countryoption,
  setResumeArr,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  button1,
  setShowSavedSearchButton,
  filtersDiv,
  setheightMain,
  heightMain,
  chrome,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  resumeRef1,
  resumeRef2,
  resumeRef3,
  mainFilters
) {
  if (platform === "indeed") {
    return hIndeed(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      button1,
      setShowSavedSearchButton,
      filtersDiv,
      setheightMain,
      heightMain,
      chrome,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history
    );
  } else if (platform === "careerBuilder") {
    return hCareerBuilder(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome,
      countryLoginUrlMap,
      countryoption
    );
  } else if (platform === "dice") {
    console.log(request, "request dice");

    return hDice(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome,
      mainFilters
    );
  } else if (platform === "glassdoor") {
    return hGlassdoor(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome
    );
  } else if (platform === "linkedin") {
    return hLinkedin(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome,
      setResumeArr,
      resumeRef1,
      resumeRef2,
      resumeRef3
    );
  } else if (platform === "simplyHired") {
    return hSimplyHired(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome
    );
  } else if (platform === "ziprecruiter") {
    return hZiprecruiter(
      request,
      sessionLinks,
      count,
      setc,
      progressPercent,
      formRef,
      setpp,
      sessionLinksBefore,
      fetchbutton,
      spinner,
      setIndeedRunning,
      setmessage,
      settitle,
      setmodalshow,
      resetFull,
      filterValues,
      setformDisable,
      chooseParaText,
      formMain1,
      setShowSavedSearchButton,
      button1,
      filtersDiv,
      setheightMain,
      heightMain,
      yourExtensionId,
      smoothScrollToBottom,
      setShowPlanPurchase,
      button2,
      processing,
      smoothScrollToTop,
      initprocessingFinal,
      initprocessing,
      selectResumeRef,
      indeedReset,
      userData,
      sessionId,
      token,
      setFinalJobCount,
      selectResumeRef1,
      form,
      formMain,
      messages,
      MESSAGES,
      MESSAGETYPE,
      chromeVersion,
      saveSession,
      globalLogout,
      history,
      setUrlMain,
      chrome
    );
  }
}

export function changeFiltersValuesPlatform(
  platform,
  option,
  urlParts,
  filterValues,
  key,
  setField
) {
  if (platform === "indeed") {
    return changeFiltersValuesIndeed(option, urlParts, key);
  } else if (platform === "careerBuilder") {
    return changeFiltersValuesCareerBuilder(
      option,
      urlParts,
      filterValues,
      key
    );
  } else if (platform === "dice") {
    return changeFiltersValuesDice(option, key, setField);
  }

  // console.log(platform, "platform");
  else if (platform === "glassdoor") {
    return changeFiltersValuesGlassdoor(option, urlParts, key);
  } else if (platform === "linkedin") {
    return changeFiltersValuesLinkedin(option, key, setField);
  } else if (platform === "simplyHired") {
    return changeFiltersValuesSimplyHired(option, urlParts, key, filterValues);
  } else if (platform === "ziprecruiter") {
    return changeFiltersValuesZiprecruiter(option, key, setField);
  }
}

export const filterLabels = {
  employerOptions: "Employer Type",
  employmentTypeOptions: "Employment Type",
  dateposted: "Date Posted",
  remoteJobs: "Remote Only",
  refine_by_location_type: "Location Type ( Remote/ In Person)",
  days: "Posted Time",
  refine_by_employment: "Employment Type",
  radius: "Distance",
  datePosted: "Date Posted",
  jobcompanyOptions: "Companies",
  jobtitleOptions: "Job Title",
  jobindustryOptions: "Industry",
  jobfunctionOptions: "Job Function",
  jobWorkplaceOptions: "Job Workplace (Remote / Onsite)",
  jobSalaryOptions: "Job Salary",
  jobBenifitOptions: "Job Benfitis",
  jobTypeOptions: "Job Type",
  experienceOptions: "Experience Level",
  "25 miles": "Distance",
  "all job types": "Job Type",
  "all salaries": "Salary",
  anytime: "Date Posted",
};

export const multipleSelectFilterLabels = [
  "employerOptions",
  "employmentTypeOptions",
  "experienceOptions",
  "jobfunctionOptions",
  "jobtitleOptions",
  "jobTypeOptions",
  "jobindustryOptions",
  "jobcompanyOptions",
  "jobWorkplaceOptions",
];

export function filterResetPlatform(
  platform,
  urlParts,
  filterRefs,
  filterValues,
  setForm,
  form
) {
  urlParts = {};

  for (let ref of filterRefs) {
    if (ref.current) {
      ref.current.clearValue();
    }
  }
  // filterValues.current = {};

  if (platform === "indeed") {
    setForm({
      ...form,
    });
  } else if (platform === "careerBuilder") {
    setForm({
      ...form,
      location: "",
      skill: "",
    });
  } else if (platform === "dice") {
    setForm({
      ...form,
      datePosted: "",
      remoteJobs: "",
      employmentType: [],
      employerType: [],
    });
  } else if (platform === "glassdoor") {
    setForm({
      ...form,
    });
  } else if (platform === "linkedin") {
    setForm({
      ...form,
      jobtype: [],
      experience: [],
      datePosted: "",
      industry: [],
      jobFunction: [],
      jobUnder10: false,
      jobCompany: [],
      jobTitle: [],
      jobWorkplace: [],
      jobBenifits: [],
      jobSalary: "",
    });
  } else if (platform === "simplyHired") {
    setForm({
      ...form,
      location: "",
      skill: "",
    });
  } else if (platform === "ziprecruiter") {
    setForm({
      ...form,
      radiusOptions: "",
      orgOptions: "",
      daysOptions: "",
      titleOptions: "",
      salaryOptions: "",
      remote: "",
      tagsOptions: "",
    });
  }
}

export function remoteFinalSubmitPlatform(
  platform,
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  resumeId,
  formRef,
  resumeData,
  form1,
  urlMain,
  resumeUrlId,
  form,
  urlParts,
  country,
  countryUrlMap,
  resumeInfo,
  resumeArr
) {
  if (platform === "indeed") {
    return remoteFinalSubmitIndeed(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      resumeId,
      formRef,
      resumeData,
      form1,
      country,
      form
    );
  } else if (platform === "careerBuilder") {
    return remoteFinalSubmitCareerBuilder(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      country,
      countryUrlMap,
      form,
      resumeId,
      formRef,
      resumeData
    );
  } else if (platform === "dice") {
    return remoteFinalSubmitDice(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData
    );
  } else if (platform === "glassdoor") {
    return remoteFinalSubmitGlassdoor(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData,
      resumeUrlId,
      urlParts,
      country,
      countryUrlMap
    );
  } else if (platform === "linkedin") {
    return remoteFinalSubmitLinkedin(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      form,
      resumeId,
      formRef,
      urlMain,
      resumeData,
      resumeInfo,
      resumeArr
    );
  } else if (platform === "simplyHired") {
    return remoteFinalSubmitSimplyHired(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData,
      country,
      countryUrlMap
    );
  } else if (platform === "ziprecruiter") {
    return remoteFinalSubmitZiprecruiter(
      sessionId,
      advanceSearch,
      stringToArray,
      excludeString,
      mayIncludeString,
      urlMain,
      form,
      resumeId,
      formRef,
      resumeData
    );
  }
}

export const remotePlatforms = [
  {
    label: "Indeed",
    value: "indeed",
  },

  {
    label: "Career Builder",
    value: "careerBuilder",
  },

  {
    label: "Dice",
    value: "dice",
  },

  {
    label: "Glassdoor",
    value: "glassdoor",
  },

  {
    label: "Linkedin",
    value: "linkedin",
  },

  {
    label: "Simply Hired",
    value: "simplyHired",
  },

  {
    label: "Ziprecruiter",
    value: "ziprecruiter",
  },
];
