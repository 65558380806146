/*global chrome*/
import React from "react";
import ReactDOM from "react-dom/client";
import Home from "./components/Home";
import mixpanel from "mixpanel-browser";
import { initializePaddle } from "@paddle/paddle-js";
import axios from "axios";
// import ReactPixel from "react-facebook-pixel";
import { BrowserRouter as Router } from "react-router-dom";
import Hotjar from "@hotjar/browser";
mixpanel.init("3b19c76456515042e19c6955c0cb1b1b", {
  cookie_domain: ".lazyapply.com",
});

// const siteId = 3235235;
const siteId = 3235231;
const hotjarVersion = 6;
let paddle = null;
Hotjar.init(siteId, hotjarVersion);
if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
  console.info = () => {};
}
initializePaddle({
  token: "live_c7a32566b600f02bb57d8db9ca0",
  eventCallback: function (data) {
    console.log("data", data);
    if (data.name == "checkout.completed") {
      console.log("data", data);
      axios
        .post("https://backend.lazyapply.com/upgradeplanpaddle", {
          ...data,
        })
        .then((response) => {
          console.log("response", response);
          if (response.data && response.data.message == "success") {
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  },
}).then((paddleInstance) => {
  if (paddleInstance) {
    paddle = paddleInstance;
  }
});

// const options = {
//   autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
//   debug: false, // enable logs
// };
// ReactPixel.init("1360289084313479", options);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router>
    <Home />
  </Router>
);

export { mixpanel, Hotjar, paddle };
