import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesIndeed() {
  const [form, setForm] = useState({
    jobtype: "",
    experience: "",
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresIndeed() {
  const country_optionsIndeed = [
    { name: "Select country", code: "" },
    {
      name: "Argentina",
      code: "ar",
    },
    {
      name: "Australia",
      code: "au",
    },
    {
      name: "Austria",
      code: "at",
    },
    {
      name: "Bahrain",
      code: "bh",
    },
    {
      name: "Belgium",
      code: "be",
    },
    {
      name: "Brazil",
      code: "br",
    },
    {
      name: "Canada",
      code: "ca",
    },
    {
      name: "Chile",
      code: "cl",
    },
    {
      name: "China",
      code: "cn",
    },
    {
      name: "Colombia",
      code: "co",
    },
    {
      name: "Costa Rica",
      code: "cr",
    },
    {
      name: "Czech Republic",
      code: "cz",
    },
    {
      name: "Denmark",
      code: "dk",
    },
    {
      name: "Ecuador",
      code: "ec",
    },
    {
      name: "Egypt",
      code: "eg",
    },
    {
      name: "Finland",
      code: "fi",
    },
    {
      name: "France",
      code: "fr",
    },
    {
      name: "Germany",
      code: "de",
    },
    {
      name: "Greece",
      code: "gr",
    },
    {
      name: "Hong Kong",
      code: "hk",
    },
    {
      name: "Hungary",
      code: "hu",
    },
    {
      name: "India",
      code: "in",
    },
    {
      name: "Indonesia",
      code: "id",
    },
    {
      name: "Ireland",
      code: "ie",
    },
    {
      name: "Israel",
      code: "il",
    },
    {
      name: "Italy",
      code: "it",
    },
    {
      name: "Japan",
      code: "jp",
    },
    {
      name: "Kuwait",
      code: "kw",
    },
    {
      name: "Luxembourg",
      code: "lu",
    },
    {
      name: "Malaysia",
      code: "malaysia",
    },
    {
      name: "Mexico",
      code: "mx",
    },
    {
      name: "Morocco",
      code: "ma",
    },
    {
      name: "Netherlands",
      code: "nl",
    },
    {
      name: "New Zealand",
      code: "nz",
    },
    {
      name: "Nigeria",
      code: "ng",
    },
    {
      name: "Norway",
      code: "no",
    },
    {
      name: "Oman",
      code: "om",
    },
    {
      name: "Pakistan",
      code: "pk",
    },
    {
      name: "Panama",
      code: "pa",
    },
    {
      name: "Peru",
      code: "pe",
    },
    {
      name: "Philippines",
      code: "ph",
    },
    {
      name: "Poland",
      code: "pl",
    },
    {
      name: "Portugal",
      code: "pt",
    },
    {
      name: "Qatar",
      code: "qa",
    },
    {
      name: "Romania",
      code: "ro",
    },
    {
      name: "Russia",
      code: "ru",
    },
    {
      name: "Saudi Arabia",
      code: "sa",
    },
    {
      name: "Singapore",
      code: "sg",
    },
    {
      name: "South Africa",
      code: "za",
    },
    {
      name: "South Korea",
      code: "kr",
    },
    {
      name: "Spain",
      code: "es",
    },
    {
      name: "Sweden",
      code: "se",
    },
    {
      name: "Switzerland",
      code: "ch",
    },
    {
      name: "Taiwan",
      code: "tw",
    },
    {
      name: "Thailand",
      code: "th",
    },
    {
      name: "Turkey",
      code: "tr",
    },
    {
      name: "Ukraine",
      code: "ua",
    },
    {
      name: "United Arab Emirates",
      code: "ae",
    },
    {
      name: "United Kingdom",
      code: "uk",
    },
    {
      name: "United States",
      code: "www",
    },
    {
      name: "Uruguay",
      code: "uy",
    },
    {
      name: "Venezuela",
      code: "ve",
    },
    {
      name: "Vietnam",
      code: "vn",
    },
  ];

  let initialFormStateIndeed = {
    jobtype: "",
    experience: "",
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  };

  let filterRefsIndeed = 10;

  return { country_optionsIndeed, initialFormStateIndeed, filterRefsIndeed };
}

export function startAutomationIndeed(
  form,
  country,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  uuidv1
) {
  let startAutomationMessage = "indeedFetchFilters";

  if (country === "us") {
    country = "www";
  }

  let startAutomationUrl = `https://${country}.indeed.com/jobs?q=${encodeURIComponent(
    form.skill
  )}&l=${encodeURIComponent(
    form.location.replace(/[^a-zA-Z\s]/g, "")
  )}&iafilter=1`;

  console.log(startAutomationUrl, "start url");

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    limit: Math.min(form.numberOfJobs, 500),
    jobLinks: [],
    fetchFilters: 0,
    uniquesessionid: uuidv1(),
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: startAutomationUrl,
    indeed: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitIndeed(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  resumeId,
  formRef,
  resumeData,
  form1,
  urlMain,
  urlParts
) {
  let url = urlMain;

  console.log(urlMain, "urlMain");

  let finalSubmitSaveValues = {
    indeedSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    baseURL: url,
    indeedResumeId: resumeId,
    session: {
      platformName: "indeed",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    filters: form1,
    indeedV2FilterSet: 0,
    indeedSetFilters: urlParts,
  };

  let finalSubmitMessage = "indeedStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return {
    url,
    finalSubmitSaveValues,
    finalSubmitMessage,
    finalSubmitData,
  };
}

export function hIndeed(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  button1,
  setShowSavedSearchButton,
  filtersDiv,
  setheightMain,
  heightMain,
  chrome,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history
) {
  if (!("linkedin" in request)) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        console.log(request.link);
        sessionLinks.current.push(request.link);
        // count increment here
        //let countlinkedin = count;
        //countlinkedin++;
        // setCount(countlinkedin);
        ++count.current;
        console.log(count.current);
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );
        setpp(progressPercent.current);
        //setProgressPercent((count.current / form.numberOfJobs) * 100);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        console.log(request.filterValues);
        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";
          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of Indeed. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);
          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              // window.open("https://secure.indeed.com/auth", "_blank");
              window.location.reload();
            }
          }, 2500);
          resetFull();

          //reset here
        } else {
          filterValues.current = request.filterValues;
          console.log(request.filterValues);
          setformDisable(true);
          console.log("allfilters", request.filterValues);
          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";
          formMain1.current.style.display = "block";
          button1.current.style.display = "none";
          setShowSavedSearchButton(false);
          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);
          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                fetchFilters: 1,
              },
              indeed: true,
              message: "indeedFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing in your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      } else if (request.message2 === "planRequired") {
        setShowPlanPurchase(true);
        setIndeedRunning(0);
        button2.current.innerText = "Completed";
        processing.current.style.display = "none";
        smoothScrollToTop();
        initprocessingFinal.current.style.display = "flex";
        initprocessing.current.style.display = "none";
        if (selectResumeRef.current)
          selectResumeRef.current.style.display = "none";
        indeedReset();
      } else if (request.message === "completed") {
        //++count.current;
        // console.log(count.current);
        // setc(count.current);
        if (typeof chrome !== "undefined" && chrome && chrome.runtime)
          chrome.runtime.sendMessage(yourExtensionId, {
            dashboard: true,
            indeed: true,
            message: "resetUA",
          });
        setIndeedRunning(0);
        console.log("completed");
        if (selectResumeRef.current)
          selectResumeRef.current.style.display = "none";
        button2.current.innerText = "Completed";
        processing.current.style.display = "none";
        // setProgressPercent(100);
        progressPercent.current = 100;
        setpp(progressPercent.current);
        smoothScrollToTop();

        const v2Id = userData?.planDetails?.v2Id;
        axios
          .post(
            `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
            { sessionId: sessionId.current },
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then((response) => {
            console.log("first response", response.data);
            setFinalJobCount(response.data.jobCount || 0);
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
          })
          .catch((err) => {
            console.log("error in request", err);
          });

        if (!window.wasRun) {
          messages()[MESSAGES.ZEROJOBSAPPLIED](
            MESSAGETYPE.USER_REPORT_FRONTEND_INDEED,
            MESSAGES.TOTALJOBSAPPLIED,
            count.current,
            JSON.stringify(formRef.current),
            `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
            chromeVersion
          );
          saveSession(count.current, "indeed");
          window.wasRun = true;
        }
      } else if (request.message === "unauthorized") {
        fetchbutton.current.disabled = false;
        setIndeedRunning(0);
        console.log("reauthenticate");
        setmessage("Please relogin again!");
        settitle("Not authorized");
        setmodalshow(true);
        setTimeout(() => {
          globalLogout();
          history.push("/");
        }, 1500);
        resetFull();
      }
    }
  }
}

export function saveSessionIndeed(configDummy, count, sessionLinks, userData) {
  let platformJobsApplied = configDummy.jobsByPlatform["indeed"] + count;

  let indeedJobApplicationLinksFinal = [...sessionLinks.current];
  if ("indeedJobApplicationLinksFinal" in userData) {
    indeedJobApplicationLinksFinal = [
      ...userData.indeedJobApplicationLinksFinal,
      ...indeedJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    indeedJobApplicationLinksFinal: indeedJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function changeFiltersValuesIndeed(option, urlParts, key) {
  if (option) {
    urlParts[key] = option.label.replace(/\(.*?\)/, "");
    console.info("url-parts", urlParts);
  }
}

export function remoteFinalSubmitIndeed(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  resumeId,
  formRef,
  resumeData,
  form1,
  country,
  form
) {
  if (country === "us") {
    country = "www";
  }

  let url = `https://${country}.indeed.com/jobs?q=${encodeURIComponent(
    form.skill
  )}&l=${encodeURIComponent(
    form.location.replace(/[^a-zA-Z\s]/g, "")
  )}&iafilter=1`;

  let urlParts = {
    Remote: "Remote",
  };

  // console.log(urlMain, "urlMain");

  console.log(sessionId.current, "sessionId");

  let finalSubmitSaveValues = {
    indeedSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    baseURL: url,
    indeedResumeId: resumeId,
    session: {
      platformName: "indeed",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    filters: form1,
    indeedV2FilterSet: 0,
    indeedSetFilters: urlParts,
    limit: Math.min(form.numberOfJobs, 500),
    jobLinks: [],
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
    fetchFilters: 1,
  };

  let finalSubmitMessage = "indeedStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return {
    url,
    finalSubmitSaveValues,
    finalSubmitMessage,
    finalSubmitData,
  };
}
