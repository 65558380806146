import React, { useState, useEffect, useRef } from "react";
import { Modal, Form } from "react-bootstrap";
import axios from "axios";
import Select from "react-select";
import { Spinner } from "react-bootstrap";
import { useStateStore } from "../../store/index";
import { v1 as uuidv1 } from "uuid";
import FileUpload from "./FileUploader";
import { useHistory } from "react-router-dom";
import { mixpanel } from "../../index";

function CreateResumeModal({ showProp, hideShow }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
    setUsePrevious(false);
    setShowResumeSection(false);
    setSelectedResumeId(null);
    setAllChecked(false);
  };
  const [showSpinner, setShowSpinner] = useState(false);
  const userResumeV3 = useStateStore((state) => state.userResumeV3);
  const setUserResumeV2IndividualV3 = useStateStore(
    (state) => state.setUserResumeV2IndividualV3
  );
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const [value, setValue] = useState("");
  const token = useStateStore((state) => state.token);
  const [file, setFile] = useState(null);
  const [usePrevious, setUsePrevious] = useState(false);
  const [selectedResumeId, setSelectedResumeId] = useState(null);
  const [showResumeSection, setShowResumeSection] = useState(false);
  const resumesCompleted = useStateStore((state) => state.resumesCompletedV3);

  const [allChecked, setAllChecked] = useState(false);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => {
    if (resumesCompleted[selectedResumeId]?.resume) {
      setCheckboxStates(
        Object.keys(resumesCompleted[selectedResumeId].resume).reduce(
          (acc, key) => ({ ...acc, [key]: false }),
          {}
        )
      );
    }
  }, [selectedResumeId]);

  // Handle "Select All" checkbox change
  const handleAllChange = (event) => {
    setAllChecked(event.target.checked);
    setCheckboxStates(
      Object.keys(checkboxStates).reduce(
        (acc, key) => ({ ...acc, [key]: event.target.checked }),
        {}
      )
    );
    console.log("checked keyss beforeeee", checkedKeys);
    if (selectedResumeId && resumesCompleted[selectedResumeId]?.resume) {
      setCheckedKeys(Object.keys(resumesCompleted[selectedResumeId].resume));
    }
    // if (resumesCompleted[selectedResumeId]?.resume) {
    //   setCheckedKeys(Object.keys(resumesCompleted[selectedResumeId].resume));
    // }
    console.log("checked keyss nowww", checkedKeys);
  };

  // Handle individual checkbox change
  // const handleCheckboxChange = (key) => (event) => {
  //   setCheckboxStates({ ...checkboxStates, [key]: event.target.checked });

  //   if (!event.target.checked) {
  //     setAllChecked(false);
  //   }

  //   const checkedKeys = Object.keys(checkboxStates).filter(key => key !== "selectAll" && checkboxStates[key]);
  //   console.log('checked keyss nowww', checkedKeys);
  // };

  const handleCheckboxChange = (key) => (event) => {
    const updatedState = { ...checkboxStates, [key]: event.target.checked };
    setCheckboxStates(updatedState);

    if (!event.target.checked) {
      setAllChecked(false);
    }

    setCheckedKeys(
      Object.keys(updatedState).filter(
        (k) => k !== "selectAll" && updatedState[k]
      )
    );
    console.log("checked keyss nowww", checkedKeys);
  };

  const handleCheckboxToggle = () => {
    mixpanel.track(
      "use_information_checkbox upload_resume_popup edit_profile_page dashboard",
      {
        href: window.location.href,
        email: userData?.email,
        checkbox_event: !usePrevious,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    setUsePrevious(!usePrevious);
    setSelectedResumeId(null);
  };
  const handleResumeSectionToggle = () => {
    setShowResumeSection(!showResumeSection);
  };

  let buttonSave = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 20px",
    textAlign: "center",
    borderRadius: "10px",
    width: "100%",
    outline: "none",
    border: "none",
    marginTop: "50px",
  };

  let showr = {
    textAlign: "center",
    fontWeight: "bold",
    color: "black",
    fontSize: "16.5px",
    marginTop: "10px",
  };
  const [showMessage, setShowMessage] = useState("");

  const showCustomMessage = (message) => {
    setShowMessage(message);
    setTimeout(() => {
      setShowMessage("");
    }, 2500);
  };

  const createNewResume = () => {
    setDisable(true);
    const v2Id = userData?.planDetails?.v2Id;
    const newResumeId = uuidv1();
    if (value != "" && file) {
      const formData = new FormData();
      console.log("file");

      const filteredObject = checkedKeys.reduce((acc, key) => {
        if (
          selectedResumeId &&
          resumesCompleted[selectedResumeId].resume.hasOwnProperty(key)
        ) {
          acc[key] = resumesCompleted[selectedResumeId].resume[key];
        }
        // if (resumesCompleted[selectedResumeId].resume.hasOwnProperty(key)) {
        //   acc[key] = resumesCompleted[selectedResumeId].resume[key];
        // }
        return acc;
      }, {});

      console.log("filteredddd data", filteredObject);

      formData.append("resumename", value);
      formData.append("resumeId", newResumeId);
      formData.append("file", file);
      formData.append("sharedData", JSON.stringify(filteredObject));
      console.log("formData", formData);
      setShowSpinner(true);
      let url = `https://backend.lazyapply.com/v2/createResumeV3/${v2Id}`;
      if (
        userData?.planDetails &&
        userData?.planDetails?.planType &&
        userData?.planDetails?.planType == "enterprise"
      ) {
        url = `https://backend.lazyapply.com/v2/createResumeEnterpriseV3/${v2Id}`;
      }
      axios
        .post(url, formData, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          setShowSpinner(false);
          console.log(response, "response submitcode");
          if ("resumeJson" in response.data) {
            showCustomMessage("Successfully Created");
            setUserResumeV2IndividualV3({
              resumename: value,
              resumeId: newResumeId,
              createdBy: userData.email,
            });
            setDisable(false);
            handleClose();
            setTimeout(() => {
              history.push(`/dashboard/editresumesv2/${newResumeId}`);
            }, 1000);
          } else {
            if ("error" in response.data) {
              showCustomMessage(response.data.message);
              // setUserResumeV2IndividualV3({
              //   resumename: value,
              //   resumeId: newResumeId,
              //   createdBy: userData.email,
              // });
              setFile(null);
              setDisable(false);
              // handleClose();
              // setTimeout(() => {
              //   history.push(`/dashboard/editresumesv2/${newResumeId}`);
              // }, 3000);
            }
          }
        })
        .catch((error) => {
          setShowSpinner(false);
          setDisable(false);
          showCustomMessage("Some error occured, please try again!");
          console.log(error, "error submitcode");
        });
    } else {
      if (!file) {
        setDisable(false);
        showCustomMessage("No resume is uploaded!");
      } else {
        setDisable(false);
        showCustomMessage("Resume name cannot be empty!");
      }
    }
  };

  function submitCoupanCode(e) {
    e.preventDefault();
    const resumev2 = userResumeV3;

    mixpanel.track(
      "create_now_button upload_resume_popup edit_profile_page dashboard",
      {
        href: window.location.href,
        email: userData?.email,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    if (resumev2) {
      let present = false;
      for (let index = 0; index < resumev2.length; index++) {
        const resume = resumev2[index];
        if (resume.resumename === value) {
          present = true;
          break;
        }
      }
      if (present) {
        showCustomMessage("Resume with this name already present!");
      } else {
        createNewResume();
      }
    } else {
      createNewResume();
    }
  }

  const resetResumeFile = () => {
    mixpanel.track(
      "reset_resume_button upload_resume_popup edit_profile_page dashboard",
      {
        href: window.location.href,
        email: userData?.email,
        plan_details: userData?.planDetails || {},
        v2Id: userData?.planDetails?.v2Id || "",
      }
    );
    setFile(null);
  };

  function splitCamelCase(str) {
    return (
      str
        // insert a space before all capital letters
        .replace(/([A-Z])/g, " $1")
        // make the first character of the string uppercase
        .replace(/^./, function (str) {
          return str.toUpperCase();
        })
        // trim any leading/trailing white spaces
        .trim()
    );
  }

  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setShow(showProp);
    mixpanel.track("upload_resume_popup edit_profile_page dashboard", {
      href: window.location.href,
      email: userData?.email,
      popup_event: showProp,
      plan_details: userData?.planDetails || {},
      v2Id: userData?.planDetails?.v2Id || "",
    });
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal-width"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>Upload Resume/CV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ padding: "20px", maxWidth: "100%" }}>
          {/* <p style={{color:'darkblue'}}>We're currently experiencing a server issue for resume upload. We appreciate your patience, it will be resolved asap.</p> */}
          <Form onSubmit={submitCoupanCode}>
            <Form.Control
              required={true}
              type="text"
              id="resumename"
              value={value}
              placeholder="Enter resume name, example -> Aman_Resume"
              onChange={(e) => setValue(e.target.value)}
            />

            <div className="resumeTrackerUpload">
              <p style={{ marginTop: "20px" }}>
                Upload your Resume/ CV{" "}
                <span onClick={resetResumeFile} className="resetResume">
                  Reset Resume
                </span>
              </p>

              <FileUpload file={file} setFile={setFile}></FileUpload>
            </div>

            {Object.keys(resumesCompleted).length > 0 && (
              <div className="resumeTrackerUpload">
                <input
                  type="checkbox"
                  style={{ display: "inline-block", marginTop: "40px" }}
                  id="one23"
                  onChange={handleCheckboxToggle}
                />
                <label
                  style={{
                    display: "inline-block",
                    margin: "20px 0px 0px 10px",
                    fontWeight: 500,
                  }}
                  htmlFor="one23"
                >
                  Use information from previously created resume{" "}
                </label>
              </div>
            )}
            {usePrevious && (
              <div>
                {(() => {
                  const groupedKeys = [];
                  const resumesCompleted1 = userResumeV3.filter(
                    (x) => resumesCompleted[x.resumeId]
                  );

                  if (resumesCompleted1.length > 4) {
                    return (
                      <div>
                        <Select
                          id={"id2"}
                          onChange={(option) => {
                            if (option) {
                              console.log("selected option", option);
                              setSelectedResumeId(option.value);
                              handleResumeSectionToggle();
                            }
                          }}
                          key={"resume old"}
                          name={"resume old"}
                          placeholder={"Select A Resume"}
                          options={userResumeV3
                            .filter((x) => resumesCompleted[x.resumeId])
                            .map((resume) => ({
                              label: resume.resumename,
                              value: resume.resumeId,
                            }))}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </div>
                    );
                  }

                  for (let i = 0; i < resumesCompleted1.length; i += 2) {
                    groupedKeys.push(resumesCompleted1.slice(i, i + 2));
                  }
                  console.log("groupedddd", groupedKeys);
                  return groupedKeys.map((group, groupIndex) => (
                    <div className="row bufferDiv">
                      {group.map((data, index) => {
                        console.log("dataaaa", data);
                        return (
                          <>
                            <input
                              type="checkbox"
                              class="checkbox-input"
                              checked={selectedResumeId === data.resumeId}
                            />
                            <div
                              className="col-5 justify-content-center"
                              style={{
                                display: "flex",
                                flex: 1,
                                // alignItems: "stretch",
                                margin: "20px 20px 20px 20px",
                              }}
                              class="checkbox-group-parent"
                              onClick={(event) => {
                                setAllChecked(false);
                                if (
                                  selectedResumeId === data.resumeId ||
                                  !showResumeSection
                                ) {
                                  handleResumeSectionToggle();
                                  setSelectedResumeId(
                                    selectedResumeId === data.resumeId
                                      ? null
                                      : data.resumeId
                                  );
                                } else {
                                  setSelectedResumeId(data.resumeId);
                                }
                              }}
                            >
                              <fieldset class="checkbox-group">
                                <div class="checkbox">
                                  <label class="checkbox-wrapper">
                                    <span class="checkbox-tile">
                                      <span class="checkbox-icon">
                                        <div className="resumeblockimg">
                                          <img src={"/assests/resume4.svg"} />
                                        </div>
                                      </span>
                                      <span class="checkbox-label">
                                        {data.resumename}
                                      </span>
                                    </span>
                                  </label>
                                </div>
                              </fieldset>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ));
                })()}

                {showResumeSection && (
                  <div className="row bufferDiv">
                    <div
                      className="col-12"
                      style={{
                        marginTop: "20px",
                        marginBottom: "20px",
                        fontWeight: 500,
                      }}
                    >
                      Mark all information to retrieve from selected Resume{" "}
                    </div>
                    <div>
                      <div className="row bufferDiv">
                        <div className="col-6">
                          <input
                            type="checkbox"
                            id="selectAll"
                            checked={allChecked}
                            onChange={handleAllChange}
                          />
                          <label htmlFor="selectAll">Select All</label>
                          <br />
                        </div>
                      </div>

                      <div className="row bufferDiv">
                        {(() => {
                          const groupedKeys = [];
                          const resumeKeys =
                            selectedResumeId == null
                              ? []
                              : Object.keys(
                                  resumesCompleted[selectedResumeId].resume
                                );
                          // const resumeKeys = Object.keys(
                          //   resumesCompleted[selectedResumeId].resume
                          // );

                          for (let i = 0; i < resumeKeys.length; i += 2) {
                            groupedKeys.push(resumeKeys.slice(i, i + 2));
                          }
                          return groupedKeys.map((group, groupIndex) => (
                            <div className="col-6" key={groupIndex}>
                              {group.map((x, index) => {
                                const overallIndex = groupIndex * 2 + index;
                                return (
                                  <React.Fragment key={index}>
                                    <input
                                      type="checkbox"
                                      id={`section${overallIndex}`}
                                      checked={checkboxStates[x] || false}
                                      onChange={handleCheckboxChange(x)}
                                    />
                                    <label
                                      htmlFor={`section${overallIndex}`}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      {splitCamelCase(x)}
                                    </label>
                                    <br />
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          ));
                        })()}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {file && (
              <p style={{ color: "green" }}>
                Resume Uploaded Successfully! press create now button
              </p>
            )}

            <button style={buttonSave} disabled={disable}>
              Create Now{" "}
              {showSpinner && (
                <Spinner
                  animation="border"
                  size="sm"
                  style={{ marginLeft: "5px" }}
                />
              )}
            </button>
          </Form>
          <p style={showr}>{showMessage}</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CreateResumeModal;
