/*global chrome*/
import React, { useRef, useState, useEffect } from "react";
import "./sidebar.css";
import { useStateStore } from "../../store/index";
import { NavLink } from "react-router-dom";

function Sidebar({ height, userData }) {
  const sidebar = useRef(null);
  const isOpenHamburger = useStateStore((state) => state.isOpenHamburger);
  const setIsOpenHamburgerMain = useStateStore(
    (state) => state.setIsOpenHamburger
  );
  const [navbarWidth, setnavbarWidth] = useState(0);
  const heightMain = useStateStore((state) => state.heightMain);

  useEffect(() => {
    console.log("heightMain", heightMain);
    if (sidebar) {
      console.log("sidebar style");
      // sidebar.current.style.height = heightMain + "px";
    }
  }, [heightMain]);

  const showDebug = () => {
    return "debug" in userData && userData.debug;
  };

  const [show, setShow] = useState(false);
  const hideFn = () => {
    setShow(!show);
  };

  useEffect(() => {
    window.localStorage.setItem("debugSession", false);
  }, []);

  const setIsOpenHamburger = (value) => {
    if (navbarWidth >= 768) {
      setIsOpenHamburgerMain(true);
    } else {
      setIsOpenHamburgerMain(value);
    }
  };

  useEffect(() => {
    setnavbarWidth(window.innerWidth);
    if (window.innerWidth >= 768) {
      setIsOpenHamburgerMain(true);
    } else {
      setIsOpenHamburgerMain(false);
    }
  }, []);

  function getInitials(name) {
    let initials = "";
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 0) {
        initials += words[i][0].toUpperCase();
      }
    }

    return initials;
  }

  const [showExtraButtons, setShowExtraButtons] = useState(true);

  return (
    <>
      <div
        ref={sidebar}
        className={`sidebar ${isOpenHamburger ? "sidebar_open" : ""}`}
      >
        <i
          onClick={() => setIsOpenHamburger(false)}
          class="fa fa-times sidebar_closed"
          aria-hidden="true"
        ></i>
        <div className="sidebarTop">
          <div className="sidebarImageContainer">
            {/* <img src={imageSrc}></img> */}
            <div className="circularDiv">
              {getInitials(userData?.name || "")}
            </div>

            {/* <img src="../../obj-128X128.png"></img> */}
          </div>
          <div>
            <p className="welcomeback">Welcome Back</p>
            <p className="name">{userData?.name || ""}</p>
            <p className="email">{userData?.email || ""}</p>
          </div>
        </div>
        <div className="sidebarMiddle">
          <div className="sidebarUl">
            <NavLink to="/dashboard" activeClassName="active" exact={true}>
              <div className={"sidebarUlChild "}>
                <div className="sidebarUlChild0">
                  <i className="fas fa-home"></i>
                </div>
                <div> Home</div>
              </div>
            </NavLink>

            <NavLink to="/dashboard/referral" activeClassName="active">
              <div className={"sidebarUlChild "}>
                <div className="sidebarUlChild0">
                  <i className="fas fa-network-wired"></i>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Ask For Referral
                  {/* <img
                      style={{
                        width: "15px",
                        marginLeft: "20px",
                      }}
                      src="/assests/hot-icon.webp"
                    ></img> */}
                </div>
              </div>
            </NavLink>

            {showExtraButtons && (
              <div
                className={`extra-buttons-container ${
                  showExtraButtons ? "show" : ""
                }`}
              >
                <NavLink to="/editprofile" activeClassName="active">
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div>Edit Profile</div>
                  </div>
                </NavLink>
                <NavLink to="/dashboard/appliedjobs" activeClassName="active">
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-layer-group"></i>
                    </div>
                    <div> Analytics</div>
                  </div>
                </NavLink>

                <NavLink to={"/dashboard/userinvites"} activeClassName="active">
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-user"></i>
                    </div>
                    <div>User Invites</div>
                  </div>
                </NavLink>

                <a>
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div>AI Cover Letter</div>
                  </div>
                </a>
                <a>
                  <div className={"sidebarUlChild"}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div
                      className="targetedsearchnav"
                      style={{ fontWeight: "normal" }}
                    >
                      Resume Score
                    </div>
                  </div>
                </a>

                <NavLink
                  to="/dashboard/linkedin-send-email"
                  activeClassName="active"
                >
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="targetedsearchnav">Linkedin Email</div>
                  </div>
                </NavLink>

                <NavLink
                  to="/dashboard/queries"
                  activeClassName="active"
                  exact={true}
                >
                  <div className={"sidebarUlChild "}>
                    <div className="sidebarUlChild0">
                      <i className="fas fa-home"></i>
                    </div>
                    <div> Question Answer Report</div>
                  </div>
                </NavLink>
              </div>
            )}
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="sidebarFooterContent">
            <img src={"/assests/contact.png"} className="contact"></img>
            <p className="contactMain">
              Contact Us <i className="fas fa-address-book"></i>
            </p>
            <div className="contactUs">
              <p>info@lazyapply.com</p>
              <p>+91 8839209610</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
