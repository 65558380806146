/*global chrome*/
import create from "zustand";
import { devtools } from "zustand/middleware";
var yourExtensionId =
  process.env.NODE_ENV === "development"
    ? "ilelheadfopgpfnmdejngipjkafglndk"
    : process.env.REACT_APP_EXTENSION_ID;
const localStorageExtensionId = window.localStorage.getItem("extensionId");
if (localStorageExtensionId && localStorageExtensionId != "") {
  yourExtensionId = localStorageExtensionId;
}

window.localStorage.setItem("extensionId", yourExtensionId);

console.log("extensionid", yourExtensionId);
const store = (set, get) => ({
  mainLoadingState: 0,
  setMainLoadingState: (value) => {
    set({ mainLoadingState: value });
  },
  globalLogout: () => {
    console.log("global");
    localStorage.clear();
    const present = get().extensionPresent;
    if (present && typeof chrome !== "undefined" && chrome && chrome.runtime) {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          dashboard: true,
          saveValues: {
            token: "",
            user: {},
          },
          global: true,
          message: "loginToken",
        },
        () => {
          set((state) => ({
            token: "",
            isUserAuthenticated: 0,
            imageSrc: null,
            userData: {},
            configDummy: {
              indeedJobs: 0,
              linkedinJobs: 0,
              diceJobs: 0,
              ziprecruiterJobs: 0,
              totalJobs: 0,
              totalsession: [],
              todaysession: [],
              linkedinJobsApplied: 0,
              diceJobsApplied: 0,
              indeedJobsApplied: 0,
              ziprecruiterJobsApplied: 0,
              todaysessioncount: 0,
              plan: 0,
              dailyLimit: 0,
              monthlyLimit: 0,
              planName: "No plan",
              startDate: "",
              endDate: "",
              planStarted: 0,
            },
          }));
        }
      );
    }
  },
  extensionPresent: false,
  setExtensionPresent: (value) => {
    set((state) => ({
      extensionPresent: value,
    }));
  },
  yourExtensionId: yourExtensionId,
  countLinkedin: 0,
  numberOfJobs: 0,
  progressPercent: 15,
  isUserAuthenticated: 0,
  token: "",
  userData: {},
  InputPlatformName: "",
  setInputPlatformName: (value) => {
    set({ InputPlatformName: value });
  },
  voted: "",
  setVoted: (value) => {
    set({ voted: value });
  },
  configDummy: {
    indeedJobs: 0,
    linkedinJobs: 0,
    ziprecruiterJobs: 0,
    diceJobs: 0,
    totalJobs: 0,
    totalsession: [],
    todaysession: [],
    linkedinJobsApplied: 0,
    diceJobsApplied: 0,
    indeedJobsApplied: 0,
    ziprecruiterJobsApplied: 0,
    todaysessioncount: 0,
    plan: 0,
    dailyLimit: 0,
    monthlyLimit: 0,
    planName: "No plan",
    startDate: "",
    endDate: "",
    planStarted: 0,
    totalsessions: 0,
    totalsessionstoday: 0,
  },
  checkdate: 0,
  indeedRunning: 0,
  linkedinRunning: 0,
  chromeVersion: -1,
  availableChromeVersion: -1,
  timeZone: "",
  setTimeZone: (value) => {
    set({
      timeZone: value,
    });
  },
  setAvailableChromeVersion: (value) => {
    set({
      availableChromeVersion: value,
    });
  },
  setChromeVersion: (value) => {
    set({
      chromeVersion: value,
    });
  },
  streakValue: 0,
  setStreakValue: (value) => set({ streakValue: value }),
  diceRunning: 0,
  ziprecruiterRunning: 0,
  heightMain: 0,
  globalTabId: null,
  setGlobalTabId: (value) => set((state) => ({ globalTabId: value })),
  setheightMain: (value) => set((state) => ({ heightMain: value })),
  setDiceRunning: (value) => set((state) => ({ diceRunning: value })),
  setLinkedinRunning: (value) => set((state) => ({ linkedinRunning: value })),
  setZiprecruiterRunning: (value) =>
    set((state) => ({ ziprecruiterRunning: value })),
  setIndeedRunning: (value) => set((state) => ({ indeedRunning: value })),
  setcheckdate: (value) => set((state) => ({ checkdate: 1 })),
  userDataPlan: (value) =>
    set((state) => ({
      userData: {
        ...state.userData,
        planDetails: { ...state.userData.planDetails, planStarted: 0 },
      },
    })),
  userLinksUpdate: (value) =>
    set((state) => ({ userData: { ...state.userData, ...value } })),
  totalCountUpdate: (value) =>
    set((state) => ({ configDummy: { ...state.configDummy, ...value } })),
  configDummyUpdate: (value) => set((state) => ({ configDummy: value })),
  userDataInit: (value) => set((state) => ({ userData: value })),
  userDataResume: (value) => {
    set((state) => ({
      userData: {
        ...state.userData,
        resume: { ...state.userData.resume, ...value.resume },
        skills: value.skills,
        resumevalidation: value.resumevalidation,
      },
    }));
  },
  userDataResumeV2: (value) => {
    set((state) => ({
      userData: {
        ...state.userData,
        resumev2: [...state.userData?.resumev2, value],
      },
    }));
  },
  userResumeV2: [],
  setUserResumeV2: (value) => {
    set((state) => ({
      userResumeV2: value,
    }));
  },
  userResumeV3: [],
  setUserResumeV3: (value) => {
    set((state) => ({
      userResumeV3: value,
    }));
  },
  setUserResumeV2Individual: (value) => {
    const resumev2 = get().userResumeV2;
    set((state) => ({
      userResumeV2: [...resumev2, value],
    }));
  },
  setUserResumeV2IndividualV3: (value) => {
    const resumev2 = get().userResumeV3;
    set((state) => ({
      userResumeV3: [...resumev2, value],
    }));
  },
  deleteUserResumeV2: (id) => {
    const resumev2 = get().userResumeV2;
    let x = resumev2.filter((resumeObj) => {
      if (resumeObj.resumeId === id) {
        return false;
      } else {
        return true;
      }
    });
    set((state) => ({
      userResumeV2: x,
    }));
  },
  deleteUserResumeV3: (id) => {
    const resumev2 = get().userResumeV3;
    let x = resumev2.filter((resumeObj) => {
      if (resumeObj.resumeId === id) {
        return false;
      } else {
        return true;
      }
    });
    set((state) => ({
      userResumeV3: x,
    }));
  },
  updateUserResumeV2: (id, name) => {
    const resumev2 = get().userResumeV2;
    let x = resumev2.map((resumeObj) => {
      if (resumeObj.resumeId === id) {
        return {
          ...resumeObj,
          resumeId: id,
          resumename: name,
        };
      } else {
        return resumeObj;
      }
    });
    set((state) => ({
      userResumeV2: x,
    }));
  },
  uploadResumeUrlData: [],
  setUploadResumeUrlAll: (data) => {
    set({
      uploadResumeUrlData: data,
    });
  },
  setUploadResumeUrlData: (data) => {
    const mainDataArray = get().uploadResumeUrlData;
    set({
      uploadResumeUrlData: [...mainDataArray, data],
    });
  },
  updateUserResumeV3: (id, name) => {
    const resumev2 = get().userResumeV3;
    let x = resumev2.map((resumeObj) => {
      if (resumeObj.resumeId === id) {
        return {
          ...resumeObj,
          resumeId: id,
          resumename: name,
        };
      } else {
        return resumeObj;
      }
    });
    set((state) => ({
      userResumeV3: x,
    }));
  },
  userInvites: {},
  invites: {},
  setInvites: (value) => {
    set((state) => ({
      invites: { ...state.invites, ...value },
    }));
  },
  setUserInvites: (value) => {
    set((state) => ({
      userInvites: { ...state.userInvites, ...value },
    }));
  },
  resumeModifiedV2: 0,
  setResumeModifiedV2: (value) => {
    set(() => ({
      resumeModifiedV2: value,
    }));
  },
  resumeModifiedV3: 0,
  setResumeModifiedV3: (value) => {
    set(() => ({
      resumeModifiedV3: value,
    }));
  },
  userDataResumeUpdate: (value) => {
    const {
      race_ethnicity,
      disability,
      veteran,
      address,
      noticeperiod,
      coverletter,
      expectedsalary,
      website_blog_portfolio,
      message_to_the_hiring_manager,
      what_makes_you_unique,
      valid_driver_license,
      earliest_start_date,
    } = value.additionalResume;
    let newfields = {};
    if ("visa_status" in value.additionalResume) {
      newfields.visa_status = value.additionalResume.visa_status;
    }
    if ("phone_country_code" in value.additionalResume) {
      newfields.phone_country_code = value.additionalResume.phone_country_code;
    }
    if ("languages_you_know" in value.additionalResume) {
      newfields.languages_you_know = value.additionalResume.languages_you_know;
    }
    if ("linkedin_profile_url" in value.additionalResume) {
      newfields.linkedin_profile_url =
        value.additionalResume.linkedin_profile_url;
    }
    set((state) => ({
      userData: {
        ...state.userData,
        resume: {
          ...state.userData.resume,
          race_ethnicity,
          disability,
          veteran,
          address,
          coverletter,
          noticeperiod,
          ...newfields,
          ...{
            additionalInfo: {
              expectedsalary,
              website_blog_portfolio,
              message_to_the_hiring_manager,
              what_makes_you_unique,
              valid_driver_license,
              earliest_start_date,
            },
          },
        },
        additionalresumevalidation: value.additionalresumevalidation,
      },
    }));
  },
  scriptInjectedGlobal: false,
  setScriptInjectedGlobal: (value) =>
    set({
      scriptInjectedGlobal: value,
    }),
  sessionFetchLoadingState: 0,
  resumeFetchLoadingState: 0,
  setSessionFetchLoadingState: (value) =>
    set({
      sessionFetchLoadingState: value,
    }),
  setResumeFetchLoadingState: (value) =>
    set({
      resumeFetchLoadingState: value,
    }),
  resumefetch: 0,
  setresumefetch: (value) => set({ resumefetch: value }),
  resumesCompleted: {},
  setResumesComplete: (value) => set((state) => ({ resumesCompleted: value })),
  resumesCompletedV3: {},
  setResumesCompleteV3: (value) =>
    set((state) => ({ resumesCompletedV3: value })),
  updateToken: (value) => set((state) => ({ token: value })),
  imageSrc: null,
  userAuthentication: (value) =>
    set((state) => ({ isUserAuthenticated: value })),
  userImageSrc: (value) => set((state) => ({ imageSrc: value })),
  heightChange: 0,
  lazyapplyXData: {
    planStarted: 0,
    stepNo: -1,
    nextPage: 1,
  },
  showFeedbackPopup: false,
  setShowFeedbackPopup: (value) => {
    set((state) => ({
      showFeedbackPopup: value,
    }));
  },
  lazyapplyXCompaniesData: {},
  setLazyapplyXCompaniesData: (value) =>
    set((state) => ({
      lazyapplyXCompaniesData: value,
    })),
  setLazyapplyXData: (value) =>
    set((state) => ({
      lazyapplyXData: value,
    })),
  generatedSampleEmail: "",
  setGeneratedSampleEmail: (value) =>
    set((state) => ({
      generatedSampleEmail: value,
    })),
  lazyapplyXSessions: [],
  setLazyapplyXSessions: (sessions) =>
    set((state) => ({
      lazyapplyXSessions: sessions,
    })),
  lazyapplyXAnalytics: {
    companiesCount: 0,
    totalsessionslength: 0,
  },
  setLazyapplyXAnalytics: (value) => {
    set((state) => ({
      lazyapplyXAnalytics: value,
    }));
  },
  setHeightChange: () =>
    set((state) => ({
      heightChange: !state.heightChange,
    })),
  settingsClick: false,
  setSettingsClick: (value) => {
    set({
      settingsClick: value,
    });
  },
  disableNextPage: false,
  setDisableNextPage: (value) => {
    set({
      disableNextPage: value,
    });
  },
  searchingJobsWithFilters: false,
  setSearchingJobsWithFilters: (value) => {
    set({
      searchingJobsWithFilters: value,
    });
  },
  paginationActivePageNumber: 1,
  setPaginationActivePageNumber: (value) => {
    set({
      paginationActivePageNumber: value,
    });
  },

  searchJobTitle: "",
  setSearchJobTitle: (value) => {
    set({
      searchJobTitle: value,
    });
  },
  searchCompanyName: "",
  setSearchCompanyName: (value) => {
    set({
      searchCompanyName: value,
    });
  },
  paginationDirection: 1,
  setPaginationDirection: (value) => {
    set({
      paginationDirection: value,
    });
  },
  sessionIdAnalytics: "",
  setSessionIdAnalytics: (value) => {
    set({
      sessionIdAnalytics: value,
    });
  },
  emailAutomationLastSession: {},
  setEmailAutomationLastSession: (value) => {
    set({
      emailAutomationLastSession: value,
    });
  },
  mainModalTitle: "",
  setMainModalTitle: (value) =>
    set({
      mainModalTitle: value,
    }),
  mainCompanySet: {},
  setMainCompanySet: (value) =>
    set({
      mainCompanySet: value,
    }),
  referralInitData: {},
  setReferralInitData: (value) => {
    set({ referralInitData: value });
  },
  profileSelectedDuringAutomation: false,
  setProfileSelectedDuringAutomation: (value) =>
    set({
      profileSelectedDuringAutomation: value,
    }),
  setReferralDataOtherDetails: (value) => {
    let referral_data = get().referralInitData;
    if (Object.keys(referral_data.referral).length > 0) {
      referral_data.referral.referralTitle = value.referralTitle;
      referral_data.referral.resumeId = value.selectedResumeId;
    } else {
      referral_data.referral = {
        referralTitle: value.referralTitle,
        resumeId: value.selectedResumeId,
      };
    }
    set({ referralInitData: referral_data });
  },
  sendEmail: false,
  setSendEmail: (value) => {
    set({ sendEmail: value });
  },
  installExtensionDuringAutomation: false,
  setInstallExtensionDuringAutomation: (value) =>
    set({ installExtensionDuringAutomation: value }),
  showEmailPermissionModal: false,
  setShowEmailPermissionModal: (value) =>
    set({ showEmailPermissionModal: value }),
  emailList: {},
  setEmailList: (value) => {
    const linkedin = value.linkedin;
    if (linkedin) {
      const emailListMain = get().emailList;
      emailListMain[linkedin] = value.email;
      set({ emailList: emailListMain });
    }
  },
  buttonClick: false,
  setButtonClick: (value) =>
    set({
      buttonClick: value,
    }),
  pauseToChooseCustomTemplate: false,
  setPauseToChooseCustomTemplate: (value) =>
    set({ pauseToChooseCustomTemplate: value }),
  customTemplates: [],
  setCustomTemplates: (value) => set({ customTemplates: value }),
  employeeList: {},
  setEmployeeList: (value) => {
    const domain = value.domain;
    if (domain) {
      const employee_list = get().employeeList;
      employee_list[domain] = value.employees;
      set({ employeeList: employee_list });
    }
  },
  initialString: "",
  setInitialString: (value) => set({ initialString: value }),
  saveButtonClickAndSuccess: false,
  setSaveButtonClickAndSuccess: (value) =>
    set({ saveButtonClickAndSuccess: value }),
  showEmailSettings: false,
  setShowEmailSettings: (value) => set({ showEmailSettings: value }),
  editEmailSettings: false,
  setEditEmailSettings: (value) => set({ editEmailSettings: value }),
  referralResumeData: {},
  setReferralResumeData: (value) =>
    set({
      referralResumeData: value,
    }),
  employeeSelected: {},
  setEmployeeSelected: (value) =>
    set({
      employeeSelected: value,
    }),
  showEmailSentSuccess: false,
  setShowEmailSentSuccess: (value) =>
    set({
      showEmailSentSuccess: value,
    }),
  mainReferralPause: {
    status: false,
    text: "",
  },
  setMainReferralPause: (value) =>
    set({
      mainReferralPause: value,
    }),
  emailData: {},
  setEmailData: (value) => set({ emailData: value }),
  employees: [],
  setEmployees: (value) => set({ employees: value }),
  showMainReferralModal: false,
  setShowMainReferralModal: (value) =>
    set({
      showMainReferralModal: value,
    }),
  showReferralFeedback: false,
  setShowReferralFeedback: (value) => set({ showReferralFeedback: value }),
  stepNoReferralFeedback: 0,
  setStepNoReferralFeedback: (value) =>
    set({
      stepNoReferralFeedback: value,
    }),
  isOpenHamburger: true,
  setIsOpenHamburger: (value) =>
    set({
      isOpenHamburger: value,
    }),
  showDemo: false,
  setShowDemo: (value) => set({ showDemo: value }),
  selectedPath: "Home",
  setSelectedPath: (value) => set({ selectedPath: value }),
  promptSelected: {
    form: {
      id: -1,
    },
    prompt: {
      id: -1,
    },
  },
  employeeQuery: "",
  setEmployeeQuery: (value) => set({ employeeQuery: value }),
  stopClick: false,
  setStopClick: (value) => set({ stopClick: value }),
  editEmail: false,
  setEditEmail: (value) =>
    set({
      editEmail: value,
    }),
  emailDataString: "",
  setEmailDataString: (value) => set({ emailDataString: value }),
  setPromptSelected: (value) => set({ promptSelected: value }),
  emailAutomationReferral: false,
  setEmailAutomationReferral: (value) =>
    set({
      emailAutomationReferral: value,
    }),
  googleToken: "",
  updateGoogleToken: (value) => set({ googleToken: value }),
});

export const useStateStore = create(devtools(store));
