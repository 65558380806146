/*global chrome*/
import { useStateStore } from "../../store/index";
const excludeFromBasicPlan = [
  "ziprecruiter",
  "glassdoor",
  "dice",
  "careerBuilder",
  "monster",
  "seek",
  "foundit",
  "simplyHired",
];

export async function GrantAccess(pathname) {
  let access = 0;
  let resultObj = {};
  const {
    userResumeV3,
    userData,
    configDummy,
    yourExtensionId,
    scriptInjectedGlobal,
    setScriptInjectedGlobal,
    setExtensionPresent,
    chromeVersion,
    availableChromeVersion,
  } = useStateStore.getState();

  console.log("versions", chromeVersion, availableChromeVersion);

  let extensionPresentMain = 0;

  if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
    console.log("check1");
    extensionPresentMain = await new Promise((resolve, reject) => {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          lazyapply: "lazyapply",
          message: "check",
          injected: scriptInjectedGlobal,
        },
        function (response) {
          // Do whatever you want, background script is ready now
          var lastError = chrome.runtime.lastError;
          if (lastError) {
            console.log("error", lastError.message);

            // 'Could not establish connection. Receiving end does not exist.'
            resolve(0);
          }
          console.log("check0", response);
          if ("status" in response && response.status === "installed") {
            setScriptInjectedGlobal(true);
            setExtensionPresent(true);
            console.log("check2");

            resolve(1);
          }
        }
      );
    });
    console.log("extensionpresent", extensionPresentMain);
  } else {
    extensionPresentMain = 0;
  }

  const isAnyResumeCompleted = () => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) {
      return (
        userResumeV3.filter((resume) => resume.validation === 1).length > 0
      );
    } else {
      return (
        "additionalresumevalidation" in userData &&
        userData.additionalresumevalidation &&
        "resumevalidation" in userData &&
        userData.resumevalidation
      );
    }
  };

  const resumeValidationCheck = () => {
    const v2Id = userData?.planDetails?.v2Id;
    console.log(v2Id, "v2Id");
    if (v2Id) {
      return !(
        userResumeV3.filter((resume) => resume.validation === 1).length > 0
      );
    } else {
      return !("resumevalidation" in userData && userData.resumevalidation);
    }
  };

  const additionalValidationCheck = () => {
    const v2Id = userData?.planDetails?.v2Id;
    if (v2Id) {
      return !(
        userResumeV3.filter((resume) => resume.validation === 1).length > 0
      );
    } else {
      return !(
        "additionalresumevalidation" in userData &&
        userData.additionalresumevalidation
      );
    }
  };

  const checkIfNotChromiumBased = () => {
    // Detect if the code is running in a Chrome-based browser
    const isChromeBasedBrowser = typeof chrome !== "undefined";

    return !isChromeBasedBrowser;
  };

  const newEmailAutomationPlatforms = ["emailAutomation"];

  const mainCondition = () => {
    if (newEmailAutomationPlatforms.includes(pathname)) {
      return true;
    } else {
      return (
        chromeVersion === availableChromeVersion &&
        chromeVersion != -1 &&
        availableChromeVersion != -1 &&
        extensionPresentMain
      );
    }
  };

  if (
    mainCondition() &&
    isAnyResumeCompleted() &&
    "planDetails" in userData &&
    userData.planDetails.planStarted == 1
  ) {
    if (
      excludeFromBasicPlan.includes(pathname) &&
      userData.planDetails.planName == "basic"
    ) {
      access = 0;
    } else {
      access = 1;
    }
  } else {
    access = 0;
    if (!("planDetails" in userData && userData.planDetails.planStarted == 1)) {
      resultObj = {
        message: "Purchase a plan in order to continue!",
        title: "No active plan!",
        gotolink: "/pricing",
      };
    } else if (checkIfNotChromiumBased()) {
      resultObj = {
        message: "Our extension only supports chrome browser for now :)",
        title: "Please use chrome browser",
        gotolink: "/dashboard",
      };
    } else if (configDummy.dailyLimit - configDummy.todaysessioncount == 0) {
      resultObj = {
        message: "You have reached your daily limit, come back tomorrow :)",
        title: "Daily limit reached!",
        gotolink: "/dashboard",
      };
    } else if (resumeValidationCheck()) {
      const v2Id = userData?.planDetails?.v2Id;
      if (v2Id) {
        resultObj = {
          message: "Please complete any one resume/cv in order to continue!",
          title: "Resume not completed!",
          gotolink: "/dashboard/editresumesv2",
        };
      } else {
        resultObj = {
          message: "Please complete your profile/resume in order to continue!",
          title: "Resume not completed!",
          gotolink: "/dashboard/editresume",
        };
      }
    } else if (additionalValidationCheck()) {
      const v2Id = userData?.planDetails?.v2Id;
      if (v2Id) {
        resultObj = {
          message: "Please complete any one resume/cv in order to continue!",
          title: "Resume not completed",
          gotolink: "/dashboard/editresumesv2",
        };
      } else {
        resultObj = {
          message:
            "Please complete your addtional info section in order to continue!",
          title: "Addtional info not completed!",
          gotolink: "/dashboard/editresume/additionalInfo",
        };
      }
    } else if (!extensionPresentMain) {
      resultObj = {
        extensionNotPresent: 1,
        message: "Install the extension in order to start applying.",
        title: "Please install the extension first!",
        gotolinknew:
          "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
      };
    } else if (
      chromeVersion != availableChromeVersion &&
      chromeVersion != -1 &&
      availableChromeVersion != -1
    ) {
      resultObj = {
        updateExtension: 1,
        message:
          "Update the extension in order to start applying. ( remove the extension and install again )",
        title: "Please update the extension first!",
        gotolinknew:
          "https://chrome.google.com/webstore/detail/lazyapply-job-application/pgnfaifdbfoiehcndkoeemaifhhbgkmm",
      };
    } else {
      resultObj = {
        message:
          "No access, Try reloading this page or reinstall the extension to newest version!",
        title: "Error, No Access!",
      };
    }
  }
  return { access, resultObj };
}
