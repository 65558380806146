import React from "react";
import { Modal, Button, Form } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles

const TemplateModal = ({
  showModal,
  handleCloseModal,
  modalMode,
  currentTemplate,
  handleInputChange,
  handleDescriptionChange,
  handleSaveTemplate,
}) => {
  return (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      dialogClassName="modal-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {modalMode === "create"
            ? "Create Custom Template"
            : "Update Custom Template"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Template Name</Form.Label>
            <Form.Control
              type="text"
              name="template_name"
              value={currentTemplate.template_name}
              onChange={handleInputChange}
              placeholder="Enter template name"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Title</Form.Label>
            <Form.Control
              type="text"
              name="email_title"
              value={currentTemplate.email_title}
              onChange={handleInputChange}
              placeholder="Enter title"
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email Description</Form.Label>
            {/* Using react-quill editor for email description */}
            <ReactQuill
              value={currentTemplate.email_description}
              onChange={handleDescriptionChange}
              placeholder="Enter description with formatting"
              modules={{
                toolbar: [
                  [{ header: "1" }, { header: "2" }, { font: [] }],
                  [{ size: [] }],
                  ["bold", "italic", "underline", "strike"],
                  [{ list: "ordered" }, { list: "bullet" }],
                  ["link"],
                  ["clean"],
                ],
              }}
              formats={[
                "header",
                "font",
                // "size",
                "bold",
                "italic",
                "underline",
                "strike",
                "list",
                "bullet",
                "link",
              ]}
              style={{ height: "300px" }} // Set the height for the editor
            />
            <Form.Text className="text-muted">
              Use placeholders like{" "}
              <span className="placeholder">{"{{company_name}}"}</span>,
              <span className="placeholder">{"{{job_role}}"}</span>, and
              <span className="placeholder">{"{{hiring_person_name}}"}</span>.
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseModal}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveTemplate}>
          {modalMode === "create" ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TemplateModal;
