/*global chrome*/
import React, { useRef, useState, useEffect } from "react";
import "./sidebar.css";
import { useStateStore } from "../../store/index";
import { NavLink, useHistory } from "react-router-dom";
import CustomAlert from "../modal";
import FeatureRequest from "../featureRequest";
import { mixpanel } from "../../index";
// import contactImg from "../../contact.png";

function Sidebar({ height, userData }) {
  const imageSrc = useStateStore((state) => state.imageSrc);
  const sidebar = useRef(null);
  const setSelectedPath = useStateStore((state) => state.setSelectedPath);
  const isOpenHamburger = useStateStore((state) => state.isOpenHamburger);
  const setIsOpenHamburgerMain = useStateStore(
    (state) => state.setIsOpenHamburger
  );
  const [navbarWidth, setnavbarWidth] = useState(0);
  const history = useHistory();
  const streakAnalytics = useStateStore((state) => state.streakAnalytics);
  const [selectedLi, setSelectedLi] = useState(0);
  const linkedinRunning = useStateStore((state) => state.linkedinRunning);
  const diceRunning = useStateStore((state) => state.diceRunning);
  const ziprecruiterRunning = useStateStore(
    (state) => state.ziprecruiterRunning
  );
  const indeedRunning = useStateStore((state) => state.indeedRunning);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const heightMain = useStateStore((state) => state.heightMain);
  function changeSelectedLi(value) {
    setSelectedLi(value);
  }

  useEffect(() => {
    console.log("heightMain", heightMain);
    if (sidebar) {
      console.log("sidebar style");
      // sidebar.current.style.height = heightMain + "px";
    }
  }, [heightMain]);

  function checkPath(e, pathName) {
    e.preventDefault();
    console.log(e, pathName);
    console.log(linkedinRunning, indeedRunning);
    if (
      linkedinRunning === 1 ||
      indeedRunning === 1 ||
      ziprecruiterRunning == 1 ||
      diceRunning == 1
    ) {
      setmessage(
        "Automation is currently in progress, please press the reset button or wait for the automation to complete. :)"
      );
      settitle("Process Ongoing");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 2500);
    } else {
      history.push(pathName);
    }
  }

  const showDebug = () => {
    return "debug" in userData && userData.debug;
  };

  const [show, setShow] = useState(false);
  const hideFn = () => {
    setShow(!show);
  };

  useEffect(() => {
    window.localStorage.setItem("debugSession", false);
  }, []);

  const handleDebug = () => {
    if (showDebug()) {
      window.localStorage.setItem("debugSession", true);
      alert("success debug started");
    } else {
      window.localStorage.setItem("debugSession", false);
      alert("success debug completed");
    }
  };

  const setIsOpenHamburger = (value) => {
    if (navbarWidth >= 768) {
      setIsOpenHamburgerMain(true);
    } else {
      setIsOpenHamburgerMain(value);
    }
  };

  useEffect(() => {
    setnavbarWidth(window.innerWidth);
    if (window.innerWidth >= 768) {
      setIsOpenHamburgerMain(true);
    } else {
      setIsOpenHamburgerMain(false);
    }
  }, []);

  function getInitials(name) {
    let initials = "";
    const words = name.split(" ");

    for (let i = 0; i < words.length; i++) {
      if (words[i].length > 0) {
        initials += words[i][0].toUpperCase();
      }
    }

    return initials;
  }

  const [showExtraButtons, setShowExtraButtons] = useState(true);

  const toggleButtons = () => {
    setShowExtraButtons(!showExtraButtons);
  };

  return (
    <>
      <FeatureRequest s={show} hideFn={hideFn}></FeatureRequest>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      <div
        ref={sidebar}
        className={`sidebar ${isOpenHamburger ? "sidebar_open" : ""}`}
      >
        <i
          onClick={() => setIsOpenHamburger(false)}
          class="fa fa-times sidebar_closed"
          aria-hidden="true"
        ></i>
        <div className="sidebarTop">
          <div className="sidebarImageContainer">
            {/* <img src={imageSrc}></img> */}
            <div className="circularDiv">
              {getInitials(userData?.name || "")}
            </div>

            {/* <img src="../../obj-128X128.png"></img> */}
          </div>
          <div>
            <p className="welcomeback">Welcome Back</p>
            <p className="name">{userData.name}</p>
            <p className="email">{userData.email}</p>
          </div>
        </div>
        <div className="sidebarMiddle">
          <div className="sidebarUl">
            <NavLink
              to="/dashboard"
              activeClassName="active"
              exact={true}
              onClick={(e) => checkPath(e, "/dashboard")}
            >
              <div
                className={"sidebarUlChild "}
                onClick={() => {
                  setIsOpenHamburger(false);
                  setSelectedPath("Home");
                  changeSelectedLi(0);
                  mixpanel.identify(userData?.email);
                  mixpanel.people.increment("home_button sidebar dashboard");
                  mixpanel.track("home_button sidebar dashboard", {
                    href: window.location.href,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  });
                }}
              >
                <div className="sidebarUlChild0">
                  <i className="fas fa-home"></i>
                </div>
                <div> Home</div>
              </div>
            </NavLink>
            {/* <NavLink
              to="/dashboard/main"
              activeClassName="active"
              exact={true}
              onClick={(e) => checkPath(e, "/dashboard/main")}
            >
              <div
                className={"sidebarUlChild "}
                onClick={() => {
                  changeSelectedLi(0);
                  mixpanel.identify(userData?.email);
                  mixpanel.people.increment(
                    "email_automation_button sidebar dashboard"
                  );
                  mixpanel.track("email_automation_button sidebar dashboard", {
                    href: window.location.href,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  });
                }}
              >
                <div className="sidebarUlChild0">
                  <i className="fas fa-home"></i>
                </div>
                <div>Email Automation</div>
              </div>
            </NavLink> */}

            {!(
              userData?.planDetails &&
              userData?.planDetails?.planType &&
              userData?.planDetails?.membertype &&
              userData?.planDetails?.planType == "enterprise"
            ) && (
              <NavLink
                to="/dashboard/referral"
                activeClassName="active"
                onClick={(e) => checkPath(e, "/dashboard/referral")}
              >
                <div
                  className={"sidebarUlChild "}
                  onClick={() => {
                    // changeSelectedLi(2);
                    setIsOpenHamburger(false);
                    setSelectedPath("Referral");
                    mixpanel.identify(userData?.email);
                    mixpanel.people.increment(
                      "secret_network_button sidebar dashboard"
                    );
                    mixpanel.track("secret_network_button sidebar dashboard", {
                      href: window.location.href,
                      email: userData?.email,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    });
                  }}
                >
                  <div className="sidebarUlChild0">
                    <i className="fas fa-network-wired"></i>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Ask For Referral
                    {/* <img
                      style={{
                        width: "15px",
                        marginLeft: "20px",
                      }}
                      src="/assests/hot-icon.webp"
                    ></img> */}
                  </div>
                </div>
              </NavLink>
            )}

            {/* <a activeClassName="active">
              <div
                className={"sidebarUlChild"}
                onClick={() => {
                  changeSelectedLi(1);
                  mixpanel.identify(userData?.email);
                  mixpanel.people.increment(
                    "more_options_button sidebar dashboard"
                  );
                  mixpanel.track("more_options_button sidebar dashboard", {
                    href: window.location.href,
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  });
                  toggleButtons();
                }}
              >
                <div className="sidebarUlChild0">
                  <i class="fa fa-list-alt" aria-hidden="true"></i>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  More Options{" "}
                  {!showExtraButtons ? (
                    <i class="fas fa-caret-down ml-2"></i>
                  ) : (
                    <>
                      <i class="fas fa-caret-up ml-2"></i>
                    </>
                  )}{" "}
                </div>
              </div>
            </a> */}
            {showExtraButtons && (
              <div
                className={`extra-buttons-container ${
                  showExtraButtons ? "show" : ""
                }`}
              >
                <NavLink
                  to={
                    userData?.planDetails?.v2Id
                      ? "/dashboard/editresumesv2"
                      : "/dashboard/editresume"
                  }
                  activeClassName="active"
                  onClick={(e) => {
                    const path = userData?.planDetails?.v2Id
                      ? "/dashboard/editresumesv2"
                      : "/dashboard/editresume";
                    checkPath(e, path);
                  }}
                >
                  <div
                    className={"sidebarUlChild "}
                    onClick={() => {
                      setIsOpenHamburger(false);
                      setSelectedPath("Edit Profile");
                      changeSelectedLi(1);
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment(
                        "edit_profile_button sidebar dashboard"
                      );
                      mixpanel.track("edit_profile_button sidebar dashboard", {
                        href: window.location.href,
                        email: userData?.email,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                    }}
                  >
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div>Edit Profile</div>
                  </div>
                </NavLink>
                {/* <NavLink
                  to="/dashboard/customtemplate"
                  activeClassName="active"
                  onClick={(e) => checkPath(e, "/dashboard/customtemplate")}
                >
                  <div
                    className={"sidebarUlChild "}
                    onClick={() => {
                      setIsOpenHamburger(false);
                      setSelectedPath("CustomTemplates");
                      changeSelectedLi(3);
                    }}
                  >
                    <div className="sidebarUlChild0">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div> Custom Templates</div>
                  </div>
                </NavLink> */}
                <NavLink
                  to="/dashboard/appliedjobs"
                  activeClassName="active"
                  onClick={(e) => checkPath(e, "/dashboard/appliedjobs")}
                >
                  <div
                    className={"sidebarUlChild "}
                    onClick={() => {
                      setIsOpenHamburger(false);
                      setSelectedPath("Analytics");
                      changeSelectedLi(2);
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment(
                        "analytics_button sidebar dashboard"
                      );
                      mixpanel.track("analytics_button sidebar dashboard", {
                        href: window.location.href,
                        email: userData?.email,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                    }}
                  >
                    <div className="sidebarUlChild0">
                      <i className="fas fa-layer-group"></i>
                    </div>
                    <div> Analytics</div>
                  </div>
                </NavLink>
                {userData?.planDetails?.v2Id &&
                  userData?.planDetails?.planType &&
                  userData?.planDetails?.membertype &&
                  userData?.planDetails?.planType == "enterprise" &&
                  userData?.planDetails?.membertype === "admin" && (
                    <NavLink
                      to={"/dashboard/userinvites"}
                      activeClassName="active"
                      onClick={(e) => {
                        const path = "/dashboard/userinvites";
                        checkPath(e, path);
                      }}
                    >
                      <div
                        className={"sidebarUlChild "}
                        onClick={() => {
                          changeSelectedLi(1);
                          mixpanel.identify(userData?.email);
                          mixpanel.people.increment(
                            "user_invites_button sidebar dashboard"
                          );
                          mixpanel.track(
                            "user_invites_button sidebar dashboard",
                            {
                              href: window.location.href,
                              email: userData?.email,
                              plan_details: userData?.planDetails || {},
                              v2Id: userData?.planDetails?.v2Id || "",
                            }
                          );
                        }}
                      >
                        <div className="sidebarUlChild0">
                          <i className="fas fa-user"></i>
                        </div>
                        <div>User Invites</div>
                      </div>
                    </NavLink>
                  )}
                <a>
                  <div
                    className={"sidebarUlChild "}
                    onClick={() => {
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment(
                        "ai_cover_letter_button sidebar dashboard"
                      );
                      mixpanel.track(
                        "ai_cover_letter_button sidebar dashboard",
                        {
                          href: window.location.href,
                          email: userData?.email,
                          plan_details: userData?.planDetails || {},
                          v2Id: userData?.planDetails?.v2Id || "",
                        }
                      );
                      window.open(
                        "https://lazyapply.com/#cover-letter-generator",
                        "_blank"
                      );
                    }}
                  >
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div>AI Cover Letter</div>
                  </div>
                </a>
                <a>
                  <div
                    className={"sidebarUlChild"}
                    onClick={() => {
                      mixpanel.identify(userData?.email);
                      mixpanel.people.increment(
                        "resume_score_button sidebar dashboard"
                      );
                      mixpanel.track("resume_score_button sidebar dashboard", {
                        href: window.location.href,
                        email: userData?.email,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      });
                      window.open(
                        "https://lazyapply.com/resume-score",
                        "_blank"
                      );
                    }}
                  >
                    <div className="sidebarUlChild0">
                      <i className="fas fa-file"></i>
                    </div>
                    <div
                      className="targetedsearchnav"
                      style={{ fontWeight: "normal" }}
                    >
                      Resume Score
                      {/* <span className="getemailsnew" style={{ marginLeft: "10px" }}>
                    LATEST
                  </span> */}
                    </div>
                  </div>
                </a>
                {((userData?.planDetails?.v2Id &&
                  userData?.planDetails?.planType &&
                  userData?.planDetails?.planType != "enterprise") ||
                  userData?.planDetails) && (
                  <NavLink
                    to="/dashboard/linkedin-send-email"
                    activeClassName="active"
                    onClick={(e) =>
                      checkPath(e, "/dashboard/linkedin-send-email")
                    }
                  >
                    <div
                      className={"sidebarUlChild "}
                      onClick={() => {
                        setIsOpenHamburger(false);
                        setSelectedPath("Linkedin Email");
                        changeSelectedLi(3);
                        mixpanel.identify(userData?.email);
                        mixpanel.people.increment(
                          "linkedin_email_button sidebar dashboard"
                        );
                        mixpanel.track(
                          "linkedin_email_button sidebar dashboard",
                          {
                            href: window.location.href,
                            email: userData?.email,
                            plan_details: userData?.planDetails || {},
                            v2Id: userData?.planDetails?.v2Id || "",
                          }
                        );
                      }}
                    >
                      <div className="sidebarUlChild0">
                        <i className="fas fa-envelope"></i>
                      </div>
                      <div className="targetedsearchnav">
                        Linkedin Email
                        {/* <span
                      className="getemailsnew"
                      style={{ marginLeft: "10px" }}
                    >
                      NEW
                    </span> */}
                      </div>
                    </div>
                  </NavLink>
                )}
                {"planDetails" in userData &&
                  userData.planDetails.planStarted == 1 && (
                    <NavLink
                      to="/dashboard/queries"
                      activeClassName="active"
                      exact={true}
                      onClick={(e) => checkPath(e, "/dashboard/queries")}
                    >
                      <div
                        className={"sidebarUlChild "}
                        onClick={() => {
                          setIsOpenHamburger(false);
                          setSelectedPath("Q&A Report");
                          changeSelectedLi(0);
                        }}
                      >
                        <div className="sidebarUlChild0">
                          <i className="fas fa-home"></i>
                        </div>
                        <div> Question Answer Report</div>
                      </div>
                    </NavLink>
                  )}
              </div>
            )}

            {/* <NavLink
              to="/dashboard/getemails"
              activeClassName="active"
              onClick={(e) => checkPath(e, "/dashboard/getemails")}
            >
              <div
                className={"sidebarUlChild "}
                onClick={() => changeSelectedLi(3)}
              >
                <div className="sidebarUlChild0">
                  <i className="fas fa-envelope"></i>
                </div>
                <div>
                  Get Emails <span className="getemailsnew">New</span>
                </div>
              </div>
            </NavLink> */}

            {/* <NavLink
              to="/dashboard/hremails"
              activeClassName="active"
              onClick={(e) => checkPath(e, "/dashboard/hremails")}
            >
              <div
                className={"sidebarUlChild "}
                onClick={() => changeSelectedLi(3)}
              >
                <div className="sidebarUlChild0">
                  <i className="fas fa-envelope"></i>
                </div>
                <div className="targetedsearchnav">
                  HR emails
                  <span className="getemailsnew" style={{ marginLeft: "10px" }}>
                    Latest
                  </span>
                </div>
              </div>
            </NavLink> */}

            {/* {((userData?.planDetails?.v2Id &&
              userData?.planDetails?.planType &&
              userData?.planDetails?.planType != "enterprise") ||
              userData?.planDetails) && (
              <NavLink
                to="/dashboard/lazyapply-x"
                activeClassName="active"
                onClick={(e) => checkPath(e, "/dashboard/lazyapply-x")}
              >
                <div
                  className={"sidebarUlChild "}
                  onClick={() => {
                    mixpanel.identify(userData?.email);
                    mixpanel.people.increment(
                      "lazyapply_x_button sidebar dashboard"
                    );
                    mixpanel.track("lazyapply_x_button sidebar dashboard", {
                      href: window.location.href,
                      email: userData?.email,
                      plan_details: userData?.planDetails || {},
                      v2Id: userData?.planDetails?.v2Id || "",
                    });
                    changeSelectedLi(3);
                  }}
                >
                  <div className="sidebarUlChild0">
                    <i className="fas fa-envelope"></i>
                  </div>
                  <div className="targetedsearchnav">Lazyapply-X</div>
                </div>
              </NavLink>
            )} */}

            {/* <div
              className={"sidebarUlChild "}
              onClick={(e) => {
                mixpanel.identify(userData?.email);
                mixpanel.people.increment(
                  "feature_request_button sidebar dashboard"
                );
                mixpanel.track("feature_request_button sidebar dashboard", {
                  href: window.location.href,
                  email: userData?.email,
                  plan_details: userData?.planDetails || {},
                  v2Id: userData?.planDetails?.v2Id || "",
                });
                setShow(true);
              }}
            >
              <div className="sidebarUlChild0">
                <i className="fas fa-layer-group"></i>
              </div>
              <div> Feature Request</div>
            </div> */}
            {/* <a>
              <div
                className={"sidebarUlChild "}
                onClick={() => {
                  window.open(
                    "https://lazyapply.com/resume-tracker#resume-tracker",
                    "_blank"
                  );
                }}
              >
                <div className="sidebarUlChild0">
                  <i className="fas fa-file"></i>
                </div>
                <div className="targetedsearchnav">
                  Resume Tracker
                  <span className="getemailsnew" style={{ marginLeft: "10px" }}>
                    NEW
                  </span>
                </div>
              </div>
            </a> */}

            {showDebug() && (
              <div className="debugnow" onClick={handleDebug}>
                Debug Now
              </div>
            )}
          </div>
        </div>
        <div className="sidebarFooter">
          <div className="sidebarFooterContent">
            <img src={"/assests/contact.png"} className="contact"></img>
            <p className="contactMain">
              Contact Us <i className="fas fa-address-book"></i>
            </p>
            <div className="contactUs">
              <p>info@lazyapply.com</p>
              <p>+91 8839209610</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
