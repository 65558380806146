import React from 'react';
import './PlanSwitcher.css'

function PlanSwitcher({isYearly,setIsYearly}) {
    const togglePlan = () => {
        setIsYearly(!isYearly);
    };

    return (
        <div className="plan-switcher">
            <span className={isYearly ? '' : 'active-plan'}>Monthly</span>
            <div className={`slider ${isYearly ? 'yearly' : 'monthly'}`} onClick={togglePlan}>
                <div className="circle"></div>
            </div>
            <span className={isYearly ? 'active-plan' : ''}>
              Yearly 
              <span className="save-label">(Save 10%)</span>
            </span>
        </div>
    );
}

export default PlanSwitcher;
