import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function EmailComposer({
  setNowShowMainSendEmailButton,
  initialString,
  emailNotFound,
  showImcompleteDataError,
}) {
  const [displayedContent, setDisplayedContent] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const quillRef = useRef(null);

  useEffect(() => {
    if (initialString) {
      setDisplayedContent("");
      setEditorContent("");
      const words = initialString.split(" ");
      let wordIndex = 0;

      const intervalId = setInterval(() => {
        if (wordIndex < words.length) {
          setDisplayedContent(
            (prev) => prev + (wordIndex > 0 ? " " : "") + words[wordIndex]
          );
          wordIndex++;
        } else {
          clearInterval(intervalId);
          setNowShowMainSendEmailButton(true);
          setEditorContent(initialString);
        }
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [initialString]);

  useEffect(() => {
    if (quillRef.current && quillRef.current.getEditor) {
      const editor = quillRef.current.getEditor();
      const editorElement = editor.container.querySelector(".ql-editor");
      editorElement.scrollTop = editorElement.scrollHeight;
    }
  }, [displayedContent]);

  const containerHeight = showImcompleteDataError ? 100 : 300;
  const containerBackgroundColor =
    emailNotFound || showImcompleteDataError ? "#e9ecef" : "white";

  return (
    <div>
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={displayedContent}
        onChange={setEditorContent}
        placeholder="Email Body Here..."
        readOnly={emailNotFound || showImcompleteDataError}
        style={{
          height: `${containerHeight}px`,
          overflowY: "auto",
          backgroundColor: containerBackgroundColor,
          border: "2px solid #ccc", 
        }}
      />
    </div>
  );
}

export default EmailComposer;
