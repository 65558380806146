import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesSimplyHired() {
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresSimplyHired() {
  let filterRefsSimplyHired = 10;

  let initialFormStateSimplyHired = {
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  };

  let country_optionsSimplyHired = [
    { name: "United States", code: "us" },
    { name: "Canada", code: "ca" },
    { name: "United Kingdom", code: "uk" },
  ];

  let countryUrlMapSimplyHired = {
    us: "https://www.simplyhired.com/jobs",
    uk: "https://www.simplyhired.co.uk/search",
    ca: "https://www.simplyhired.ca/jobs",
  };

  return {
    filterRefsSimplyHired,
    initialFormStateSimplyHired,
    country_optionsSimplyHired,
    countryUrlMapSimplyHired,
  };
}

export function startAutomationSimplyHired(
  countryUrlMap,
  country,
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  uuidv1
) {
  let startAutomationMessage = "simplyHiredFetchFilters";

  country = "us";

  let startAutomationUrl =
    countryUrlMap[country] +
    `?q=${form.skill.split(" ").join("+")}` +
    `&l=${form.location
      .replace(/[^a-zA-Z\s]/g, "")
      .split(" ")
      .join("+")}`;

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    simplyHiredFetchFilters: 0,
    simplyHiredLimit: Math.min(form.numberOfJobs, 100),
    simplyHiredJobLinks: [],
    simplyHiredLocation: form.location,
    simplyHiredCountryCode: country,
    uniquesessionid: uuidv1(),
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: `${startAutomationUrl}`,
    selected: true,
    simplyHired: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitSimplyHired(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData,
  urlParts
) {
  let url = urlMain;
  for (let [key, val] of Object.entries(urlParts)) {
    url = url + "&" + key + "=" + val;
  }

  let finalSubmitSaveValues = {
    simplyHiredSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    simplyHiredBaseUrl: url,
    simplyHiredLimit: Math.min(form.numberOfJobs, 100),
    simplyHiredJobLinks: [],
    simplyHiredResumeId: resumeId,
    session: {
      platformName: "simplyHired",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
  };

  let finalSubmitMessage = "simplyHiredStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionSimplyHired(
  configDummy,
  count,
  sessionLinks,
  userData
) {
  let platformJobsApplied = configDummy.jobsByPlatform["simplyHired"] + count;

  let simplyHiredJobApplicationLinksFinal = [...sessionLinks.current];
  if ("simplyHiredJobApplicationLinksFinal" in userData) {
    simplyHiredJobApplicationLinksFinal = [
      ...userData.simplyHiredJobApplicationLinksFinal,
      ...simplyHiredJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    simplyHiredJobApplicationLinksFinal: simplyHiredJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hSimplyHired(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome
) {
  if ("simplyHired" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of SimplyHired. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);

          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              window.open("https://simplyhired.com/", "_blank");

              window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = request.filterValues;
          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                simplyHiredFetchFilters: 1,
              },
              simplyHired: true,
              message: "simplyHiredFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_SIMPLYHIRED,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "simplyHired");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "simplyHired");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
      if (request.message === "updateLocationUrl") {
        console.log("url final here", request.url);
        setUrlMain(request.url);
      }
    }
  }
}

export function changeFiltersValuesSimplyHired(
  option,
  urlParts,
  key,
  filterValues
) {
  if (option && filterValues.current[key].value) {
    urlParts[filterValues.current[key].value] = option.value;
    console.info("url-parts", urlParts);
  }
}

export function remoteFinalSubmitSimplyHired(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData,
  country,
  countryUrlMap
) {
  country = "us";

  let url =
    countryUrlMap[country] +
    `?q=${form.skill.split(" ").join("+")}` +
    `&l=${form.location
      .replace(/[^a-zA-Z\s]/g, "")
      .split(" ")
      .join("+")}` +
    "Remote";

  // for (let [key, val] of Object.entries(urlParts)) {
  //   url = url + "&" + key + "=" + val;
  // }

  let finalSubmitSaveValues = {
    simplyHiredSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    simplyHiredBaseUrl: url,
    simplyHiredLimit: Math.min(form.numberOfJobs, 100),
    simplyHiredJobLinks: [],
    simplyHiredResumeId: resumeId,
    session: {
      platformName: "simplyHired",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    simplyHiredFetchFilters: 1,
    simplyHiredLocation: form.location,
    simplyHiredCountryCode: country,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let finalSubmitMessage = "simplyHiredStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
