import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesZiprecruiter() {
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    radiusOptions: "",
    orgOptions: "",
    daysOptions: "",
    titleOptions: "",
    tagsOptions: "",
    salaryOptions: "",
    remote: "",
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresZiprecruiter() {
  let filterRefsZiprecruiter = 10;

  let initialFormStateZiprecruiter = {
    skill: "",
    location: "",
    numberOfJobs: 0,
    radiusOptions: "",
    orgOptions: "",
    daysOptions: "",
    titleOptions: "",
    salaryOptions: "",
    tagsOptions: "",
    remote: "",
    resumeJobScore: 40,
  };

  return {
    filterRefsZiprecruiter,
    initialFormStateZiprecruiter,
  };
}

export function startAutomationZiprecruiter(
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString
) {
  let startAutomationMessage = "ziprecruiterFetchFilters";

  const skills = form.skill.split(" ");
  let string1 = "";
  for (let index = 0; index < skills.length; index++) {
    const element = skills[index];
    if (index === 0) {
      string1 += element;
    } else {
      string1 += "+" + element;
    }
  }

  let startAutomationUrl = "";

  if (form.location !== "")
    startAutomationUrl = ` https://www.ziprecruiter.com/candidate/search?search=${string1}&location=${form.location.replace(
      /[^a-zA-Z\s]/g,
      ""
    )}`;
  else {
    startAutomationUrl = `https://www.ziprecruiter.com/candidate/search?search=${string1}`;
  }

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    ziprecruiterFetchFilters: 0,
    ziprecruiterLimit: Math.min(form.numberOfJobs, 200),
    ziprecruiterJobLinks: [],
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: `${startAutomationUrl}`,
    selected: true,
    ziprecruiter: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitZiprecruiter(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData
) {
  let url = urlMain;
  if (form.daysOptions !== "") {
    url += "&days=" + form.daysOptions.value;
  }
  if (form.titleOptions !== "") {
    url += "&refine_by_title=" + form.titleOptions.value;
  }
  if (form.salaryOptions !== "") {
    url += "&refine_by_salary=" + form.salaryOptions.value;
  }
  if (form.remote !== "") {
    url += "&refine_by_location_type=" + form.remote.value;
  }
  if (form.tagsOptions !== "") {
    // url += "&refine_by_tags=" + form.tagsOptions;
    url += "&refine_by_employment=" + form.tagsOptions.value;
  }
  if (form.radiusOptions !== "") {
    url += "&radius=" + form.radiusOptions.value;
  }

  let finalSubmitSaveValues = {
    ziprecruiterSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    ziprecruiterBaseUrl: url,
    ziprecruiterLimit: Math.min(form.numberOfJobs, 200),
    ziprecruiterJobLinks: [],
    ziprecruiterResumeId: resumeId,
    session: {
      platformName: "ziprecruiter",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
  };

  let finalSubmitMessage = "ziprecruiterStartApplying";

  let finalSubmitData = {
    url: `${url}`,
    selected: true,
    ziprecruiter: "true",
    message: "setBaseUrl",
    ziprecruiterBaseUrl: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionZiprecruiter(
  configDummy,
  count,
  sessionLinks,
  userData
) {
  let platformJobsApplied = configDummy.jobsByPlatform["ziprecruiter"] + count;

  let ziprecruiterJobApplicationLinksFinal = [...sessionLinks.current];
  if ("ziprecruiterJobApplicationLinksFinal" in userData) {
    ziprecruiterJobApplicationLinksFinal = [
      ...userData.ziprecruiterJobApplicationLinksFinal,
      ...ziprecruiterJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    ziprecruiterJobApplicationLinksFinal: ziprecruiterJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hZiprecruiter(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome
) {
  if ("ziprecruiter" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        // let countcareerBuilder = count;
        // countcareerBuilder++;
        // console.log(countcareerBuilder)

        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);

          if (request.message1 === "server busy") {
            setmessage("No jobs found due to busy server. Try again later!");
            settitle("Server Busy");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }

          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of ZipRecruiter. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);
          // messages()[MESSAGES.NOFILTERSFETCHED](
          //   MESSAGETYPE.USER_REPORT_FRONTEND,
          //   MESSAGES.NOFILTERSFETCHED,
          //   `${userData.email},${userData.name},${userData.planDetails.planName}`
          // );
          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              window.open("https://www.ziprecruiter.com/authn/login", "_blank");

              window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = {};

          for (let key in request.filterValues) {
            filterValues.current[key] = request.filterValues[key].map(
              (item) => {
                return {
                  value: item.value,
                  label: item.text,
                };
              }
            );
          }

          // filterValues.current = request.filterValues;

          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                ziprecruiterFetchFilters: 1,
              },
              ziprecruiter: true,
              message: "ziprecruiterFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", v2Id, sessionId, response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_ZIPRECRUITER,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "ziprecruiter");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "ziprecruiter");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
    }
  }
}

export function changeFiltersValuesZiprecruiter(option, key, setField) {
  if (key === "refine_by_location_type") {
    setField("remote", option);
  } else if (key === "days") {
    setField("daysOptions", option);
  } else if (key === "refine_by_employment") {
    setField("tagsOptions", option);
  } else if (key === "radius") {
    setField("radiusOptions", option);
  }
}

export function remoteFinalSubmitZiprecruiter(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  form,
  resumeId,
  formRef,
  resumeData
) {
  const skills = form.skill.split(" ");
  let string1 = "";
  for (let index = 0; index < skills.length; index++) {
    const element = skills[index];
    if (index === 0) {
      string1 += element;
    } else {
      string1 += "+" + element;
    }
  }

  let url = "";

  if (form.location !== "")
    url = ` https://www.ziprecruiter.com/candidate/search?search=${string1}&location=${form.location.replace(
      /[^a-zA-Z\s]/g,
      ""
    )}`;
  else {
    url = `https://www.ziprecruiter.com/candidate/search?search=${string1}`;
  }

  url += "&refine_by_location_type=only_remote";

  let finalSubmitSaveValues = {
    ziprecruiterSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    ziprecruiterBaseUrl: url,
    ziprecruiterLimit: Math.min(form.numberOfJobs, 200),
    ziprecruiterJobLinks: [],
    ziprecruiterResumeId: resumeId,
    session: {
      platformName: "ziprecruiter",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    ziprecruiterFetchFilters: 1,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let finalSubmitMessage = "ziprecruiterStartApplying";

  let finalSubmitData = {
    url: `${url}`,
    selected: true,
    ziprecruiter: "true",
    message: "setBaseUrl",
    ziprecruiterBaseUrl: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
