import React, { useState, useEffect } from "react";
import { useStateStore } from "../../store";
import ExpandableText from "./IsExplandableText";
import "./ReferralMainBlock.css";
import ReferralPeopleList from "./ReferralPeopleList";
import { mixpanel } from "../../index";

function ReferralMainBlock({
  setShow = () => {},
  setSendEmail,
  company,
  showSendEmail = true,
  mixpanel_page,
}) {
  const userData = useStateStore((state) => state.userData);
  const setStepNoReferralFeedback = useStateStore(
    (state) => state.setStepNoReferralFeedback
  );
  const setEmployeesMain = useStateStore((state) => state.setEmployees);
  const setMainCompanySet = useStateStore((state) => state.setMainCompanySet);
  const setMainModalTitle = useStateStore((state) => state.setMainModalTitle);
  const setButtonClick = useStateStore((state) => state.setButtonClick);
  let industries = company.industries
    .map((industry) => {
      if (industry === company.industryMain) {
        return { industry, main: 1 };
      } else {
        return { industry, main: 0 };
      }
    })
    .sort((a, b) => b.main - a.main);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const displayedIndustries = showAll ? industries : industries.slice(0, 5);

  useEffect(() => {
    setStepNoReferralFeedback(1);
  }, []);

  return (
    <div className="main-referral-container">
      <div className="main-referral-top">
        <div className="main-referral-top-first">
          <img
            alt="company_logo"
            src={company?.logo || "/assests/no_image_available.png"}
            className="navbarImg"
          ></img>
        </div>
        <div className="main-referral-top-second">
          <div className="main-referral-company-name">
            {company.name}{" "}
            {company?.socialNetworks?.linkedin &&
              company.socialNetworks.linkedin != "" && (
                <i
                  className="fab fa-linkedin"
                  onClick={() => {
                    mixpanel.identify(userData.email);
                    mixpanel.people.increment(
                      `company_linkedin_link ${mixpanel_page} dashboard`
                    );
                    mixpanel.track(
                      `company_linkedin_link ${mixpanel_page} dashboard`,
                      {
                        email: userData?.email,
                        plan_details: userData?.planDetails || {},
                        v2Id: userData?.planDetails?.v2Id || "",
                      }
                    );

                    window.open(company.socialNetworks.linkedin, "_blank");
                  }}
                ></i>
              )}
          </div>
          <div className="main-referral-company-domain main-domain" >
            {company?.domain || ""}
          </div>
          <div className="main-referral-company-otherdetails">
            Employees - {company.totalEmployees}, Country -{" "}
            {company?.country?.name || "unknown"}
            {/* , Revenue - {company.revenue}, */}
          </div>
        </div>
        {showSendEmail && (
          <div className="main-referral-top-third">
            <button
              id="main-ask-referral-button"
              onClick={() => {
                setButtonClick(false);
                setEmployeesMain([])
                mixpanel.identify(userData.email);
                mixpanel.people.increment(
                  "ask_for_referral_button secret_network_page dashboard"
                );
                mixpanel.track(
                  "ask_for_referral_button secret_network_page dashboard",
                  {
                    email: userData?.email,
                    plan_details: userData?.planDetails || {},
                    v2Id: userData?.planDetails?.v2Id || "",
                  }
                );
                setShow(true);
                setMainModalTitle(`Apply To ${company.name}`);
                setMainCompanySet(company);
              }}
            >
              Ask For Referral
            </button>
          </div>
        )}
      </div>
      <div className="main-referral-bottom">
        <div>
          <ExpandableText text={company.description} maxLength={300} />
        </div>
        <div className="main-referral-tags">
          {displayedIndustries.map((industry, index) => (
            <div
              key={index}
              className={
                "main-referral-tag-content" +
                (industry.main === 1 ? " main-referral-tag-content-main" : "")
              }
            >
              {industry.industry}
            </div>
          ))}
          {industries.length > 5 && (
            <div
              onClick={toggleShowAll}
              className="main-referral-tag-content main-referral-tag-content-button"
            >
              {showAll ? "Show Less" : "Show More"}
            </div>
          )}
        </div>
      </div>
      {!showSendEmail && <ReferralPeopleList setSendEmail={setSendEmail} />}
    </div>
  );
}

export default ReferralMainBlock;
