/*global chrome*/
import React, { useEffect, useState, useRef, useCallback } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import FeedbackReferal from "./feedbackReferal";
import Header from "./header";
import Dashboard from "./dashboard/dashboard";
import Faq from "./faq";
import Pricing from "./pricing";
import Privacy from "./privacy";
import Terms from "./terms";
import Refund from "./refundpolicy";
import { Alert } from "react-bootstrap";
import Footer from "./footer";
import ScrollToTop from "./ScrollToTop";
import { Switch, Route, Redirect, useHistory } from "react-router-dom";
import NoMatch from "./error";
import { useStateStore } from "../store/index";
import axios from "axios";
import CustomAlert from "./modal";
import Login from "./login";
import PhoneModal from "./phonemodal";
import { mixpanel } from "../index";
import Hotjar from "@hotjar/browser";
import moment from "moment-timezone";
import LoginMain from "./dashboard/LoginMain";
import Intercom from "@intercom/messenger-js-sdk";

function Popup() {
  const searchParams = new URLSearchParams(window.location.search);
  const from_job_page = searchParams.get("from_job_page");
  const setScriptInjectedGlobal = useStateStore(
    (state) => state.setScriptInjectedGlobal
  );
  const scriptInjectedGlobal = useStateStore(
    (state) => state.scriptInjectedGlobal
  );
  const userResumeV2 = useStateStore((state) => state.userResumeV2);
  const [sessionfetch, setsessionfetch] = useState(0);
  // const [resumefetch, setresumefetch] = useState(0);
  const resumefetch = useStateStore((state) => state.resumefetch);
  const setresumefetch = useStateStore((state) => state.setresumefetch);
  const setTimeZone = useStateStore((state) => state.setTimeZone);
  const configDummy = useStateStore((state) => state.configDummy);
  const setResumesComplete = useStateStore(
    (state) => state.setResumesCompleteV3
  );
  const setStreakValue = useStateStore((state) => state.setStreakValue);
  const configDummyUpdate = useStateStore((state) => state.configDummyUpdate);
  const token = useStateStore((state) => state.token);
  const googleToken = useStateStore((state) => state.googleToken);
  const setVoted = useStateStore((state) => state.setVoted);
  const setInputPlatformName = useStateStore(
    (state) => state.setInputPlatformName
  );
  const setChromeVersion = useStateStore((state) => state.setChromeVersion);
  const scriptinjected = useRef(false);
  const yourExtensionId = useStateStore((state) => state.yourExtensionId);
  // const loading = useRef(null);
  const history = useHistory();
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setExtensionPresent = useStateStore(
    (state) => state.setExtensionPresent
  );
  const setMainLoadingState = useStateStore(
    (state) => state.setMainLoadingState
  );
  const sessionFetchLoadingState = useStateStore(
    (state) => state.sessionFetchLoadingState
  );
  const resumeFetchLoadingState = useStateStore(
    (state) => state.resumeFetchLoadingState
  );

  const setSessionFetchLoadingState = useStateStore(
    (state) => state.setSessionFetchLoadingState
  );
  const setResumeFetchLoadingState = useStateStore(
    (state) => state.setResumeFetchLoadingState
  );
  const setUploadResumeUrlAll = useStateStore(
    (state) => state.setUploadResumeUrlAll
  );
  const showDemo = useStateStore((state) => state.showDemo);
  const globalLogout = useStateStore((state) => state.globalLogout);
  const userAuthentication = useStateStore((state) => state.userAuthentication);
  const setGlobalTabId = useStateStore((state) => state.setGlobalTabId);
  const userImageSrc = useStateStore((state) => state.userImageSrc);
  const updateToken = useStateStore((state) => state.updateToken);
  const userDataInit = useStateStore((state) => state.userDataInit);
  const userData = useStateStore((state) => state.userData);
  const userToken = useStateStore((state) => state.token);
  const isUserAuthenticated = useStateStore(
    (state) => state.isUserAuthenticated
  );
  const [showProp, setShowProp] = useState(false);
  const checkdate = useStateStore((state) => state.checkdate);

  // useEffect(() => {
  //   // if (isUserAuthenticated === 1) {
  //   //   if ("phoneNumberValidation" in userData) {
  //   //     setShowProp(false);
  //   //   } else {
  //   //     setShowProp(true);
  //   //   }
  //   // }
  // }, [userData]);

  useEffect(() => {
    console.log("userdata", userData);
    Intercom({
      app_id: "cpi0banm",
      user_id: userData.email,
      name: userData.name,
      email: userData.email,
      created_at: userData.created_at,
    });
  }, [userData]);

  useEffect(() => {
    // loading.current.style.display = "none";
    console.log("check lstorage, init home");
    //const user = localStorage.getItem("user");
    //const token = localStorage.getItem("token");
    //console.log(user + "user");
    //console.log(token + "token");
    let token = null;
    let user = null;
    const usermain = JSON.parse(window.localStorage.getItem("user") || "{}");
    const tokenmain = window.localStorage.getItem("token");
    //if else for user data present
    if (usermain != undefined && Object.keys(usermain).length > 0) {
      user = usermain;
    }
    if (tokenmain != undefined) {
      token = tokenmain;
    }
    console.log(tokenmain);

    if (token || checkdate == 1) {
      // console.log(token)
      // loading.current.style.display = "block";
      updateToken(token);
      axios
        .get("https://backend.lazyapply.com/getUserDetails", {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(async (response) => {
          // const feedbackReferal = response.data?.feedbackReferal;
          // if (!feedbackReferal) {
          //   showFeedbackWithTimeout();
          // }

          const v2Id = response.data?.planDetails?.v2Id;
          console.log("v2idd", v2Id);
          let mongoData = await axios.get(
            `https://backend.lazyapply.com/v2/userAggregateData/${v2Id}`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          );

          if (
            "planDetails" in response.data &&
            response.data.planDetails.planStarted == 1
          ) {
            let config = configDummy;
            config.plan = 1;
            config = { ...config, ...response.data.planDetails };
            if (mongoData?.data?.found) {
              config.totalJobs = mongoData.data?.aggregateData?.jobs;
              config.jobsByPlatform =
                mongoData.data?.aggregateData?.jobsByPlatform;
              config.totalsessions = mongoData.data?.aggregateData?.sessions;
              let timeZone;
              mixpanel.identify(userData?.email);
              mixpanel.people.set({
                streak: mongoData.data?.streak || 0,
                totalJobs: mongoData.data?.aggregateData?.jobs || 0,
              });

              setStreakValue(mongoData.data?.streak);
              try {
                timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
              } catch (e) {
                timeZone = "UTC"; // Fallback to UTC if not supported
              }
              console.log("timezoneee", timeZone);
              if (
                mongoData?.data?.lastSessionDate &&
                isToday(new Date(), mongoData?.data?.lastSessionDate, timeZone)
              ) {
                config.todaysessioncount =
                  mongoData.data?.aggregateData?.todayJobs;
                config.totalsessionstoday =
                  mongoData.data?.aggregateData?.todaySessions;
              } else {
                config.todaysessioncount = 0;
                config.totalsessionstoday = 0;
              }
            }

            configDummyUpdate(config);
          }
          let obj = {
            firstName: response.data.name,
            email: response.data.email,
          };
          Hotjar.identify(response.data.email, {
            firstName: response.data.name,
            email: response.data.email,
          });
          console.log("hotjar identity");
          console.log("onload", response.data);
          if ("phoneNumberValidation" in response.data) {
            obj.phone = response.data.phoneNumber;
            obj.phoneCountry = response.data.country;
          }

          if ("resumeUrls" in response.data) {
            setUploadResumeUrlAll(response.data.resumeUrls);
          }

          if (window.$crisp)
            window.$crisp.push(["set", "user:email", response.data.email]);

          window?.fcWidget?.user?.setProperties(obj);
          userDataInit(response.data);
          userAuthentication(1);
          mixpanel.identify(response.data.email);
          mixpanel.people.set({
            $name: response.data.name,
            $email: response.data.email,
          });
          // send messag here
          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(
              yourExtensionId,
              {
                dashboard: true,
                saveValues: {
                  token: token,
                  user: response.data,
                },
                global: true,
                message: "initialToken",
              },
              (response) => {
                console.log(
                  "response from extension",
                  response,
                  chrome.runtime.lastError
                );
              }
            );
        })
        .catch((error) => {
          console.log(error);
          // if (loading.current?.style) loading.current.style.display = "none";
          if (error.message == "Request failed with status code 403") {
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
            }, 1500);
          } else {
            console.log("error");
            setmessage("Some error occured, please try again in some time!");
            settitle("Error");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
          }
        });
    }
    if (user) {
      userImageSrc(user.picture);
    }
  }, [userToken]);

  useEffect(() => {
    console.log(
      "sessionfetch",
      sessionFetchLoadingState,
      resumeFetchLoadingState
    );
    if (sessionFetchLoadingState && resumeFetchLoadingState) {
      console.log("setmainloadingstate");
      setMainLoadingState(1);
    } else {
      setMainLoadingState(0);
    }
  }, [sessionFetchLoadingState, resumeFetchLoadingState]);

  /*
  const calCulate = (sessionData) => {
    return;
    let config = {
      indeedJobs: 0,
      linkedinJobs: 0,
      diceJobs: 0,
      ziprecruiterJobs: 0,
      linkedinJobsApplied: 0,
      diceJobsApplied: 0,
      indeedJobsApplied: 0,
      ziprecruiterJobsApplied: 0,
      totalJobs: 0,
      totalsession: [],
      todaysession: [],
      todaysessioncount: 0,
      plan: 0,
      dailyLimit: 0,
      monthlyLimit: 0,
      planName: "No plan",
      startDate: "",
      endDate: "",
      planStarted: 0,
      totalsessions: 0,
      totalsessionstoday: 0,
    };
    console.log("---------------------configupdate-----------------");
    if ("planDetails" in userData && userData.planDetails.planStarted == 1) {
      config.plan = 1;
      config = { ...config, ...userData.planDetails };
      // console.log(config);

      if ("indeedJobApplicationLinks" in userData) {
        config.indeedJobs = userData.indeedJobApplicationLinks.length;
      }
      if ("linkedinJobApplicationLinks" in userData) {
        config.linkedinJobs = userData.linkedinJobApplicationLinks.length;
      }
      if ("diceJobApplicationLinks" in userData) {
        config.diceJobs = userData.diceJobApplicationLinks.length;
      }
      if ("ziprecruiterJobApplicationLinks" in userData) {
        config.ziprecruiterJobs =
          userData.ziprecruiterJobApplicationLinks.length;
      }
      if ("linkedinJobApplicationLinksFinal" in userData) {
        config.linkedinJobsApplied =
          userData.linkedinJobApplicationLinksFinal.length;
      }
      if ("diceJobApplicationLinksFinal" in userData) {
        config.diceJobsApplied = userData.diceJobApplicationLinksFinal.length;
      }
      if ("indeedJobApplicationLinksFinal" in userData) {
        config.indeedJobsApplied =
          userData.indeedJobApplicationLinksFinal.length;
      }
      if ("ziprecruiterJobApplicationLinksFinal" in userData) {
        config.ziprecruiterJobsApplied =
          userData.ziprecruiterJobApplicationLinksFinal.length;
      }
      // config.totalJobs = Math.max(
      //   config.indeedJobsApplied +
      //     config.linkedinJobsApplied +
      //     config.ziprecruiterJobsApplied +
      //     config.diceJobsApplied,
      //   config.totalJobs
      // );
      if ("totalJobs" in sessionData) {
        // config.totalJobs = sessionData.totalJobs;
      }
      if ("totalSessions" in sessionData) {
        // config.totalsessions = sessionData.totalSessions;
      }
      if ("totatSessionsToday" in sessionData) {
        // config.totalsessionstoday = sessionData.totatSessionsToday;
      }
      if ("sessionsToday" in sessionData) {
        let count = 0;
        sessionData.sessionsToday.forEach((session) => {
          if ("count" in session) count += parseInt(session.count);
        });
        config.todaysessioncount = count;
        config.todaysession = sessionData.sessionsToday;
      }
      if ("value" in sessionData) {
        config.totalsession = sessionData.value;
      }
    } else {
      configDummy.plan = 0;
      configDummy.planStarted = 0;
      //  console.log("plan finished");
    }
    configDummyUpdate(config);
  };

  */

  useEffect(() => {
    console.log("------useeffect homedash-------------");
    console.log("user data length - ", Object.keys(userData).length);
    if (userData.voted != "") {
      setVoted(userData.voted);
      if (
        userData.voted !== "zip_recruiter" &&
        userData.voted !== "career_builder" &&
        userData.voted !== "glass_door"
      ) {
        setInputPlatformName(userData.voted);
      }
    }
    if (token && Object.keys(userData).length > 0 && isUserAuthenticated == 1) {
      console.log("here0");
      if (sessionfetch === 0) {
        console.log("here1");
        setsessionfetch(1);
        const v2Id = userData?.planDetails?.v2Id;
        const url = v2Id
          ? `https://backend.lazyapply.com/v2/getUserSessions/${v2Id}`
          : "https://backend.lazyapply.com/getUserSessions";
        const url1 = `https://backend.lazyapply.com/v2/totalAnalytics/${v2Id}`;
        const url2 = `https://backend.lazyapply.com/v2/initialAnalytics/${v2Id}`;
        axios
          .get(url1, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            console.log("first response", response.data);
            if (response.data.found) {
              setSessionFetchLoadingState(1);
              setTimeZone(response.data.timeZone);
              // setStreakAnalytics(response.data);
              // calCulate(response.data);
            } else {
              axios
                .get(url, {
                  headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                  let timeZone;
                  try {
                    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                  } catch (e) {
                    timeZone = "UTC"; // Fallback to UTC if not supported
                  }
                  setTimeZone(timeZone);
                  axios
                    .post(
                      url2,
                      { ...response.data, timeZone },
                      {
                        headers: { Authorization: `Bearer ${token}` },
                      }
                    )
                    .then((r) => {
                      if (!r.data.error) {
                        setSessionFetchLoadingState(1);
                        // setStreakAnalytics(r.data);
                        // calCulate(r.data);
                      }
                    })
                    .catch((e) => {
                      setSessionFetchLoadingState(1);
                      console.log("error", e);
                    });
                })
                .catch((error) => {
                  setSessionFetchLoadingState(1);
                  // calCulate({});
                  if (error.message == "Request failed with status code 403") {
                    console.log("reauthenticate");
                    setmessage("Please relogin again!");
                    settitle("Not authorized");
                    setmodalshow(true);
                    setTimeout(() => {
                      globalLogout();
                    }, 1500);
                  } else {
                    console.log("error");
                  }
                });
            }
          })
          .catch((err) => {
            console.log("error");
          });
      }
      if (resumefetch === 0 && userData?.planDetails?.v2Id) {
        console.log("here2");
        const v2Id = userData?.planDetails?.v2Id;
        axios
          .get(`https://backend.lazyapply.com/v2/resumesV3/completed/${v2Id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            setresumefetch(1);
            setResumeFetchLoadingState(1);
            console.log("resumescompleted", response);
            if (
              "resumes" in response.data &&
              Object.keys(response.data.resumes).length > 0
            ) {
              setResumesComplete(response.data.resumes);
            }
          })
          .catch((err) => {
            setresumefetch(1);
            setResumeFetchLoadingState(1);
            console.log("some error occured");
            if (err.message == "Request failed with status code 403") {
              console.log("reauthenticate");
              setmessage("Please relogin again!");
              settitle("Not authorized");
              setmodalshow(true);
              setTimeout(() => {
                globalLogout();
              }, 1500);
            } else {
              console.log("error");
            }
          });
      } else {
        setResumeFetchLoadingState(1);
      }
    }
  }, [userData, token]);

  const [showFeedback, setShowFeedback] = useState(false);

  // const showFeedbackWithTimeout = () => {
  //   setTimeout(() => {
  //     setShowFeedback(true);
  //   }, 10000);
  // };

  useEffect(() => {
    if (typeof chrome !== "undefined" && chrome && chrome.runtime) {
      chrome.runtime.sendMessage(
        yourExtensionId,
        {
          lazyapply: "lazyapply",
          message: "check",
          injected: scriptInjectedGlobal,
        },
        function (response) {
          // Do whatever you want, background script is ready now
          var lastError = chrome.runtime.lastError;
          if (lastError) {
            console.log(lastError.message, "error");
            // 'Could not establish connection. Receiving end does not exist.'
            return;
          }
          console.log("check", response);
          if ("status" in response && response.status == "installed") {
            setExtensionPresent(true);
            setScriptInjectedGlobal(true);
          }
        }
      );
    }
  }, []);

  const messageFn = useCallback((event) => {
    if (
      typeof event.data === "object" &&
      !Array.isArray(event.data) &&
      event.data !== null &&
      !scriptinjected.current &&
      event.data.injectedScript == "true"
    ) {
      if (event.data.version != -1) {
        setChromeVersion(event.data.version);
      }
      setScriptInjectedGlobal(true);
      scriptinjected.current = true;
    }
  }, []);

  const setShowDemo = useStateStore((state) => state.setShowDemo);
  const mainJobPageFn = () => {
    if (from_job_page && from_job_page != "") {
      setShowDemo(true);
      window.localStorage.setItem("fromjob", true);
    }
    const fromjob = window.localStorage.getItem("fromjob");
    if (fromjob != undefined && fromjob) {
      setShowDemo(true);
    }
  };

  useEffect(() => {
    mainJobPageFn();
    window.addEventListener("message", messageFn);
    return () => {
      window.removeEventListener("message", messageFn);
    };
  }, []);

  const hideFeedback = () => {
    setShowFeedback(false);
  };

  const [showAlert, setShowAlert] = useState(true);
  const buynow = () => {
    history.push("/dashboard/lazyapply-x");
  };

  function isToday(userDate, lastDate, timeZone) {
    if (!userDate || !lastDate || !timeZone) return false;

    const now = moment(userDate).tz(timeZone);
    const last = moment(lastDate).tz(timeZone);

    console.log("nowww", now.format("YYYY-MM-DD"));
    console.log("lasttt", last.format("YYYY-MM-DD"));

    return now.format("YYYY-MM-DD") === last.format("YYYY-MM-DD");
  }

  return (
    <>
      {/* {isUserAuthenticated ? (
        <>
          <Alert
            show={showAlert}
            style={{ position: "fixed" }}
          >
            <Alert.Heading>
              {`The Future of Job Hunting – Try Our Powerful New Tool! ->`}{" "}
            
              <span className="freeforsometime"> LazyApply 2.0 </span>
              
              <span
                className="mainTimeBuy"
                onClick={() =>
                  window.open(
                    `https://referralai.lazyapply.com/dashboard?token=${googleToken}`
                  )
                }
              >
                USE NOW
              </span>
            </Alert.Heading>
          </Alert>
        </>
      ) : (
        <></>
      )} */}
      <FeedbackReferal
        hideFeedback={hideFeedback}
        showFeedback={showFeedback}
      />
      <PhoneModal showProp={showProp}></PhoneModal>
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {/* <div className="loading" ref={loading}>
        <div className="boxLoading"></div>
      </div> */}
      <Switch>
        <Route exact path={`/`}>
          {isUserAuthenticated == 1 ? (
            <>
              {/* <Redirect to="/dashboard/main" /> */}
              <Redirect to="/dashboard" />
            </>
          ) : (
            <>
              <ScrollToTop>
                {showDemo ? (
                  <>
                    <LoginMain></LoginMain>
                  </>
                ) : (
                  <>
                    <Login></Login>
                  </>
                )}
              </ScrollToTop>
            </>
          )}
        </Route>
        <Route exact path={`/pricing`}>
          {isUserAuthenticated == 1 ? (
            <ScrollToTop>
              <Header></Header>
              <Pricing></Pricing>
              <Footer></Footer>
            </ScrollToTop>
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        <Route exact path={`/faq`}>
          <ScrollToTop>
            <Header></Header>
            <Faq></Faq>
            <Footer></Footer>
          </ScrollToTop>
        </Route>
        <Route exact path={`/privacy`}>
          <ScrollToTop>
            <Header></Header>
            <Privacy></Privacy>
            <Footer></Footer>
          </ScrollToTop>
        </Route>
        <Route exact path={`/terms`}>
          <ScrollToTop>
            <Header></Header>
            <Terms></Terms>
            <Footer></Footer>
          </ScrollToTop>
        </Route>
        <Route exact path={`/refundpolicy`}>
          <ScrollToTop>
            <Header></Header>
            <Refund></Refund>
            <Footer></Footer>
          </ScrollToTop>
        </Route>
        <Route path="/dashboard">
          {isUserAuthenticated == 1 ? (
            <>
              <Header></Header>
              <Dashboard></Dashboard>
            </>
          ) : (
            <>
              {showDemo ? (
                <>
                  <LoginMain></LoginMain>
                </>
              ) : (
                <>
                  <Login></Login>
                </>
              )}
            </>
          )}
        </Route>
        <Route>
          <NoMatch />
        </Route>
      </Switch>
    </>
  );
}

export default Popup;
