import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

const experienceOptions = [
  {
    value: "1",
    label: "Internship",
  },
  {
    value: "2",
    label: "Entry level",
  },
  {
    value: "3",
    label: "Associate",
  },
  {
    value: "4",
    label: "Mid-Senior level",
  },
  {
    value: "5",
    label: "Director",
  },
  {
    value: "6",
    label: "Executive",
  },
];

const jobTypeOptions = [
  {
    value: "F",
    label: "Full-time",
  },
  {
    value: "P",
    label: "Part-time",
  },
  {
    value: "C",
    label: "Contract",
  },
  {
    value: "T",
    label: "Temporary",
  },
  {
    value: "V",
    label: "Volunteer",
  },
  {
    value: "I",
    label: "Internship",
  },
];

const datePosted = [
  {
    value: "",
    label: "Any time",
  },
  {
    value: "r2592000",
    label: "Past month",
  },
  {
    value: "r604800",
    label: "Past week",
  },
  {
    value: "r86400",
    label: "Past 24 hours",
  },
];

export function useStateVariablesLinkedin() {
  const [form, setForm] = useState({
    jobtype: [],
    experience: [],
    skill: "",
    location: "",
    numberOfJobs: 0,
    datePosted: "",
    industry: [],
    jobFunction: [],
    jobUnder10: false,
    jobCompany: [],
    jobTitle: [],
    jobWorkplace: [],
    jobBenifits: [],
    jobSalary: "",
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresLinkedin() {
  let filterRefsLinkedin = 10;

  let initialFormStateLinkedin = {
    jobtype: [],
    experience: [],
    skill: "",
    location: "",
    numberOfJobs: 0,
    datePosted: "",
    industry: [],
    jobFunction: [],
    jobUnder10: false,
    jobCompany: [],
    jobTitle: [],
    jobWorkplace: [],
    jobBenifits: [],
    jobSalary: "",
    resumeJobScore: 40,
  };

  return {
    filterRefsLinkedin,
    initialFormStateLinkedin,
  };
}

export function startAutomationLinkedin(
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  uuidv1,
  resumeInfo,
  resumeArr
) {
  let startAutomationMessage = "linkedinFetchFilters";

  let startAutomationUrl = `https://linkedin.com/jobs/`;

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    linkedinFetchFilters: 0,
    linkedinLimit: Math.min(form.numberOfJobs, 500),
    linkedinJobLinks: [],
    uniquesessionid: uuidv1(),
    linkedinResumeInfo: resumeInfo,
    linkedinResumeArr: resumeArr,
    linkedinLocation: form.location,
    linkedinSkill: form.skill,
    linkedinPageNumber: 1,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: `${startAutomationUrl}&start=0`,
    selected: true,
    linkedin: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitLinkedin(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  form,
  resumeId,
  formRef,
  urlMain,
  resumeData
) {
  function makeFilter(value) {
    let string = "";
    for (let index = 0; index < value.length; index++) {
      const element = value[index].value;
      if (index === 0) {
        string += element;
      } else {
        string += "%2C" + element;
      }
    }
    return string;
  }

  console.log(form, "form");

  let filter3Value = "";
  let filter1Value = "";

  if (form.datePosted.value) {
    filter3Value = form.datePosted.value;
  }

  if (form.jobSalary.value) {
    filter1Value = form.jobSalary.value;
  }

  let url = urlMain;

  console.log(url, "url linkedin");

  console.log(filter3Value, filter1Value, "filter 1 and 3 value");

  let string3 = "&f_TPR=" + filter3Value;
  let string1 = "&f_SB2=" + filter1Value;

  if (makeFilter(form.experience) !== "") {
    url = url + "&f_E=" + makeFilter(form.experience);
  }
  if (makeFilter(form.jobtype) !== "") {
    url = url + "&f_JT=" + makeFilter(form.jobtype);
  }
  if (makeFilter(form.jobCompany) !== "") {
    url = url + "&f_C=" + makeFilter(form.jobCompany);
  }
  if (makeFilter(form.jobFunction) !== "") {
    url = url + "&f_F=" + makeFilter(form.jobFunction);
  }
  if (makeFilter(form.jobTitle) !== "") {
    url = url + "&f_T=" + makeFilter(form.jobTitle);
  }
  if (makeFilter(form.jobWorkplace) !== "") {
    url = url + "&f_WT=" + makeFilter(form.jobWorkplace);
  }
  if (makeFilter(form.jobBenifits) !== "") {
    url = url + "&f_BE=" + makeFilter(form.jobBenifits);
  }
  if (makeFilter(form.industry) !== "") {
    url = url + "&f_I=" + makeFilter(form.industry);
  }

  if (filter3Value !== "") url = url + string3;
  if (filter1Value !== "") url = url + string1;
  if (form.jobUnder10) {
    url = url + "&f_EA=true";
  }

  let finalSubmitSaveValues = {
    linkedinSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    linkedinBaseUrl: url,
    linkedinLimit: Math.min(form.numberOfJobs, 500),
    linkedinJobLinks: [],
    linkedinResumeId: resumeId,
    session: {
      platformName: "linkedin",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
  };

  let finalSubmitMessage = "linkedinStartApplying";

  let finalSubmitData = {
    url: `${url}&start=0`,
    selected: true,
    linkedin: true,
    message: "setBaseUrl",
    linkedinBaseUrl: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionLinkedin(
  configDummy,
  count,
  sessionLinks,
  userData
) {
  let platformJobsApplied = configDummy.jobsByPlatform["linkedin"] + count;

  let linkedinJobApplicationLinksFinal = [...sessionLinks.current];
  if ("linkedinJobApplicationLinksFinal" in userData) {
    linkedinJobApplicationLinksFinal = [
      ...userData.linkedinJobApplicationLinksFinal,
      ...linkedinJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    linkedinJobApplicationLinksFinal: linkedinJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hLinkedin(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome,
  setResumeArr,
  resumeRef1,
  resumeRef2,
  resumeRef3
) {
  if ("linkedin" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        // let countcareerBuilder = count;
        // countcareerBuilder++;
        // console.log(countcareerBuilder)

        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "resumeArr") {
        console.log("resumeArrrr", request.resumeArr);
        setResumeArr(request.resumeArr.slice(0, 5));
        window.localStorage.setItem(
          "resumeArr",
          JSON.stringify(request.resumeArr.slice(0, 5))
        );
      } else if (request.message === "filterValues") {
        if (request.initUrl) {
          // console.log('init urllllll', request.initUrl);
          setUrlMain(request.initUrl.replace(/\/?#?\/?$/, ""));
        }

        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of Linkedin. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);
          messages()[MESSAGES.NOFILTERSFETCHED](
            MESSAGETYPE.USER_REPORT_FRONTEND,
            MESSAGES.NOFILTERSFETCHED,
            `${userData.email},${userData.name},${userData.planDetails.planName}`
          );
          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              window.open("https://www.linkedin.com/login", "_blank");

              window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = {
            jobcompanyOptions: [],
            jobtitleOptions: [],
            jobindustryOptions: [],
            jobfunctionOptions: [],
            jobWorkplaceOptions: [],
            jobSalaryOptions: [],
            jobBenifitOptions: [],
            datePosted: datePosted,
            jobTypeOptions: jobTypeOptions,
            experienceOptions: experienceOptions,
          };

          request.filterValues.forEach((filter) => {
            let filterObj = {};
            if ("name" in filter && "text" in filter) {
              filterObj.label = filter.text.split("\n")[0];
              if (filter?.name.includes("advanced-filter-timePostedRange-")) {
                console.log(
                  filter?.name.split("advanced-filter-timePostedRange-"),
                  "time posted"
                );
                filterObj.value = filter?.name.split(
                  "advanced-filter-timePostedRange-"
                )[1];
              } else if (filter?.name.includes("advanced-filter-company-")) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-company-"
                )[1];
                filterValues.current.jobcompanyOptions.push(filterObj);
              } else if (
                filter?.name.includes("advanced-filter-populatedPlace-")
              ) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-populatedPlace-"
                )[1];
              } else if (filter?.name.includes("advanced-filter-industry-")) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-industry-"
                )[1];
                filterValues.current.jobindustryOptions.push(filterObj);
              } else if (filter?.name.includes("advanced-filter-function-")) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-function-"
                )[1];
                filterValues.current.jobfunctionOptions.push(filterObj);
              } else if (filter?.name.includes("advanced-filter-title-")) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-title-"
                )[1];
                filterValues.current.jobtitleOptions.push(filterObj);
              } else if (
                filter?.name.includes("advanced-filter-workplaceType-")
              ) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-workplaceType-"
                )[1];
                filterValues.current.jobWorkplaceOptions.push(filterObj);
              } else if (
                filter?.name.includes("advanced-filter-salaryBucketV2-")
              ) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-salaryBucketV2-"
                )[1];
                filterValues.current.jobSalaryOptions.push(filterObj);
              } else if (filter?.name.includes("advanced-filter-benefits-")) {
                filterObj.value = filter?.name.split(
                  "advanced-filter-benefits-"
                )[1];
                filterValues.current.jobBenifitOptions.push(filterObj);
              } else {
                console.log("Not required");
              }
            }
          });

          // filterValues.current = request.filterValues;

          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                linkedinFetchFilters: 1,
              },
              linkedin: true,
              message: "linkedinFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (resumeRef1.current) resumeRef1.current.style.display = "none";
            if (resumeRef2.current) resumeRef2.current.style.display = "none";
            if (resumeRef3.current) resumeRef3.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            if (resumeRef1.current) resumeRef1.current.style.display = "none";
            if (resumeRef2.current) resumeRef2.current.style.display = "none";
            if (resumeRef3.current) resumeRef3.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_LINKEDIN,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "linkedin");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "linkedin");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
    }
  }
}

export function changeFiltersValuesLinkedin(option, key, setField) {
  if (key === "datePosted") {
    setField("datePosted", option);
  } else if (key === "jobcompanyOptions") {
    setField("jobCompany", option);
  } else if (key === "jobtitleOptions") {
    setField("jobTitle", option);
  } else if (key === "jobindustryOptions") {
    setField("industry", option);
  } else if (key === "jobfunctionOptions") {
    setField("jobFunction", option);
  } else if (key === "jobWorkplaceOptions") {
    setField("jobWorkplace", option);
  } else if (key === "jobSalaryOptions") {
    setField("jobSalary", option);
  } else if (key === "jobBenifitOptions") {
    setField("jobBenifits", option);
  } else if (key === "jobTypeOptions") {
    setField("jobtype", option);
  } else if (key === "experienceOptions") {
    setField("experience", option);
  }
}

export function remoteFinalSubmitLinkedin(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  form,
  resumeId,
  formRef,
  urlMain,
  resumeData,
  resumeInfo,
  resumeArr
) {
  let string1 = encodeURIComponent(form.skill);

  let url =
    `https://linkedin.com/jobs/search/?currentJobId=&f_AL=true&f_WT=2&keywords=` +
    string1;

  if (form.jobUnder10) {
    url = url + "&f_EA=true";
  }

  let finalSubmitSaveValues = {
    linkedinSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    linkedinBaseUrl: url,
    linkedinLimit: Math.min(form.numberOfJobs, 500),
    linkedinJobLinks: [],
    linkedinResumeId: resumeId,
    session: {
      platformName: "linkedin",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    linkedinFetchFilters: 1,
    linkedinResumeInfo: resumeInfo,
    linkedinResumeArr: resumeArr,
    linkedinLocation: form.location,
    linkedinSkill: form.skill,
    linkedinPageNumber: 1,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let finalSubmitMessage = "linkedinStartApplying";

  let finalSubmitData = {
    url: `${url}&start=0`,
    selected: true,
    linkedin: true,
    message: "setBaseUrl",
    linkedinBaseUrl: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
