import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
function LinkedinFetchFiltersArticle({ showProp, hideShow, title }) {
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    hideShow();
  };
  useEffect(() => {
    setShow(showProp);
  }, [showProp]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      dialogClassName="custom-modal-width2"
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="featuresDiv0"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="featuresChild0">
            <div className="featuresChild0Content" style={{ width: "70%" }}>
              <div className="featuresChild0Contenttop">
                <div>
                  <div className="featurepoint">1</div>
                </div>
                <div className="featuretitle"> Log in to Linkedin </div>
              </div>
              <div className="featuresChild0ContentBottom">
                <p>
                  Visit{" "}
                  <a href="https://www.linkedin.com/login/" target="_blank">
                    Linkedin
                  </a>{" "}
                  to check if you're logged in Linkedin. If not, please logged
                  in to your linkedin Account and retry the process on
                  LazyApply.
                </p>
              </div>
            </div>
          </div>
          <div className="featuresChild1">
            <img
              src={"/assests/linkedinLogin.png"}
              class="featuresImageX"
              style={{ minHeight: "250px", maxWidth: "300px" }}
            />
          </div>
        </div>
        <div
          className="featuresDiv0"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="featuresChild1">
            <img
              src={"/assests/lknLanguage.png"}
              class="featuresImageX"
              style={{ maxWidth: "340px", maxHeight: "400px" }}
            />
          </div>
          <div className="featuresChild0">
            <div className="featuresChild0Content" style={{ width: "80%" }}>
              <div className="featuresChild0Contenttop">
                <div>
                  <div className="featurepoint">2</div>
                </div>
                <div className="featuretitle"> Set Language as English </div>
              </div>
              <div className="featuresChild0ContentBottom">
                <p>
                  Visit{" "}
                  <a
                    href="https://www.linkedin.com/mypreferences/d/language"
                    target="_blank"
                  >
                    this page
                  </a>{" "}
                  to change your language on Linkedin to English. If not, please
                  logged in to your linkedin Account and retry the process on
                  LazyApply.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="featuresDiv0"
          style={{ gridTemplateColumns: "1fr 1fr" }}
        >
          <div className="featuresChild0">
            <div className="featuresChild0Content" style={{ width: "70%" }}>
              <div className="featuresChild0Contenttop">
                <div>
                  <div className="featurepoint">3</div>
                </div>
                <div className="featuretitle">
                  {" "}
                  Do not use multiple job titles at once{" "}
                </div>
              </div>
              <div className="featuresChild0ContentBottom">
                <p>
                  The image shows a bad example of entering job titles in the
                  skill input.
                </p>
                <p>
                  In case you want to apply for more than one job title, please
                  do that in separate sessions.
                </p>
              </div>
            </div>
          </div>
          <div className="featuresChild1">
            <img
              src={"/assests/multipleSkill.png"}
              class="featuresImageX"
              style={{
                minHeight: "100px",
                minWidth: "300px",
                maxWidth: "400px",
                maxHeight: "200px",
              }}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LinkedinFetchFiltersArticle;
