import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useStateStore } from "../../store";
import mainCompanies from "./data/companies.json";

function AutomationStartModal({
  prompt,
  showStartModal,
  customFn,
  setMainPrompts,
  setPromptIndexSelected,
}) {
  const [show, setShow] = useState(false);
  const setEmailAutomationReferral = useStateStore(
    (state) => state.setEmailAutomationReferral
  );
  const referralInitData = useStateStore((state) => state.referralInitData);
  const setReferralInitData = useStateStore(
    (state) => state.setReferralInitData
  );

  const optionsRevenue = mainCompanies.revenue;
  const optionsEmployees = mainCompanies.employees;
  const optionsCountry = mainCompanies.countries;

  const setStopClick = useStateStore((state) => state.setStopClick);
  const setPromptSelected = useStateStore((state) => state.setPromptSelected);
  const handleClose = () => {
    setMainPrompts([
      {
        id: 1,
        checked: false,
        text: "I want referral from N companies that can offer more than Y CTC for Z role.",
      },
      {
        id: 2,
        checked: false,
        text: "I want referral from X company for Z role.",
      },
    ]);
    setPromptIndexSelected(-1);
    setShow(false);
    customFn();
  };

  useEffect(() => {
    setShow(showStartModal);
  }, [showStartModal, prompt]);

  const [form, setForm] = useState({
    N: 5,
    Z: "Software Developer",
    X: "stripe.com",
    Y: "20lakhs",
    id: -1,
    employees: 2,
    employeeQuery: "Software Developer",
    country: "",
    employeeCount: "",
    revenue: "",
  });

  const setField = (key, value) => {
    if (key === "Z") {
      const referralData = referralInitData?.referral;
      setReferralInitData({
        ...referralInitData,
        referral: {
          ...referralData,
          referralTitle: value,
        },
      });
    }
    setForm({ ...form, [key]: value, id: prompt.id });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      centered
      size="md"
    >
      <Modal.Header closeButton>
        <Modal.Title>Email Automation</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ padding: "10px" }}>
        <div>
          <div className="automation_start_modal">
            {prompt?.id && prompt.id == 1 ? (
              <>
                I want referral from{" "}
                <Form.Control
                  className="form_main_automation form_main_automation_count"
                  type="number"
                  id="companies_count"
                  value={form.N}
                  onChange={(e) => setField("N", e.target.value)}
                />{" "}
                companies that can offer more than
                <Form.Control
                  className="form_main_automation form_main_automation_count"
                  type="text"
                  id="companies_ctc"
                  value={form.Y}
                  onChange={(e) => setField("Y", e.target.value)}
                />{" "}
                CTC for
                <Form.Control
                  className="form_main_automation"
                  type="text"
                  id="companies_role"
                  value={form.Z}
                  onChange={(e) => setField("Z", e.target.value)}
                />{" "}
                role.
              </>
            ) : (
              <>
                I want referral from
                <Form.Control
                  className="form_main_automation"
                  type="text"
                  placeholder="Ex. amazon.com,stripe"
                  id="companies_companyname"
                  value={form.X}
                  onChange={(e) => setField("X", e.target.value)}
                />{" "}
                company for
                <Form.Control
                  className="form_main_automation"
                  type="text"
                  id="companies_role"
                  value={form.Z}
                  onChange={(e) => setField("Z", e.target.value)}
                />{" "}
                role.
              </>
            )}
          </div>
          {prompt?.id && prompt.id == 1 ? (
            <div className="automation_start_modal_additional_settings">
              <p>Additional Settings ( Optional ) </p>

              <Form.Group controlId="followersQuestion">
                <Form.Control
                  as="select"
                  required={true}
                  value={
                    mainCompanies.countries.filter(
                      (c) => c.code == form.country
                    )?.[0]?.code || ""
                  }
                  onChange={(e) => {
                    setField("country", e.target.value);
                  }}
                >
                  <option value="">Select Country</option>
                  {optionsCountry.map((option, index) => (
                    <option key={index} value={option.code}>
                      {option.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="followersQuestion">
                <Form.Control
                  as="select"
                  required={true}
                  value={form.employeeCount}
                  onChange={(e) => {
                    setField("employeeCount", e.target.value);
                  }}
                >
                  <option value="">Select Employees Count</option>
                  {optionsEmployees.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="followersQuestion">
                <Form.Control
                  as="select"
                  required={true}
                  value={form.revenue}
                  onChange={(e) => {
                    setField("revenue", e.target.value);
                  }}
                >
                  <option value="">Revenue</option>
                  {optionsRevenue.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="followersQuestion">
                <Form.Label htmlFor="inputskill">
                  Find Employees With Job Title
                </Form.Label>
                <Form.Control
                  type="text"
                  value={form.employeeQuery}
                  placeholder="Hr, software developer, marketing etc"
                  onChange={(e) => setField("employeeQuery", e.target.value)}
                />
              </Form.Group>
            </div>
          ) : (
            <>
              <div className="automation_start_modal_additional_settings">
                <p>Additional Settings ( Optional ) </p>
                <Form.Group controlId="followersQuestion">
                  <Form.Label htmlFor="inputskill">
                    Find Employees With Job Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={form.employeeQuery}
                    placeholder="Hr, software developer, marketing etc"
                    onChange={(e) => setField("employeeQuery", e.target.value)}
                  />
                </Form.Group>
              </div>
            </>
          )}
          <div className="automation_start_modal_button">
            <button
              onClick={() => {
                setStopClick(false);
                const referralData = referralInitData?.referral;
                setReferralInitData({
                  ...referralInitData,
                  referral: {
                    ...referralData,
                    referralTitle: form.Z,
                  },
                });
                setPromptSelected({ prompt: prompt, form: form });
                setEmailAutomationReferral(true);
                handleClose();
              }}
            >
              Start Sending Emails
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AutomationStartModal;
