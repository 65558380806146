import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import CustomAlert from "../modal";
import { useStateStore } from "../../store/index";
// import resumeimg from "../../resume.png";
import axios from "axios";
import InviteMemberModal from "./inviteMemberModal";

function UserInvites() {
  const token = useStateStore((state) => state.token);
  const history = useHistory();
  const userData = useStateStore((state) => state.userData);
  const [message, setmessage] = useState("");
  const [title, settitle] = useState("");
  const [modalshow, setmodalshow] = useState(false);
  const setUserInvites = useStateStore((state) => state.setUserInvites);
  const setInvites = useStateStore((state) => state.setInvites);
  const invites = useStateStore((state) => state.invites);
  const userinvites = useStateStore((state) => state.userInvites);
  const [userInvitesPresent, setUserInvitesPresent] = useState(false);
  const [invitesPresent, setInvitesPresent] = useState(false);
  const [resumeData, setResumeData] = useState([]);
  const [completedResumes, setCompletedResumes] = useState({});
  const [memberType, setMemberType] = useState("");
  function goBack() {
    history.goBack();
  }

  useEffect(() => {
    // get all user invites
    loading.current.style.display = "none";
    const v2Id = userData?.planDetails?.v2Id;
    const membertype = userData?.planDetails?.membertype;

    if (
      userData?.planDetails?.planName &&
      ["gold", "diamond", "platinum"].includes(userData?.planDetails?.planName)
    ) {
      if (v2Id && membertype && membertype === "admin" && v2Id != "") {
        if (membertype) {
          setMemberType(membertype);
        }
        loading.current.style.display = "block";
        axios
          .get(`https://backend.lazyapply.com/v2/userinvites/${v2Id}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((response) => {
            console.log("userinvites admin", response);
            loading.current.style.display = "none";
            if (
              "userinvites" in response.data &&
              Object.keys(response.data.userinvites).length > 0
            ) {
              setUserInvitesPresent(true);
              setUserInvites(response.data.userinvites);
            }
          })
          .catch((err) => {
            loading.current.style.display = "none";
            console.log("some error occured");
          });
      }
    }
  }, [userData]);

  const upgradePlan = (data) => {
    axios
      .post(
        `https://backend.lazyapply.com/v2/upgradePlan/${data.v2Id}`,
        {
          fromemail: data.invitedBy,
          toemail: data.email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((response) => {
        console.log("planupgraded", response);
        loading.current.style.display = "none";
        setmessage("Your plan is upgraded successfully");
        settitle("Plan Upgraded!");
        setmodalshow(true);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured");
      });
  };

  const acceptInvite = (data) => {
    console.log("accept invite", data);
    loading.current.style.display = "block";
    axios
      .post(
        `https://backend.lazyapply.com/v2/updateInvite/${data.v2Id}`,
        {
          email: data.email,
          accepted: 1,
          inviteId: data.inviteId,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(async (response) => {
        console.log("invite accepted", response);
        setInvites({
          [data.inviteId]: {
            ...data,
            accepted: 1,
          },
        });
        await upgradePlan(data);
      })
      .catch((err) => {
        loading.current.style.display = "none";
        console.log("some error occured");
      });
  };

  useEffect(() => {
    if (Object.keys(userinvites).length > 0) {
      setUserInvitesPresent(true);
    }
  }, [userinvites]);

  const inviteMember = () => {
    if (
      userData?.planDetails?.userLimit &&
      userData?.planDetails?.userLimit > Object.keys(userinvites).length
    )
      setShowInviteModal(true);
    else {
      setmessage("Cannot send more invites, invitation limit reached");
      settitle("User invitation limit reached!");
      setmodalshow(true);
      setTimeout(() => {
        setmodalshow(false);
      }, 3000);
    }
  };

  function closeModal() {
    setShowInviteModal(false);
  }
  const [showInviteModal, setShowInviteModal] = useState(false);
  const loading = useRef(null);

  useEffect(() => {
    if (Object.keys(invites).length > 0) {
      setInvitesPresent(true);
    }
  }, [invites]);

  const InviteComponent = () => {
    return (
      <>
        {invitesPresent ? (
          <>
            <p className="editresumeheading">WorkSpace Member</p>
            <div className="v2UserInvites">
              <p className="userinvitessecondheading">Your Invitations</p>
              <div className="row">
                {Object.keys(invites).map((key, index) => {
                  const data = invites[key];
                  return (
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-3">
                          {data.accepted ? (
                            <div className="v2userinviteaccepted">
                              Invite Accepted
                            </div>
                          ) : (
                            <div
                              onClick={() => acceptInvite(data)}
                              className="v2userinviterejected"
                              style={{ cursor: "pointer" }}
                            >
                              Accept Invitation
                            </div>
                          )}
                        </div>
                        <div className="col-8">
                          <div className="v2userinviteemail">
                            {"Invited By - "}
                            <span>{data.invitedBy}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="v2resume">
              <button
                className="createfirstresume"
                style={{ border: "none", cursor: "unset" }}
              >
                No pending invites
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  const UserInviteComponent = () => {
    return (
      <>
        {userInvitesPresent ? (
          <>
            <p className="editresumeheading">WorkSpace Admin</p>
            <div className="v2UserInvites">
              <p className="userinvitessecondheading">Your user invites</p>
              <div className="row">
                {Object.keys(userinvites).map((key, index) => {
                  const data = userinvites[key];
                  return (
                    <div className="col-12">
                      <div className="row ">
                        <div className="col-4">
                          {data.accepted ? (
                            <div className="v2userinviteaccepted">
                              Invite Accepted
                            </div>
                          ) : (
                            <div className="v2userinviterejected">
                              Invite Pending
                            </div>
                          )}
                        </div>
                        <div className="col-8">
                          <div className="v2userinviteemail">
                            {"Invited to - "}
                            <span>{data.email}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="v2resume">
              <button className="createfirstresume" onClick={inviteMember}>
                Invite a team member
              </button>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <div className="loading loadingresume" ref={loading}>
        <div className="boxLoading"></div>
      </div>
      <InviteMemberModal showProp={showInviteModal} hideShow={closeModal} />
      <CustomAlert message={message} s={modalshow} title={title}></CustomAlert>
      {userData?.planDetails?.planName &&
        ["gold", "diamond", "platinum"].includes(
          userData?.planDetails?.planName
        ) &&
        memberType === "admin" && (
          <button className="additionalInfo btn" onClick={inviteMember}>
            Invite a team member
          </button>
        )}
      <button className="buttonGoBack btn" onClick={goBack}>
        <i className="fas fa-arrow-left"></i> Go Back
      </button>
      {userData?.planDetails?.planName ? (
        <>
          {["gold", "diamond", "platinum"].includes(
            userData?.planDetails?.planName
          ) && memberType === "admin" ? (
            <>
              <UserInviteComponent />
            </>
          ) : (
            <>
              <InviteComponent />
            </>
          )}
        </>
      ) : (
        <></>
      )}
      {/* {memberType === "admin" ? <UserInviteComponent /> : <InviteComponent />} */}
    </div>
  );
}

export default UserInvites;
