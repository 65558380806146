import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";

export function useStateVariablesCareerBuilder() {
  const [form, setForm] = useState({
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  });

  return {
    form,
    setForm,
  };
}

export function featuresCareerBuilder() {
  let filterRefsCareerBuilder = 10;

  let initialFormStateCareerBuilder = {
    skill: "",
    location: "",
    numberOfJobs: 0,
    resumeJobScore: 40,
  };

  let country_optionsCareerBuilder = [
    { name: "United States", code: "us" },
    { name: "Canada", code: "ca" },
    // { label: "United Kingdom", value: "uk" },
  ];

  let countryUrlMapCareerBuilder = {
    us: "https://www.careerbuilder.com/jobs",

    // uk: "https://www.careerbuilder.co.uk/jobsearch",

    ca: "https://www.careerbuilder.ca/jobs",
  };
  let countryLoginUrlMapCareerBuilder = {
    us: "https://auth.careerbuilder.com/Account/Login",
    uk: "https://www.careerbuilder.co.uk",
    ca: "https://www.careerbuilder.ca",
  };

  return {
    filterRefsCareerBuilder,
    country_optionsCareerBuilder,
    initialFormStateCareerBuilder,
    countryUrlMapCareerBuilder,
    countryLoginUrlMapCareerBuilder,
  };
}

export function startAutomationCareerBuilder(
  countryUrlMap,
  country,
  form,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  uuidv1
) {
  let startAutomationMessage = "careerBuilderFetchFilters";

  console.log(countryUrlMap, country, "career builder country");

  if (!(country in countryUrlMap)) {
    country = "us";
  }

  let startAutomationUrl =
    countryUrlMap[country] +
    `?location=${form.location
      .replace(/[^a-zA-Z\s]/g, "")
      .split(" ")
      .join("+")}` +
    `&keywords=${form.skill.split(" ").join("+")}` +
    "&cb_apply=true";

  let startAutomationSaveValues = {
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    careerBuilderFetchFilters: 0,
    careerBuilderLimit: Math.min(form.numberOfJobs, 200),
    careerBuilderJobLinks: [],
    careerBuilderLocation: form.location,
    careerBuilderCountryCode: country,
    uniquesessionid: uuidv1(),
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let startAutomationData = {
    url: `${startAutomationUrl}`,
    selected: true,
    careerBuilder: true,
    message: "tabid",
  };

  return {
    startAutomationMessage,
    startAutomationUrl,
    startAutomationSaveValues,
    startAutomationData,
  };
}

export function finalSubmitCareerBuilder(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  urlMain,
  urlParts,
  form,
  resumeId,
  formRef,
  resumeData
) {
  let url = urlMain;
  for (let [key, val] of Object.entries(urlParts)) {
    url = url + "&" + key + "=" + val;
  }

  let finalSubmitSaveValues = {
    careerBuilderSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    careerBuilderBaseUrl: url,
    careerBuilderLimit: Math.min(form.numberOfJobs, 200),
    careerBuilderJobLinks: [],
    careerBuilderResumeId: resumeId,
    session: {
      platformName: "careerBuilder",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
  };

  let finalSubmitMessage = "careerBuilderStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}

export function saveSessionCareerBuilder(
  configDummy,
  count,
  sessionLinks,
  userData
) {
  let platformJobsApplied = configDummy.jobsByPlatform["careerBuilder"] + count;

  let careerBuilderJobApplicationLinksFinal = [...sessionLinks.current];
  if ("careerBuilderJobApplicationLinksFinal" in userData) {
    careerBuilderJobApplicationLinksFinal = [
      ...userData.careerBuilderJobApplicationLinksFinal,
      ...careerBuilderJobApplicationLinksFinal,
    ];
  }

  let platformJobApplicationLinksFinal = {
    careerBuilderJobApplicationLinksFinal:
      careerBuilderJobApplicationLinksFinal,
  };

  return { platformJobsApplied, platformJobApplicationLinksFinal };
}

export function hCareerBuilder(
  request,
  sessionLinks,
  count,
  setc,
  progressPercent,
  formRef,
  setpp,
  sessionLinksBefore,
  fetchbutton,
  spinner,
  setIndeedRunning,
  setmessage,
  settitle,
  setmodalshow,
  resetFull,
  filterValues,
  setformDisable,
  chooseParaText,
  formMain1,
  setShowSavedSearchButton,
  button1,
  filtersDiv,
  setheightMain,
  heightMain,
  yourExtensionId,
  smoothScrollToBottom,
  setShowPlanPurchase,
  button2,
  processing,
  smoothScrollToTop,
  initprocessingFinal,
  initprocessing,
  selectResumeRef,
  indeedReset,
  userData,
  sessionId,
  token,
  setFinalJobCount,
  selectResumeRef1,
  form,
  formMain,
  messages,
  MESSAGES,
  MESSAGETYPE,
  chromeVersion,
  saveSession,
  globalLogout,
  history,
  setUrlMain,
  chrome,
  countryLoginUrlMap,
  countryoption
) {
  if ("careerBuilder" in request) {
    if ("message" in request) {
      if (request.message === "Applied successfully") {
        // let countcareerBuilder = count;
        // countcareerBuilder++;
        // console.log(countcareerBuilder)

        console.log(request.link, "link------------");
        sessionLinks.current.push(request.link);
        ++count.current;
        setc(count.current);
        progressPercent.current = Math.floor(
          (count.current / formRef.current.numberOfJobs) * 100
        );

        setpp(progressPercent.current);
        console.log("update count");
      } else if (request.message === "Already Applied Before") {
        sessionLinksBefore.current.push(request.link);
      } else if (request.message === "filterValues") {
        if (request.filterValues["30 miles"]) {
          request.filterValues["Distance"] = request.filterValues["30 miles"];
          delete request.filterValues["30 miles"];
        }
        if (request.filterValues["30+ days"]) {
          request.filterValues["Date Posted"] =
            request.filterValues["30+ days"];
          delete request.filterValues["30+ days"];
        }
        Object.keys(request.filterValues).forEach((x) => {
          if (!request.filterValues[x].value) delete request.filterValues[x];
        });
        filterValues.current = request.filterValues;
        //   setFilters(request.filterValues);
        //   console.log("filters", filters);

        if (Object.keys(request.filterValues).length === 0) {
          fetchbutton.current.disabled = false;
          spinner.current.style.display = "none";

          setIndeedRunning(0);
          // console.log("Try again for better input details");
          if (request.message2 === "signInRequired") {
            setmessage(
              "You're logged out of CareerBuilder. Try again after logging in!"
            );
          } else {
            setmessage("Try again with better input details!");
          }
          settitle("No results found");
          setmodalshow(true);
          messages()[MESSAGES.NOFILTERSFETCHED](
            MESSAGETYPE.USER_REPORT_FRONTEND,
            MESSAGES.NOFILTERSFETCHED,
            `${userData.email},${userData.name},${userData.planDetails.planName}`
          );
          setTimeout(() => {
            setmodalshow(false);
            if (request.message2 === "signInRequired") {
              // console.log(
              //   "openieng",
              //   countryCode,
              //   countryLoginUrlMap[countryCode]
              // );
              window.open(countryLoginUrlMap[countryoption], "_blank");
              window.location.reload();
            }
          }, 2500);
          resetFull();
        } else {
          filterValues.current = request.filterValues;
          setIndeedRunning(0);
          setformDisable(true);
          //perform all the filter operations

          chooseParaText.current.style.display = "block";
          spinner.current.style.display = "none";

          console.log("making formMain1 visible");
          formMain1.current.style.display = "block";
          setShowSavedSearchButton(false);
          button1.current.style.display = "none";

          if (filtersDiv.current)
            setheightMain(heightMain + filtersDiv.current.clientHeight + 300);

          if (typeof chrome !== "undefined" && chrome && chrome.runtime)
            chrome.runtime.sendMessage(yourExtensionId, {
              dashboard: true,
              saveValues: {
                careerBuilderFetchFilters: 1,
              },
              careerBuilder: true,
              message: "careerBuilderFiltersFetched",
            });

          // Call the function
          smoothScrollToBottom();
        }
      } else if (request.message === "debugSession") {
        fetchbutton.current.disabled = false;
        spinner.current.style.display = "none";
        setIndeedRunning(0);
        setmessage(
          "We have detected some issue in LazyApply. Our team has been notified and we will fix this issue within next 1 hour. You have to do nothing on your part, this will be automatically fixed. \n\nThank you for being a wonderful customer."
        );
        settitle("Some problem occured");
        setmodalshow(true);
        setTimeout(() => {
          setmodalshow(false);
        }, 20000);
        resetFull();
      }
      if (request.message === "applypage") {
        if ("message2" in request) {
          console.log("Request.message2------>", request.message2);
          if (request.message2 === "planRequired") {
            setShowPlanPurchase(true);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();
            initprocessingFinal.current.style.display = "flex";
            initprocessing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            indeedReset();
          } else if (request.message2 === "completed") {
            console.log("completed full");
            // ++c.current;
            // setCount(c.current);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            smoothScrollToTop();

            const v2Id = userData?.planDetails?.v2Id;
            axios
              .post(
                `https://backend.lazyapply.com/v2/jobCountInLastSession/${v2Id}`,
                { sessionId: sessionId.current },
                {
                  headers: { Authorization: `Bearer ${token}` },
                }
              )
              .then((response) => {
                console.log("first response", response.data);
                setFinalJobCount(response.data.jobCount || 0);
                initprocessingFinal.current.style.display = "flex";
                initprocessing.current.style.display = "none";
              })
              .catch((err) => {
                console.log("error in request", err);
              });

            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            console.log(form);
            console.log(formMain.current.children);
            if (!window.wasRun) {
              messages()[MESSAGES.ZEROJOBSAPPLIED](
                MESSAGETYPE.USER_REPORT_FRONTEND_CAREERBUILDER,
                MESSAGES.TOTALJOBSAPPLIED,
                count.current,
                JSON.stringify(formRef.current),
                `${userData.email},total jobs applied this session ${count.current},${userData.name},${userData.planDetails.planName}`,
                chromeVersion
              );
              saveSession(count.current, "careerBuilder");
              window.wasRun = true;
            }
          } else if (
            request.message2 ===
            "No results found, try changing the filter values"
          ) {
            console.log("no results found");
            setmessage("Try again for better input details!");
            settitle("No results found");
            setmodalshow(true);
            setTimeout(() => {
              setmodalshow(false);
            }, 2500);
            setIndeedRunning(0);
            button2.current.innerText = "Completed";
            processing.current.style.display = "none";
            if (selectResumeRef.current)
              selectResumeRef.current.style.display = "none";
            if (selectResumeRef1.current)
              selectResumeRef1.current.style.display = "none";
            progressPercent.current = 100;
            setpp(progressPercent.current);
            saveSession(count.current, "careerBuilder");
          } else if (request.message2 === "unauthorized") {
            //  alert("unauthorised");
            fetchbutton.current.disabled = false;
            setIndeedRunning(0);
            console.log("reauthenticate");
            setmessage("Please relogin again!");
            settitle("Not authorized");
            setmodalshow(true);
            setTimeout(() => {
              globalLogout();
              history.push("/");
            }, 1500);
            resetFull();
          }
        }
      }
      if (request.message === "updateLocationUrl") {
        console.log("url final here", request.url);
        setUrlMain(request.url);
      }
    }
  }
}

export function changeFiltersValuesCareerBuilder(
  option,
  urlParts,
  filterValues,
  key
) {
  if (option) {
    urlParts[filterValues.current[key].value] = option.value;
    console.info("url-parts", urlParts);
  }
}

export function remoteFinalSubmitCareerBuilder(
  sessionId,
  advanceSearch,
  stringToArray,
  excludeString,
  mayIncludeString,
  country,
  countryUrlMap,
  form,
  resumeId,
  formRef,
  resumeData
) {
  if (!(country in countryUrlMap)) {
    country = "us";
  }

  let url =
    countryUrlMap[country] +
    `?location=${form.location
      .replace(/[^a-zA-Z\s]/g, "")
      .split(" ")
      .join("+")}` +
    `&keywords=${form.skill.split(" ").join("+")}` +
    "&cb_apply=true";

  let urlParts = {
    cb_workhome: "remote",
  };

  for (let [key, val] of Object.entries(urlParts)) {
    url = url + "&" + key + "=" + val;
  }

  let finalSubmitSaveValues = {
    careerBuilderSessionId: sessionId.current,
    excludeKeywords: advanceSearch ? stringToArray(excludeString) : [],
    mayIncludeKeywords: advanceSearch ? stringToArray(mayIncludeString) : [],
    careerBuilderBaseUrl: url,
    careerBuilderLimit: Math.min(form.numberOfJobs, 200),
    careerBuilderJobLinks: [],
    careerBuilderResumeId: resumeId,
    session: {
      platformName: "careerBuilder",
      formData: formRef.current,
    },
    resumeData: resumeData.current,
    careerBuilderFetchFilters: 1,
    careerBuilderLocation: form.location,
    careerBuilderCountryCode: country,
    resumeJobScore: form.resumeJobScore,
    userProvidedJobTitle: form.skill,
    unappliedJobsDueToScore: 0,
  };

  let finalSubmitMessage = "careerBuilderStartApplying";

  let finalSubmitData = {
    message: "setBaseUrl",
    url: url,
  };

  return { url, finalSubmitSaveValues, finalSubmitMessage, finalSubmitData };
}
