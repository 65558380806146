export function styles() {
  const styleform = {
    padding: "20px",
    paddingTop: "0px",
  };
  const styleform1 = {
    margin: "10px auto",
    padding: "0 5px",
  };

  const filtersStyle = {
    padding: "20px",
    boxShadow: "0px 2px 4px 2px lightgrey, 0px 2px 4px 2px #efefef",
    margin: "5px",
    position: "relative",
  };

  let parastyle = {
    display: "none",
    color: "red",
    marginLeft: "20px",
  };

  let buttonMain1 = {
    backgroundColor: "#35AD46",
    color: "white",
    fontWeight: "bold",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  let buttonMain = {
    backgroundColor: "grey",
    color: "white",
    cursor: "default",
    fontSize: "16px",
    padding: "10px 10px",
    textAlign: "center",
    borderRadius: "10px",
    outline: "none",
    border: "none",
  };

  return {
    styleform,
    styleform1,
    filtersStyle,
    parastyle,
    buttonMain1,
    buttonMain,
  };
}
